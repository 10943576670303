import moment from "moment";
import { useEffect, useState } from "react";

const buildDateGreaterThan = (latestDate:string, currentDate:string) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};

function withClearCache(Component: any) {
  function ClearCacheComponent(props: JSX.IntrinsicAttributes) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      try {
      fetch("/meta.json",{
        cache: "no-cache"
      })
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = __BUILD_DATE__;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        }).catch(err => {
          console.error(err);
        });
      }catch (err) {
        console.error(err);
      }
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload();
    };

    return (
        <>{isLatestBuildDate ? <Component {...props} /> : null}</>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
