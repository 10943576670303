import BarChart from '../components/BarChart';
import LineChart from '../components/LineChart';
import PieChart from '../components/PieChart';

export const getChartStyle = (selectedChart: string | null) => {
  const styles = {
    width: '100%',
    height: '100%',
  };
  switch (selectedChart) {
    case 'STACKED_BAR_CHART':
    case 'BAR_CHART':
      styles.width = '407.77px';
      styles.height = '203.5px';
      break;
    case 'LINE_CHART':
      styles.width = '407.77px';
      styles.height = '203.5px';
      break;
    case 'PIE_CHART':
      styles.width = '180px';
      styles.height = '200px';
      break;
    default:
      styles.width = '400px';
  }
  return styles;
};

export function getChartComponent(
  selectedChart: string,
  boxIndex: string|number,
  updatedSessionData: any,
  chartOptions?:any
) {
  switch (selectedChart) {
    case 'STACKED_BAR_CHART':
    case 'BAR_CHART':
      return <BarChart key={boxIndex} chartData={updatedSessionData}  customOptions={chartOptions} />;
    case 'LINE_CHART':
      return <LineChart key={boxIndex} chartData={updatedSessionData}  customOptions={chartOptions}/>;
    case 'PIE_CHART':
      return <PieChart key={boxIndex} chartData={updatedSessionData}  customOptions={chartOptions}/>;
    default:
      return <BarChart key={boxIndex} chartData={updatedSessionData} customOptions={chartOptions} />;
  }
}

export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    padding: '0px',
    position: 'absolute',
    top: '0px',
    right: '24px',
    overflow: 'visible',
  }),

  control: (provided: any) => ({
    ...provided,
    width: '80px',
    height: '35px',
  }),

  option: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'lightgrey' : 'white',
    color: state.isFocused ? 'white' : 'black',
  }),

  menu: (provided: any) => ({
    ...provided,
    position: 'absolute',
    top: 0,
    right: 39,
    padding: '3px',
  }),
};

export const chartStyles = (selectedChart: string) => {
  return {
    width:
      selectedChart === 'PIE_CHART'
        ? '326px'
        : selectedChart === 'STACKED_BAR_CHART' || selectedChart === 'BAR_CHART' || selectedChart === 'LINE_CHART'
        ? '477.77px'
        : '300px',
    height: '284px',
  };
};
