import styles from './table-view.module.scss';
import { TColumn } from '@trimble-oss/modus-web-components';
import SharedUiTable from '../lib/shared-ui-table';
import UIMetaInfo from '../table-meta/table-meta';
import { ModusTableColumn } from '@trimble-oss/modus-web-components/';

export interface UITableViewProps {
  headers: ModusTableColumn<any>[];
  values: any[];
  rowActions:any[]; 
  currentPageNumber: number;
  paginationAction: any;
  metaData: any;
  onRowActionClick?: any;
  onCellLinkClick?:any;
}

export function UITableView(props: UITableViewProps) {
  return (
    <div id="ui-table-view">
      <SharedUiTable headers={props.headers} values={props.values} rowActions={props.rowActions}
          onRowActionClick={props.onRowActionClick} onCellLinkClick={props.onCellLinkClick}/>
      <br></br>
      <div className={styles['table-meta']}>
        <UIMetaInfo
          paginationAction={props.paginationAction}
          currentPage={props.currentPageNumber}
          meta={props.metaData}
        />
      </div>
    </div>
  );
}

export default UITableView;
