import styles from './shared-ui-table.module.scss';
import { ModusDataTable,ModusTable } from '@trimble-oss/modus-react-components';
import { TColumn, ModusDataTableDisplayOptions } from '@trimble-oss/modus-web-components/';
import { ModusTableColumn } from '@trimble-oss/modus-web-components/';

/* eslint-disable-next-line */
export interface SharedUiTableProps {
  headers: ModusTableColumn<any>[] ;
  values: any[];
  rowActions: any[];
  onRowActionClick?: any;
  onCellLinkClick?:any;
}

export enum dataTypes {
  text,
  number,
  date,
}

export interface tableHeader {
  header: string;
  accessorKey: string;
  id: string;
  dataType: dataTypes;
}

export function SharedUiTable(props: SharedUiTableProps) {
  // console.log('shareUI table Headers : '+JSON.stringify(props.headers));
  // console.log('shareUI table values : '+JSON.stringify(props.values));

  const options: ModusDataTableDisplayOptions = {
    rowStripe       : true,
    cellBorderless  : true,
    borderless      : true,
  }

  return <ModusTable columns={props.headers} data={props.values} rowActions={props.rowActions} displayOptions={options} onRowActionClick={props.onRowActionClick} onCellLinkClick={props.onCellLinkClick}/>;
}

export default SharedUiTable;
