
// Snytax : [ page name + _ + component name + _ + action / text / id ]
export enum Constants {
    
    // TABLE DATA CONSTANTS
    USER_TABLE_NAME_ID                  = 'name',
    USER_TABLE_ROLE_ID                  = 'role',
    USER_TABLE_STATUS_ID                = 'status',
    USER_TABLE_EMAIL_ID                 = 'email',
    USER_TABLE_ACTIVATION_ID            = 'activeFrom',
    USER_TABLE_INVITED_ID               = 'invitedOn',
    USER_TABLE_EXPIRED_ID               = 'expiryDate',
    USER_TABLE_ACTION_ID                = 'action',
    USER_TAB_ALL_USERS_ID               = 'profileUsers',
    USER_TAB_ALL_USERS_DISPLAY_NAME     = 'ALL USERS',
    USER_TAB_ICS_USERS_ID               = 'icsUsers',
    USER_TAB_ICS_USERS_DISPLAY_NAME     = 'PENDING USERS',

    // User Profiles constants
    USER_PROFILE_ROLE_OWNER_TEXT        = 'owner',
    USER_PROFILE_ROLE_ADMIN_TEXT        = 'admin',
    USER_PROFILE_ROLE_READ_ONLY_TEXT    = 'read-only-user',
    USER_PROFILE_ROLE_MANAGER_TEXT      = 'team_manager',
    USER_PROFILE_ROLE_PRODUCT_USER_TEXT = 'product_user',

    // ics UI - user page security role text
    USER_ICS_ROLE_READ_ONLY_TEXT        = 'Account Reader',
    USER_ICS_ROLE_OWNER_TEXT            = 'Account Owner',
    USER_ICS_ROLE_ADMIN_TEXT            = 'Account Administrator',
    USER_ICS_ROLE_MANAGER_TEXT          = 'Project Administrator',
    USER_ICS_ROLE_PRODUCT_USER_TEXT     = 'Standard User',

    // TCP role names
    USER_TCP_ROLE_READ_ONLY_TEXT        = 'TCP_READER',
    USER_TCP_ROLE_OWNER_TEXT            = 'TCP_ACCOUNT_OWNER',
    USER_TCP_ROLE_ADMIN_TEXT            = 'TCP_COMPANY_ADMIN',
    USER_TCP_ROLE_MANAGER_TEXT          = 'TCP_TEAM_MANAGER',
    USER_TCP_ROLE_PRODUCT_USER_TEXT     = 'TCP_END_USER' ,

    // resend and reinvite constants
    USER_RESEND_INVITE_TEXT             = 'resend',
    USER_REINVITE_TEXT                  = 'reinvite',

    // workflows and capabilities constants
    ABSTRACT_PAGE_TAB_CAPABILITIES_ID       = 'capabilities',
    ABSTRACT_PAGE_TAB_WORKFLOWS_ID          = 'workflows',
    ABSTRACT_PAGE_TAB_DEVELOPER_APIs_ID     = 'apis',
    ABSTRACT_PAGE_TAB_DEVELOPER_SDKs_ID     = 'sdks',
    ABSTRACT_PAGE_TAB_DEVELOPER_data_ID     = 'datasets',
    ABSTRACT_PAGE_TAB_DEVELOPER_k8s_ID      = 'k8s',
    ABSTRACT_PAGE_TAB_CAPABILITIES_TEXT     = 'Web and App Services',
    ABSTRACT_PAGE_TAB_WORKFLOWS_TEXT        = 'workflows',

    // PaGE constants
    PAGE_MY_CAPABILITIES_ID                 = 'myCapabilities',
    PAGE_MARKETPLACE_CAPABILITIES_ID        = 'marketplaceCapabilities',
    PAGE_DEVELOPER_TOOLS_ID                 = 'developerTools',

    // Page display texts
    PAGE_MY_CAPABILITIES_TEXT               = 'My Capabilities',
    PAGE_MARKETPLACE_CAPABILITIES_TEXT      = 'Marketplace Capabilities',
    PAGE_DEVELOPER_TOOLS_TEXT               = 'Developer Tools'
}