import { useEffect } from 'react';
import styles from './cloud-support.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

/* eslint-disable-next-line */
export interface CloudSupportProps {}

export function CloudSupport(props: CloudSupportProps) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://www.cognitoforms.com/f/seamless.js';
    script.setAttribute('data-key', '0cXQNplKOk-LZ2g6xRL5fQ');
    script.setAttribute('data-form', '32');
    script.async = true;

    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = 'css/cog_modus_form.css';
    link.type = 'text/css';
    document.head.appendChild(link);
    document.getElementById('cloud-support-container')?.appendChild(script);
    return () => {
      document.getElementById('cloud-support-container')?.removeChild(script);
    };
  }, []);

  const onClickBack = () => {
    if (location.key !== 'default') {
      navigate(-1);
    }
  }
  return (
    <div className={styles['cloud-support']}>
      <div className={styles['navigation']}>
        <Link
          onClick={onClickBack}
          data-auto="backicon"
          className={styles['cloud-support-backButton']}
          to={''}
        >
          <div className={styles['cloud-support-buttonText']}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              className="mi-solid mi-chevron-left"
              viewBox="0 0 24 24"
            >
              <path d="M14.71 6.71a.996.996 0 0 0-1.41 0L8.71 11.3a.996.996 0 0 0 0 1.41l4.59 4.59a.996.996 0 1 0 1.41-1.41L10.83 12l3.88-3.88c.39-.39.38-1.03 0-1.41Z" />
            </svg>
            <span className="back-link pl-2">{t('Common.Back')}</span>
          </div>
        </Link>
      </div>
      <div
        className={styles['cloud-support-container']}
        id="cloud-support-container"
      ></div>
    </div>
  );
}

export default CloudSupport;
