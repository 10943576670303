import { SubscriptionStatusTypes } from './FeatureDeployment';

export interface Product {
  _id: string;
  summary: string;
  name: string;
  description: string;
  id: string;
  features: Feature[];
}

export interface Feature {
  supportedDimensions: any;
  featureId: string;
  active: boolean;
  service: string;
  type: string;
  catalogue: Catalogue;
  logoUrl: string;
  productImageUrl: string;
  tagNames: string[];
  ownedBy: string;
  supportedDevices: string[];
  catagories: string[];
  additionalFeatures: AdditionalFeature[];
  plans: Plan[];
  name: string;
  description: string;
  summary: string;
  crtdDt?: string;
  deploymentInfo?: DeploymentInfo;
  email?: string;
  entitlementId?: string;
  id: string;
  uptdDt?: string;
  userId?: string;
  note: string;
  keyFeatures?: KeyFeature[];
}
export interface AdditionalFeature {
  name: string;
  desc: string;
}

export interface KeyFeature {
  image: string;
  name: string;
  desc: string;
}

export interface Plan {
  title: string;
  subTitle: string;
  price: {
    unit: string,
    amount: number,
    currency: string
  };
  image: string;
  includedFunctionalities: string[];
  isEnabled: boolean;
}

export interface Price {
  unit: string;
  amount: number;
  currency: string;
}
export interface DeploymentInfo {
  deploymentTime?: string;
  status?: SubscriptionStatusTypes;
  statusDescription?: string;
  deploymentId: string;
  details?: Detail[];
}

export interface Detail {
  workflowName: string;
  releaseId: number;
  parameters: ParameterValue[];
}
export interface Catalogue {
  deploymentConfig?: DeploymentConfig[];
  urls?: URL;
}

export interface URL {
  launch: string;
}
export interface DeploymentConfig {
  parameters: Parameter[];
}

export interface ParameterValue {
  name: string;
  value: string;
}

export interface Parameter {
  name: string;
  type: string;
  defaultValue?: string;
  paramType?: ParamType;
  required?: boolean;
  label?: string;
  description?: string;
  value?: string | undefined;
}

export interface UIParameter extends Parameter {
  name: string;
}

export enum ParamType {
  Explicit = 'explicit',
  Implicit = 'implicit',
}
