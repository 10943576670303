import styles from './feature-info.module.scss';
import mobile_icon from '../../assets/images/mobile_icon.svg';
import web_icon from '../../assets/images/mac_laptop.svg';
import tablet_icon from '../../assets/images/tablet-icon.svg';


/* eslint-disable-next-line */
export interface FeatureInfoProps {
  header: string;
  title: string;
  body: string;
  imageUrl?: string;
  deviceSupport?: string[];
}

export enum FeatureDeviceSupport {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Web    = 'web'
}

export function FeatureInfo(props: FeatureInfoProps) {
  
  const { header, title, body, imageUrl, deviceSupport } = { ...props };
  //console.log('FeatureInfoProps', props);

  const getDeviceIcon = (device: string) => {
    switch (device) {
      case FeatureDeviceSupport.Mobile:
        return <img src={mobile_icon} alt="mobile" key={'mobile'}/>;
      case FeatureDeviceSupport.Tablet:
        return <img src={tablet_icon} alt="tablet" key={'tablet'}/>;
      case FeatureDeviceSupport.Web:
        return <img src={web_icon} alt="laptop" key={'laptop'}/>;  
      default:
        return '';
    }
  };
  return (
    <div className={styles['container']}>
      <div className={styles['text-content']}>
        <div className={styles['header-container']}>
          <div className={styles['header']}>{header}</div>
          <div className={styles['title']}>{title}</div>
          <div className={styles['body']}>{body}</div>
        </div>
        {deviceSupport && (
          <div className={styles['footer']}>
            {deviceSupport.length > 0 &&
              deviceSupport.map((device, index) => getDeviceIcon(device))}
          </div>
        )}
      </div>
      {imageUrl ? (
        <img
          className={styles['img-content']}
          src={imageUrl}
          alt="feature screenshots"
        />
      ) : (
        <div className={styles['img-content-empty']}></div>
      )}
    </div>
  );
}

export default FeatureInfo;
