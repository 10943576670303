import Notification from '../notification/Notification';
import { createContext, useContext, useEffect, useState } from 'react';
import ToastLister from '../toast-lister/toast-lister';
import { ActionType } from '../../../../../libs/ui/src/action-button/action-props';
import { useLocation } from 'react-router-dom';
import { RouteType } from '../../models/RouteType';

/* eslint-disable-next-line */
export interface NotificationProviderProps {
  children: any;
  type?: string[];
}

export interface AlertType {
  "type"?: 'error' | 'info' | 'info-gray' | 'info-gray-dark' | 'success' | 'warning';
  message: string;
  status: string;
  id: string;
  link?: string;
  source?:RouteType;
}

export interface NotificationContextProps {
  alert?: AlertType;
  showAlertText?: boolean;
  hasError?:boolean;
  setAlertMesage?: (message: AlertType) => void;
  alertCloseHandler?: () => void;
  setNotification?: ({ id, message, status, title }: any) => void;
  setStatusConsumer?: (
    actionId: ActionType,
    featureData: any,
    handlers: any,
    productName: string,
    source?: string
  ) => Promise<void>;
  dismissAll?:()=> void;
  setHasError?:(value:boolean)=>void;
}

const NotificationContext = createContext<NotificationContextProps>({
  alert: undefined,
});

export interface NotificationMessage {
  id: string;
  message: string;
  status: string;
  title: string;
  autohide?:boolean;
  source?:RouteType;
}

export function NotificationProvider(props: NotificationProviderProps) {
  const [messageList, setMessageList] = useState<NotificationMessage[]>([]);
  const [alert, setAlert] = useState<AlertType>();
  const [hasError,setError]=useState<boolean>();
  const [showAlertText, setShowAlertText] = useState(false);
  const location = useLocation();
  const setNotification = ({ id, message, status, title ,source}: any) => {
    setMessageList((prev) => {
      const updatedState = [...prev];
      let isPresent = false;
      updatedState.map((mesg) => {
        if (mesg.id === id) {
          isPresent = true;
          mesg.message = message;
          mesg.status = status;
          mesg.title = title;
        }
        return mesg;
      });
      if (!isPresent) {
        updatedState.push({
          message: message,
          status: status,
          title: title,
          id: id,
          source:source
        });
      }
      return updatedState;
    });
  };

  const setAlertMesage = (message: AlertType) => {
    setAlert(message);
    setShowAlertText(true);
  };
  const alertCloseHandler = () => {
    setShowAlertText(false);
    setAlert(undefined);
  };
  const hasToast = () => {
    return props.type?.length && props.type?.some((value) => value === 'toast');
  };

  const hasNotification = () => {
    return (
      props.type?.length &&
      props.type?.some((value) => value === 'notification')
    );
  };
  const setHasError =(value:boolean) => {
    setError(value);
  }

  const closeNotification = (id: string) => {
    setMessageList((prev) => {
      const updatedState = [...prev];
      return updatedState.filter((mesg) => {
        if (mesg.id === id) {
          return false;
        }
        return true;
      });
    });
  };

  useEffect(() => {
      const pathValues = location.pathname.split('/');
      if(pathValues.length) {
        clearNotification(pathValues[1] as any);
      }
  },[location.pathname]);
  const clearNotification = (source?:RouteType) => {
    setMessageList((prev) => {
      const updatedState = [...prev];
      return updatedState.filter((mesg) => {
        if (mesg.source !== source) {
          return false;
        }
        return true;
      });
    });
    if(showAlertText && alert?.source !== source) alertCloseHandler();
  }
  const contextValue = {
    setNotification,
    setHasError,
    setAlertMesage,
    alert,
    hasError,
    showAlertText,
    alertCloseHandler,
    dismissAll:clearNotification
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {hasNotification() && (
        <Notification
          notificatiomMesgList={messageList}
          closeNotification={closeNotification}
        ></Notification>
      )}
      {hasToast() && (
        <ToastLister
          toastMesgList={messageList}
          closeToast={closeNotification}
        ></ToastLister>
      )}
      {props.children}
    </NotificationContext.Provider>
  );
}

export function useNotificationContext() {
  return useContext(NotificationContext);
}

export default NotificationProvider;
