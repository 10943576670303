export class Converter {

    /** 
     * lowering the case of the text
     * replacing empty space and colon
     * adding prefix and sufix text
     * this is to maintain the unique id for gain sight and automation
     */
    static replaceSpaceAndColon(prefix: string, textToReplace: string, suffix: string){
        let value = textToReplace ? textToReplace.toLowerCase().replaceAll(' ', '_').replaceAll(':', '_'):'';

        if(prefix){
            value = prefix + '_' + value;
        }

        if(suffix){
            value = value + '_' + suffix ;
        }

        return value ;
    }
}