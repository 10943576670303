import { SubscriptionStatusTypes } from '../models/FeatureDeployment';
import {
    axiosInstance
} from '../hooks/axiosInstanceHelper';
import {globalConfig} from '../API/Utils/enivronment-util';
import { ActionType } from '../../../../libs/ui/src/action-button/action-props';

export enum StatusTypes {
  PENDING = "Pending", INPROGRESS = "In Progress", SUCCESS = "Success", FAILED = "Failed", CONFLICT = "Conflict"
}

async function statusCheckConn(actionId:ActionType,featureData: any, handlers: any, productName: string, source?: string) {
    const res = axiosInstance().request({
        method: 'GET',
      url: `${globalConfig.config.webPubSubURL}/negotiate/v1`,
        params:{
            id: featureData.accountId
        }
    }); 
    res.then(val => {
        if (val && val.status === 200) {
            const data = val.data;
            const ws = new WebSocket(data.url);
            ws.onopen = () => console.log('connected');
            ws.onmessage = (serverRes) => {
              const data = (serverRes.data) ? JSON.parse(serverRes.data) : {};
              console.log('provisioning_status_check ' + data.message);
              const eventData = JSON.parse(data.eventData);
              console.log('provisioning_status_check | productId: ' + eventData.productId);
              if (eventData.featureId === featureData.featureId) {                
                if (eventData.provisioningStatus === SubscriptionStatusTypes.UNSUBSCRIBE_INPROGRESS
                  || eventData.provisioningStatus === SubscriptionStatusTypes.UNSUBSCRIBE_PENDING
                  || eventData.provisioningStatus === SubscriptionStatusTypes.SUBSCRIBE_INPROGRESS
                  || eventData.provisioningStatus === SubscriptionStatusTypes.SUBSCRIBE_PENDING) {
                  //Status is in progress, continue listening
                } else {
                  ws.close();
                  console.log('Connection with the server is closed');                  
                };
                handlers.updateButtonState(actionId,featureData.featureId, productName, eventData.provisioningStatus);
              }
            }
      }
    }).catch(err => {
        handlers.updateButtonState(actionId,featureData.featureId, productName, SubscriptionStatusTypes.SUBSCRIBE_FAILED);
        console.log(err);
      });        
  }

const setButtonState = (action: string, handlers:any, featureId: string, productName: string, status: string,actionId?:ActionType,) => {
  switch (status) {
    case StatusTypes.PENDING:
      handlers.updateButtonState(actionId,featureId, productName, 
        (action === "subscribe") ? SubscriptionStatusTypes.SUBSCRIBE_PENDING : SubscriptionStatusTypes.UNSUBSCRIBE_PENDING);
      break;
    case StatusTypes.CONFLICT:
      handlers.updateButtonState(actionId,featureId, productName, 
        (action === "subscribe") ? SubscriptionStatusTypes.SUBSCRIBE_CONFLICT : SubscriptionStatusTypes.UNSUBSCRIBE_CONFLICT);
      break;
    case StatusTypes.FAILED:
      handlers.updateButtonState(actionId,featureId, productName, 
        (action === "subscribe") ? SubscriptionStatusTypes.SUBSCRIBE_FAILED : SubscriptionStatusTypes.UNSUBSCRIBE_FAILED);
      break;
    case StatusTypes.INPROGRESS:
      handlers.updateButtonState(actionId,featureId, productName, 
        (action === "subscribe") ? SubscriptionStatusTypes.SUBSCRIBE_INPROGRESS : SubscriptionStatusTypes.UNSUBSCRIBE_INPROGRESS);
      break;
    case StatusTypes.SUCCESS:
      handlers.updateButtonState(actionId,featureId, productName, 
        (action === "subscribe") ? SubscriptionStatusTypes.SUBSCRIBE_SUCCESS : SubscriptionStatusTypes.UNSUBSCRIBE_SUCCESS);
      break;
  }
}

export const ProvisioningAPIs = {
  async startProvision(data: any, productName: string, handlers: any,actionId:ActionType, source?: string,setNotifier?:boolean) {
    const url = `/provisioning/v1`;
    //console.log(`Provision Request payload ${JSON.stringify(data)}`);
    const res = axiosInstance().request({
      method: 'POST',
      url: url,
      data: data
    });
    console.log("Provision Request is made")
    res.then(val => {
      if (val && val.status === 202) {
        if(setNotifier) 
        {if (source) {
          statusCheckConn(actionId,data, handlers, productName, source);
        } else {
          statusCheckConn(actionId,data, handlers, productName);
        }}
      } else if(val && val.status === 200){
        setButtonState(data.action, handlers, data.featureId, productName, StatusTypes.SUCCESS,actionId);
      } else {
        setButtonState(data.action, handlers, data.featureId, productName, StatusTypes.PENDING,actionId);
      }
    }).catch(err => {
      if (err && err.status === 409) {
        console.log('409 conflict, during provisioning :' + data.action);
        setButtonState(data.action, handlers, data.featureId, productName, StatusTypes.CONFLICT,actionId);
      } else {
        setButtonState(data.action, handlers, data.featureId, productName, StatusTypes.FAILED,actionId);
      }
      console.log(err);
    });
  },

  async updateProvision(data: any, productName: string, featureDeploymentID: string, handlers: any,actionId:ActionType, source?: string) {
    const url = `/provisioning/v1/${featureDeploymentID}`;
    //console.log(`Provision Request payload ${JSON.stringify(data)}`);
    const res = axiosInstance().request({
      method: 'PUT',
      url: url,
      data: data
    });
    console.log("Provision Request is made")
    res.then(val => {
      if (val && val.status === 200) {
        console.log(val.data);
        if (source) {
          statusCheckConn(actionId,data, handlers, productName, source);
        } else {
          statusCheckConn(actionId,data, handlers, productName);
        }
      } else {
        setButtonState(data.action, handlers, data.featureId, productName, StatusTypes.PENDING,actionId);
      }
    }).catch(err => {
      if (err && err.status === 409) {
        console.log('409 conflict, during provisioning :' + data.action);
        setButtonState(data.action, handlers, data.featureId, productName, StatusTypes.CONFLICT,actionId);
      } else {
        setButtonState(data.action, handlers, data.featureId, productName, StatusTypes.FAILED,actionId);
      }
      console.log(err);
    });
  }
};

