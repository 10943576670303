import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js/auto';
import {   Chart,
  CategoryScale,
  ChartData, LinearScale, BarElement, Tooltip } from 'chart.js';
  import ChartDataLabels from 'chartjs-plugin-datalabels';

interface BarChartProps {
  chartData: ChartData<'bar'>;
  customOptions?: ChartOptions<'bar'>;
}
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
);
const BarChart: React.FC<BarChartProps> = ({
  chartData,
  customOptions,
}: BarChartProps) => {
  // Default options
  const defaultOptions: ChartOptions<'bar'> = {
    animation: {
      animateRotate: true,
      animateScale: true,
      duration: 1000,
      easing: 'easeInOutQuart',
    } as any,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'center',
        labels: {
          boxWidth: 8,
          padding: 4,
        },
      },
      datalabels: {
        anchor: 'center',
        color:'#ffffff',
        font: {
          size: 8,
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };


  const options: ChartOptions<'bar'> = {
    ...defaultOptions,
    ...customOptions,
  };

  console.log('I am the bar component', chartData);

  return <Bar data={chartData} options={options} plugins={[ChartDataLabels as any]} />;
};

export default BarChart;
