import { FeatureAction } from './Permission';

export type FeaturePermissions = {
  [key: string]: { permissions: FeatureAction[] };
};
export class FeatureCapabilities {
  public static readonly myCapabilities: 'my-capabilities' = 'my-capabilities';
  public static readonly marketplaceCapabilities: 'marketplace-capabilities' =
    'marketplace-capabilities';
  public static readonly accountUsers: 'account-users' = 'account-users';
  public static readonly developerPortal: 'developer-portal' =
    'developer-portal';
  public static readonly TDrive: 't-drive' = 't-drive';
}
export const RoleActionMapping: { [key: string]: FeatureAction[] } = {
  owner : ['R', 'A', 'C', 'D', 'U'],
  admin: ['R', 'A', 'C', 'D', 'U'],
  product_user: ['R']
}

export const RoleMapping: { [key: string]: FeaturePermissions } = {
  owner: {
    // [FeatureCapabilities.bidToActuals]: {
    //   permissions: ['R', 'A', 'C', 'D', 'U'],
    // },
    // [FeatureCapabilities.Inspections]: {
    //   permissions: ['R', 'A', 'C', 'D', 'U'],
    // },
    // [FeatureCapabilities.materiaCreator]: {
    //   permissions: ['R', 'A', 'C', 'D', 'U'],
    // },
    // [FeatureCapabilities.trimbleDrawing]: {
    //   permissions: ['R', 'A', 'C', 'D', 'U'],
    // },
    // [FeatureCapabilities.liveSharing]: {
    //   permissions: ['R', 'A', 'C', 'D', 'U'],
    // },
    // [FeatureCapabilities.skydio]: { permissions: ['R', 'A', 'C', 'D', 'U'] },
    [FeatureCapabilities.myCapabilities]: {
      permissions: ['R', 'A', 'C', 'D', 'U'],
    },
    [FeatureCapabilities.accountUsers]: {
      permissions: ['R', 'A', 'C', 'D', 'U'],
    },
    [FeatureCapabilities.marketplaceCapabilities]: {
      permissions: ['R', 'A', 'C', 'D', 'U'],
    },
    [FeatureCapabilities.developerPortal]: {
      permissions: ['R', 'A', 'C', 'D', 'U'],
    },
    [FeatureCapabilities.TDrive]: {
      permissions: ['R', 'A', 'C', 'D', 'U'],
    },
  },
  admin: {
    // [FeatureCapabilities.bidToActuals]: {
    //   permissions: ['R', 'A', 'C', 'D', 'U'],
    // },
    // [FeatureCapabilities.Inspections]: {
    //   permissions: ['R', 'A', 'C', 'D', 'U'],
    // },
    // [FeatureCapabilities.materiaCreator]: {
    //   permissions: ['R', 'A', 'C', 'D', 'U'],
    // },
    // [FeatureCapabilities.trimbleDrawing]: {
    //   permissions: ['R', 'A', 'C', 'D', 'U'],
    // },
    // [FeatureCapabilities.liveSharing]: {
    //   permissions: ['R', 'A', 'C', 'D', 'U'],
    // },
    // [FeatureCapabilities.skydio]: { permissions: ['R', 'A', 'C', 'D', 'U'] },
    [FeatureCapabilities.myCapabilities]: {
      permissions: ['R', 'A', 'C', 'D', 'U'],
    },
    [FeatureCapabilities.accountUsers]: {
      permissions: ['R', 'A', 'C', 'D', 'U'],
    },
    [FeatureCapabilities.marketplaceCapabilities]: {
      permissions: ['R', 'A', 'C', 'D', 'U'],
    },
    [FeatureCapabilities.developerPortal]: {
      permissions: ['R', 'A', 'C', 'D', 'U'],
    },
    [FeatureCapabilities.TDrive]: {
      permissions: ['R', 'A', 'C', 'D', 'U'],
    },
  },
  product_user: {
    // [FeatureCapabilities.bidToActuals]: { permissions: ['R'] },
    // [FeatureCapabilities.Inspections]: { permissions: ['R'] },
    // [FeatureCapabilities.materiaCreator]: { permissions: ['R'] },
    // [FeatureCapabilities.trimbleDrawing]: { permissions: ['R'] },
    // [FeatureCapabilities.liveSharing]: { permissions: ['R'] },
    // [FeatureCapabilities.skydio]: { permissions: ['R'] },
    [FeatureCapabilities.myCapabilities]: { permissions: ['R'] },
    [FeatureCapabilities.marketplaceCapabilities]: { permissions: ['R'] },
    [FeatureCapabilities.developerPortal]: { permissions: ['R'] },
    [FeatureCapabilities.TDrive]: { permissions: ['R'] },
  },
};
