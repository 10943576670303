import styles from './info-card.module.scss';
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { ActionButton } from '../action-button/action-button';
import { Converter } from '../utils/conversion-util';
import { ActionProps } from '../action-button/action-props';
import { ModusButton, ModusChip } from '@trimble-oss/modus-react-components';

export interface InfoCardProps {
  title: string;
  subtitle?: string;
  info: string;
  statusIcon?: string;
  actions?: ActionProps[];
  className: string;
  image?: string;
  id: string;
  minWidth?: string;
  height?: string;
  borderTop?: string;
  titleLink?: string;
  show?: boolean;
  clickHandlers?: any;
  tags?:string[];
}

export function InfoCard(props: InfoCardProps) {
  const { title } = props;
  const titleName = Converter.replaceSpaceAndColon('card_title', title, '');
  const viewMoreName = Converter.replaceSpaceAndColon('view_more_btn', title, '');
  const getHandlerCallBack = (label: string | undefined) => {
    return label ? props.clickHandlers[label] : () => null;
  }
  return (
    <div className={styles['container']}>
      <Card
        data-auto="info-card" id="info_card"
        className={props.className}
        style={{ maxWidth: '450px', minWidth: props.minWidth || '260px', height: props.height || '260px', borderTop: props.borderTop }}
      >
        {props.image && <Card.Img
          variant="top"
          className={styles['container-image']}
          src={props.image}
        />}
        <Card.Body>
            <Card.Title className="d-flex justify-content-between align-items-center text-trimble-blue-dark" data-auto="title" id={titleName} as="h3">
              {props.titleLink ? <Link to={props.titleLink}><span className={styles['info_card--title']+" text-trimble-blue-dark"}>{props.title}</span></Link> : <span className={styles['info_card--title']}>{props.title}</span>}
              {props.statusIcon && <i className="material-icons-outlined">{props.statusIcon}</i>}
            </Card.Title>
            {props.subtitle && <Card.Title data-auto="sub-title" as="h4">{props.subtitle}</Card.Title>}
            <div className='d-flex justify-content-flex-start align-items-start pb-2'>
            {props.tags && props.tags.length > 0 && props.tags.map((tag) => {
                 return (
                  <ModusChip
                    value={tag}
                    size='small'
                    chipStyle='outline'
                  >
                    {tag}
                  </ModusChip>
                 )
            })}
              </div>
            {props.info && <p>{props.info}</p>}
        </Card.Body>
        <Card.Footer className={styles['container-footer']}>
          <div className={styles['action-buttons']  +' d-flex align-content-between'}>
            {props.actions &&
              props.actions.length > 0 &&
              props.actions.map((item) => {
                if (item.type === "button" && item.show) {
                  return (<ActionButton
                    key={item.actionId}
                    type={item.type}
                    isDisabled={item.isDisabled}
                    onClickHandler={getHandlerCallBack(item.clickHandlerLabel)}
                    title={item.title}
                    status={item.status}
                    to={item.to}
                    cardId={props.id}
                    cardTitle={props.title}
                    isOnload={item.isOnload}
                    actionId={item.actionId}
                  ></ActionButton>);
                } else {
                  return (
                    item.to && (
                      <ModusButton
                        key={item.actionId}
                        size={item.size || 'small'}
                        id={viewMoreName}
                        data-auto="view_more"
                        buttonStyle="outline"
                        color="primary"
                        onClick={() => {
                          window.open(item.to, '_blank');
                        }}
                      >
                        {item.title}
                      </ModusButton>
                    )
                  );
                }
              })}
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
}
