import { ModusModal } from '@trimble-oss/modus-react-components';
import { FieldOption, FormRenderer, IField } from '@workflow/shared/ui-form';
import { updateUserRoles, IRoleEditReq } from '../../API/RoleAssignment';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { ActionType } from '@workflow/ui';

/* eslint-disable-next-line */
export interface UserEditRoleProps {
  title: string;
  accountId?: string;
  userInfo: any;
  onSuccess: (res: any) => void;
  onClose: () => void;
  onError: (error: any) => void;
}

export function UserEditRole(props: UserEditRoleProps) {
  const { title, userInfo, accountId } = { ...props };
  const [triggerSubmit, setTriggerSubmit] = useState(0);
  const [isLoaded, setIsLoaded] = useState(true);
  const [formTemplate, setTemplate] = useState<IField[]>();  
  const [disableCancelButton, setdisableCancelButton] = useState(false);
  const [disableSubmitButton, setdisableSubmitButton] = useState(false);
  const UN_ASSIGN = 'unassign';
  const ASSIGN = 'assign';

  const openAddDialog = () => {
    const modalElement = document.querySelector<Element>(
      'modus-modal#user-edit-role-dialog'
    );
    (modalElement as unknown as HTMLModusModalElement).open();
  };

  const selectRoleOptions: readonly FieldOption[] = [
    {
      value: 'TCP_COMPANY_ADMIN',
      label: t('Common.Role.Title.Administrator'),
      helperText: t('Common.Role.Description.Administrator'),
    },
    // { value: 'TCP_ACCOUNT_OWNER', label: t('Common.Role.Title.Owner'), helperText: t('Common.Role.Description.Owner') },
    {
      value: 'TCP_END_USER',
      label: t('Common.Role.Title.User'),
      helperText: t('Common.Role.Description.User'),
    },
  ];

  const availableRoles = [
    {
      value: 'TCP_COMPANY_ADMIN',
      label: t('Common.Role.Title.Administrator'),
      mapper: 'Account Administrator',
      helperText: t('Common.Role.Description.Administrator'),
    },
    {
      value: 'TCP_ACCOUNT_OWNER',
      label: t('Common.Role.Title.Owner'),
      mapper: 'Account Owner',
      helperText: t('Common.Role.Description.Owner'),
    },
    {
      value: 'TCP_END_USER',
      label: t('Common.Role.Title.User'),
      mapper: 'Standard User',
      helperText: t('Common.Role.Description.User'),
    },
  ];

  const getExisitingRoleOptions = (roles: string[]): FieldOption[] => {
    const options: FieldOption[] = [];
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        const roleStr = role ? role.trimStart() : '';
        const option = availableRoles.find((r) => r.mapper === roleStr);
        if (option) {
          options.push(option);
        }
      });
    }
    return options;
  };

  const template = [
    {
      type: 'webinput',
      label: t('Users.EditRoleModal.FirstName'),
      required: false,
      name: 'firstName',
      disabled: true,
    },
    {
      type: 'webinput',
      label: t('Users.EditRoleModal.LastName'),
      required: false,
      name: 'lastName',
      disabled: true,
    },
    {
      type: 'webinput',
      label: t('Users.EditRoleModal.Email'),
      required: false,
      name: 'email',
      disabled: true,
    },
    {
      type: 'multiselect',
      label: t('Users.AddModal.SecurityRole'),
      required: true,
      name: 'securityRole',
      options: selectRoleOptions,
      defaultValue: getExisitingRoleOptions(userInfo?.role),
      onChange: (newVal : any) =>{
        const existingRoles = getExisitingRoleOptions(userInfo?.role);
        if(newVal?.length !== existingRoles?.length){
          setdisableSubmitButton(false);
        }else {
          let isSame = true;
          newVal?.forEach((newValItem: any) => {
            const existingRole = existingRoles?.find((existingRoleItem) => existingRoleItem.value === newValItem.value);
            if(!existingRole){
              isSame = false;
            }
          });
          setdisableSubmitButton(isSame);
        }
      }
    },
  ];

  useEffect(() => {
    setdisableSubmitButton(true);
    setTemplate(template);
    openAddDialog();
  }, []);

  const onSubmit = (data: any) => {
    setdisableCancelButton(true);
    setdisableSubmitButton(true);
    disableSelect(true);
    console.log('data', data);
    const request: IRoleEditReq = {
      accountId: accountId,
      userId: userInfo?.uuid,
    };

    const roleAssignments: { roleId: any; action: string }[] = [];

    //Clear existing roles
    const existingAssignments = getExisitingRoleOptions(userInfo?.role);
    existingAssignments?.forEach((role: any) => {
      const isRolePresent = data.securityRole?.find((newRole: any) => newRole.value === role.value);
      if(!isRolePresent){
        roleAssignments.push({
          roleId: role.value,
          action: UN_ASSIGN,
        });
      }      
    });
    //Assign new roles
    data.securityRole?.forEach((role: any) => {
      const isRolePresent = existingAssignments?.find((roleExisting: any) => roleExisting.value === role.value);
      if(!isRolePresent){
      roleAssignments.push({
        roleId: role.value,
        action: ASSIGN,
      });
     }
    });
    request['roleAssignments'] = roleAssignments;
    setIsLoaded(false);
    updateUserRoles(request).then(
      (res) => {
        setIsLoaded(true);
        setdisableCancelButton(false);
        setdisableSubmitButton(false);
        if (res){
          let isSuccessful = true;
          const roleAssignmentsStatus = res.data? res.data.roleAssignments: [{ status: res.status?.toString()}];
          if(roleAssignmentsStatus){
            for(let i=0; i<roleAssignmentsStatus.length; i++){
              if(roleAssignmentsStatus[i].status !== '201' && roleAssignmentsStatus[i].status !== '204'){
                isSuccessful = false;
                break;
              }
            }
          }else {
            isSuccessful = false;
          }
          
          if(isSuccessful){
            props.onSuccess(res);
          }else {
            props.onError(res);
          }          
        }else {
          props.onError(res);
        }
        setIsLoaded(true);
        setdisableCancelButton(false);
        setdisableSubmitButton(false);
        document.querySelector('modus-modal')?.close();
      },
      (error) => {
        console.log('error', error);
        setIsLoaded(true);
        setdisableCancelButton(false);
        setdisableSubmitButton(false);
        props.onError(error);
        document.querySelector('modus-modal')?.close();
      }
    );
  };

  const disableSelect = (flag: boolean)=>{
    setTemplate((prevState) => {
      const updatedTemplate = prevState?[...prevState]:[];
      if(updatedTemplate){
        return updatedTemplate?.map((field: any) => {
          if(flag && field.type === 'multiselect'){
            field['disabled'] = true;
          }
          return field;
        });
      }
      return updatedTemplate;      
    });
  }

  const onActionClick = () => {
    setTriggerSubmit((value) => value + 1);
  };

  return (
    <ModusModal
      id="user-edit-role-dialog"
      backdrop={'static'}
      zIndex='1050'
      headerText={title}
      primaryButtonText={t('Common.Submit')}
      primaryButtonDisabled={disableSubmitButton}
      onPrimaryButtonClick={onActionClick}
      secondaryButtonText={t('Common.Cancel')}
      secondaryButtonDisabled={disableCancelButton}
      onSecondaryButtonClick={() => {
        setIsLoaded(true);
        document.querySelector('modus-modal')?.close();
      }}
      onClosed={() => {
        props.onClose();
      }}
    >
      <div>
        {!isLoaded && (
          <div className="progress-bar">
            <div className="progress-bar-value"></div>
          </div>
        )}
        <FormRenderer
          cssStyle={{ width: '400px', height: '350px' }}
          formData={{
            firstName: userInfo?.firstName,
            lastName: userInfo?.lastName,
            email: userInfo?.email,
          }}
          formTemplate={formTemplate}
          onSubmit={onSubmit}
          triggerSubmit={triggerSubmit}
          actionId={ActionType.ADD}
        ></FormRenderer>
      </div>
    </ModusModal>
  );
}

export default UserEditRole;
