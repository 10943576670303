import { InfoCard, ModalMessage, ModalPopup } from '@workflow/ui';
import { FeatureListItem } from '../../models/FeatureListItem';
import StatusAlertComp from '../status-alert-comp/status-alert-comp';
import styles from './list-features.module.scss';
import i18next, { t } from 'i18next';
import { useEffect, useState } from 'react';
import * as langSet from "../../supportedLang.json";
import { useNotificationContext } from '../notification-provider/NotificationProvider';
import { DateUtil } from '../../../../../libs/ui/src/utils/DateUtil';
import { SharedUiCard } from '@workflow/shared/ui-card';
import {CircleSpinner} from '@workflow/shared/info-graphics';
import SharedGraphics from 'libs/shared/graphics/src/lib/shared-graphics';
import { Constants } from '../../API/AppConstants';

/* eslint-disable-next-line */
export interface ListFeaturesProps {
  error?: any;
  isLoaded: boolean;
  title?: string;
  subtitle?: string;
  catalogueList: FeatureListItem[];
  showConfirmDialog?: boolean;
  handleClose?: any;
  handleConfirm?: any;
  modalMessage?: ModalMessage;
  type: 'PAGE' | 'LIST' | 'PLACEHOLDER' | 'PROFILE';
  comingSoonMsg?: any;
  comingSoonTitle?: string;
  inviteDescription1?: string;
  inviteDescription2?: string;
  inviteType?: string;
  clickHandlers?: any;
  styles?: {
    minWidth: string,
    minHeight: string
  },
  source?: string;
}

export function ListFeatures(props: ListFeaturesProps) {
  const { type, error, isLoaded, title, subtitle, catalogueList, showConfirmDialog, handleClose, handleConfirm, modalMessage } = { ...props };
  const [selectedLang, setSelectedLang] = useState<{ code?: string, native?: string, name?: string }>();
  const { alert, alertCloseHandler, showAlertText } = useNotificationContext();
  // const changingLanguage = (lang?: { code?: string, native?: string, name?: string }) => {
  //   //display language  
  //   if (lang?.native) localStorage.setItem("choosenLang", lang.native);
  //   // i18n language
  //   if (lang?.code) {
  //     const language = lang.code;
  //     localStorage.setItem("userLang", language);
  //     i18next.changeLanguage(language).then(() => {
  //       DateUtil.localize();
  //     });
  //   }
  // }

  
  const noResultsTitle       = t('Capability.No.Results.title', {page: title});
  const noResultsDescription = t('Capability.No.Results.Descr', {page: title});
  // let tabTitle = t('My.Capability.Tab.Web');
  // if(title) {
  //   if(title.toLowerCase() === Constants.ABSTRACT_PAGE_TAB_WORKFLOWS_ID) {
  //     tabTitle = t('My.Capability.Tab.workflow');
  //   }
  // }

  const initSelectedLanguage = () => {
    const choosenLanguage = localStorage.getItem("choosenLang");
    if (choosenLanguage) {
      const selectedLanguage = langSet.languages.find(lang => lang.native === choosenLanguage);
      setSelectedLang(selectedLanguage);
    }
  }
  const getTitle = () => {
    return selectedLang ? selectedLang.native : localStorage.getItem("choosenLang") ? localStorage.getItem("choosenLang") : t("Profile.SelectLanguage.Field");
  }
  useEffect(() => {
    initSelectedLanguage();
  }, []);
  if (error) {
    return <div className='container-error'>
      <img
        data-auto="progress"
        width="120"
        height="120"
        src="images/cloud.svg"
        alt="error..."
      ></img>
      <StatusAlertComp showAlertText={true} alert={error} alwaysShowAlert={true} ></StatusAlertComp>
    </div>
  } else if (!isLoaded) {
     return <CircleSpinner size="48px" text={t('Common.Loading.Title')}></CircleSpinner>
  } else {
    return (
      <div>
        {type === 'PAGE' || type === 'PLACEHOLDER' || type === 'PROFILE' ? (
          <>
            {/* {title && <h1 data-auto="marketplace-title" className="cust-display-heading p-0.5">{title}</h1>} */}
            {/* {subtitle ? <h1 data-auto="marketplace-desc" className="cust-display-subheading px-0.5 py-0.5">{subtitle}</h1> : ''} */}
          </>
        ) : (
          ''
        )}
        {/* {type === 'PROFILE' ? (
          <Form onSubmit={() => changingLanguage(selectedLang)}>
            <Form.Group className={styles['langDropdown']}>
              <DropdownButton
                variant="outline-primary"
                id="dropdown-basic-button"
                title={getTitle()}
                className="ml-3"
              >
                {langSet.languages.map((val, index) => (
                  <Dropdown.Item
                    active={selectedLang?.code === val.code}
                    key={val.code}
                    eventKey={index}
                    onClick={() => {
                      setSelectedLang(val);
                    }}
                  >
                    {val.native}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Button
                type="submit"
                variant="primary"
                className={styles['langSubmitBtn']}
              >
                {t('Common.Submit')}
              </Button>
            </Form.Group>
          </Form>
        ) : (
          ''
        )} */}
        {alert && (
          <div className="pt-4">
            <StatusAlertComp
              showAlertText={showAlertText}
              alert={alert}
              alertCloseHandler={alertCloseHandler}
            ></StatusAlertComp>
          </div>
        )}
        {catalogueList.length === 0 ? (
          <SharedGraphics
            source={props.source}
            title={noResultsTitle}
            description={noResultsDescription}
            btnText={t('Capability.No.Results.btn')}
            btnLink={''}
          />
        ) : (
          <div className="list row justify-content-start pt-2 row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
            {catalogueList.map((item) => {
              const statusIcon = {
                icon: item.statusIcon,
                style: {
                  color: item.statusIcon === 'error' ?'red':'#349C44',
                },
              };
              if (
                props.source &&
                props.source == Constants.PAGE_DEVELOPER_TOOLS_ID
              ) {
               return <div
                  data-auto="service-catalogue"
                  key={item.id}
                  className="col my-2"
                >
                  <InfoCard
                    title={item.title}
                    subtitle={item.subtitle}
                    info={item.info}
                    className={item.className ? item.className : 'shadow'}
                    actions={item.actions}
                    image={item.image}
                    id={item.id}
                    statusIcon={item.statusIcon}
                    minWidth={props?.styles?.minWidth}
                    height={props?.styles?.minHeight}
                    borderTop={item.borderTop}
                    titleLink={item.titleLink}
                    clickHandlers={props.clickHandlers}
                    tags={item.tags}
                  />
                </div>;
              } else {
                return (
                  <div
                    data-auto="service-catalogue"
                    key={item.id}
                    className="col my-2"
                  >
                    <SharedUiCard
                      title={item.title}
                      subtitle={item.subtitle}
                      info={item.info}
                      className={item.className ? item.className : 'shadow'}
                      actions={item.actions}
                      image={item.image}
                      id={item.id}
                      statusIcon={statusIcon}
                      width={'100%'}
                      height={props?.styles?.minHeight}
                      borderTop={item.borderTop}
                      titleLink={item.titleLink}
                      clickHandlers={props.clickHandlers}
                      tags={item.tags}
                    />
                  </div>
                );
              }
            })}
          </div>
        )}

        {type === 'PAGE' ? (
          <ModalPopup
            title={modalMessage ? modalMessage.title : ''}
            message={modalMessage ? modalMessage.message : ''}
            confirmBtnTitle={modalMessage ? modalMessage.confirmBtnTitle : ''}
            showConfirmDialog={showConfirmDialog}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default ListFeatures;
