import { ModusCard } from '@trimble-oss/modus-react-components';
import styles from './shared-ui-card.module.scss';
import { Converter, ActionButton, ActionProps } from '@workflow/ui';
import { Link } from 'react-router-dom';
import { ModusButton } from '@trimble-oss/modus-react-components';
import { useNavigate } from 'react-router-dom';

/* eslint-disable-next-line */
export interface SharedUiCardProps {
  title: string;
  subtitle?: string;
  info: string;
  statusIcon?: { icon?: string; style: any };
  actions?: ActionProps[];
  className: string;
  image?: string;
  id: string;
  width?: string;
  height?: string;
  borderTop?: string;
  titleLink?: string;
  show?: boolean;
  clickHandlers?: any;
  tags?: string[];
}

export function SharedUiCard(props: SharedUiCardProps) {
  const {
    title,
    subtitle,
    info,
    statusIcon,
    actions,
    className,
    image,
    id,
    width,
    height,
    borderTop,
    titleLink,
    show,
    clickHandlers,
    tags,
  } = { ...props };
  const titleName = Converter.replaceSpaceAndColon('card_title', title, '');
  const viewMoreName = Converter.replaceSpaceAndColon(
    'view_more_btn',
    title,
    ''
  );
  const navigate = useNavigate();
  const getHandlerCallBack = (label: string | undefined) => {
    return label ? clickHandlers[label] : () => null;
  };
  const avatarTxt = image ? undefined : title.charAt(0);
  return (
    <ModusCard
      width={width}
      height={height}
      aria-label="ui-card"
      showCardBorder={true}
      showShadowOnHover={true}
      borderRadius="8px"
      id={id}
    >
      <div className={styles['ui-card__layout']}>
        <div className={styles['ui-card__header']}>
          <div className={styles['ui-card__header-avatar']}>
            {image && (
              <img
                alt={'logo of a product'}
                className={styles['ui-card__header-image']}
                src={image}
              />
            )}
            {!image && (
              <div className={styles['ui-card__header-avatar-container']}>
                <span className={styles['ui-card__header-avatar-text']}>
                  {avatarTxt}
                </span>
              </div>
            )}
          </div>

          <div className={styles['ui-card__header-text']}>
            <h3>
              {titleLink ? (
                <Link className={styles['ui-card__header-link']} to={titleLink}>
                  <span
                    className={
                      styles['ui-card__header-title'] +
                      ' text-trimble-blue-dark'
                    }
                  >
                    {title}
                  </span>
                </Link>
              ) : (
                <span className={styles['ui-card__header-title']}>{title}</span>
              )}
            </h3>
            <span
              className={styles['ui-card__header-subtitle']}
              id="card-subtitle"
            >
              {subtitle}
            </span>
          </div>
          {statusIcon && statusIcon.icon && (
            <div className={styles['ui-card__header-status']}>
              <i className={'material-icons-outlined'} style={statusIcon.style}>
                {statusIcon.icon}
              </i>
            </div>
          )}
        </div>
        {tags && tags.length > 0 && (
          <div className={styles['ui-card__tag']}>
            {tags.map((tag, index) => {
              return (
                <>
                  <span key={tag}>{tag}</span>
                  {index < tags.length - 1 && <span> • </span>}
                </>
              );
            })}
          </div>
        )}
        {info && <p className={styles['ui-card__info']}>{info}</p>}
        <div className={styles['ui-card__action-buttons']}>
          {actions &&
            actions.length > 0 &&
            actions.map((item) => {
              if (item.type === 'button' && item.show) {
                return (
                  <ActionButton
                    key={item.actionId}
                    type={item.type}
                    isDisabled={item.isDisabled}
                    onClickHandler={getHandlerCallBack(item.clickHandlerLabel)}
                    title={item.title}
                    status={item.status}
                    to={item.to}
                    cardId={id}
                    cardTitle={title}
                    isOnload={item.isOnload}
                    actionId={item.actionId}
                  ></ActionButton>
                );
              } else if (item.type === 'launch' && item.show) {
                return (
                  item.to && (
                    <ModusButton
                      key={item.actionId}
                      size={item.size || 'small'}
                      id={viewMoreName}
                      data-auto="view_more"
                      buttonStyle="outline"
                      color="primary"
                      onClick={() => {
                        window.open(item.to, '_blank');
                      }}
                    >
                      <div className={styles['buttonStyle']}>
                        {item.title}
                        {item.icon == 'launch' && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="currentColor"
                            className="mi-solid mi-launch"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19.78 19.78H4.22V4.22h6.67c.61 0 1.11-.5 1.11-1.11C12 2.5 11.5 2 10.89 2H3c-.55 0-1 .45-1 1v18c0 .55.45 1 1 1h18c.55 0 1-.45 1-1v-7.89c0-.61-.5-1.11-1.11-1.11-.61 0-1.11.5-1.11 1.11v6.67ZM14.22 3.11c0 .61.5 1.11 1.11 1.11h2.88L8.07 14.36c-.43.43-.43 1.13 0 1.57.43.43 1.13.43 1.57 0L19.78 5.79v2.88c0 .61.5 1.11 1.11 1.11.61 0 1.11-.5 1.11-1.11V3c0-.55-.45-1-1-1h-5.67c-.61 0-1.11.5-1.11 1.11Z" />
                          </svg>
                        )}
                      </div>
                    </ModusButton>
                  )
                );
              } else {
                return (
                  item.to && (
                    <ModusButton
                      key={item.actionId}
                      size={item.size || 'small'}
                      id={viewMoreName}
                      data-auto="view"
                      buttonStyle="outline"
                      color="primary"
                      onClick={() => {
                        navigate(item.to? item.to: '');
                      }}
                    >
                      <div className="buttonStyle">
                        {item.title}
                      </div>
                    </ModusButton>
                  ))
              }
            })}
        </div>
      </div>
    </ModusCard>
  );
}

export default SharedUiCard;
