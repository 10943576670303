export type OptionType ={ label: string; value: string }[];
export function calculateDates(selectedValue: string): {
  newStartDate: Date;
  newEndDate: Date;
} {
  const currentDate = new Date();
  let newStartDate = currentDate;
  let newEndDate = currentDate;

  switch (selectedValue) {
    case '2 months':
      newStartDate = new Date(
        currentDate.getTime() - 2 * 30 * 24 * 60 * 60 * 1000
      );
      break;
    case '3 months':
      newStartDate = new Date(
        currentDate.getTime() - 3 * 30 * 24 * 60 * 60 * 1000
      );
      break;
    case '6 months':
      newStartDate = new Date(
        currentDate.getTime() - 6 * 30 * 24 * 60 * 60 * 1000
      );
      break;
    case 'last week':
      newStartDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      break;
    case 'last 2 weeks':
      newStartDate = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);
      break;
    case 'last 3 weeks':
      newStartDate = new Date(currentDate.getTime() - 21 * 24 * 60 * 60 * 1000);
      break;
    case 'yesterday':
      newStartDate = new Date(currentDate.getTime() - 1 * 24 * 60 * 60 * 1000);
      newEndDate = newStartDate;
      break;
    case 'last 7 days':
      newStartDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      break;
    case 'last 14 days':
      newStartDate = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);
      break;
  }

  return { newStartDate, newEndDate };
}

export function getSubOptions(
  selectedOption: string
): OptionType {
  switch (selectedOption) {
    case 'month':
      return [
        { label: '2 Months', value: '2 months' },
        { label: '3 Months', value: '3 months' },
        { label: '6 Months', value: '6 months' },
        { label: 'Custom', value: 'custom' },
      ];
    case 'week':
      return [
        { label: 'Last Week', value: 'last week' },
        { label: 'Last 2 Weeks', value: 'last 2 weeks' },
        { label: 'Last 3 Weeks', value: 'last 3 weeks' },
        { label: 'Custom', value: 'custom' },
      ];
    case 'day':
      return [
        { label: 'yesterday', value: 'yesterday' },
        { label: 'Last 7 Days', value: 'last 7 days' },
        { label: 'Last 14 Days', value: 'last 14 days' },
        { label: 'Custom', value: 'custom' },
      ];
    default:
      return [];
  }
}

export function calculateDateval(value: string): {
  newStartDate: Date;
  newEndDate: Date;
} {
  const currentDate = new Date();
  let newStartDate = currentDate;
  const newEndDate = currentDate;

  switch (value) {
    case 'week':
      newStartDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      break;
    case 'month':
      newStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      break;
    default:
      newStartDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      break;
  }
console.log(newStartDate,newEndDate)
  return { newStartDate, newEndDate };
}
