import { useAuth } from '../../hooks/useAuthProviderHook';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { setLocalStorageValue } from '../../hooks/useLocalStrorage';
import { CircleSpinner } from '@workflow/shared/info-graphics';
/* eslint-disable-next-line */
export interface LoginRedirectProps {}

export function LoginRedirect(props: LoginRedirectProps) {
  const { onLoginRedirect } = useAuth();
  const [searchParams] = useSearchParams();

  
  useEffect(() => {
    const userType = searchParams.get('userType');
    if (userType) setLocalStorageValue('userType', userType);
    if (onLoginRedirect) {
      onLoginRedirect();
    }
  }, []);
  return (
    <div className="px-3">
      <CircleSpinner size="48px" text={'Redirecting...'} />
    </div>
  );
}

export default LoginRedirect;
