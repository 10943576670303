import axios from 'axios';
import { t } from 'i18next';
import {
  requestSuccessHandler,
  requestErrorHandler,
  responseSuccessHandler,
  responseErrorHandler,
} from '../hooks/axiosInstanceHelper';
import { globalConfig, isDevelopment } from './Utils/enivronment-util';
import { ModusDataTableCellLink} from '@trimble-oss/modus-web-components';
import { Constants } from './AppConstants';
const axiosInstance = axios.create({
  baseURL: isDevelopment
    ? process.env['NX_CLOUD_BASE_URL'] +
      '' +
      process.env['NX_CLOUD_PROFILES_BASE_SEGMENT']
    : `${'APP.CLOUD.BASE.URL'}${'APP.CLOUD.PROFILES.BASE.SEGMENT'}`,
});

axiosInstance.interceptors.request.use(
  requestSuccessHandler,
  requestErrorHandler
);
axiosInstance.interceptors.response.use(
  responseSuccessHandler,
  responseErrorHandler
);

export interface ProfileUserResponse {
  trn: string;
  uuid: string;
  deleted: boolean;
  createdTimeStamp: string;
  createdBy: string;
  updatedBy: string;
  updatedTimeStamp: string;
  primaryRegion: string;
  firstName: string;
  lastName: string;
  lastLoginTimeStamp: string;
  email: string;
}

export interface ICSUserData {
  status: string;
  name: string;
  email: string;
  role: string;
  uuid?: string;
  activeFrom?: string;
  firstName?: string;
  lastName?: string;
  action?: ModusDataTableCellLink;
  userInfo?: ProfileUserResponse;
}

export interface relationsResponse {
  data: relationData;
}

export interface relationData {
  uuid: string;
  sourceProfileUuid: string;
  sourceProfileType: string;
  sourceProfileTrn: string;
  targetProfileUuid: string;
  targetProfileType: string;
  targetProfileTrn: string;
  targetRelationName: string;
  attributes: {
    role: string;
  };
  deleted: boolean;
  createdTimeStamp: string;
  createdBy: string;
  updatedBy: string;
  updatedTimeStamp: string;
  primaryRegion: string;
}

const relationPostPayload = {
  relations: [
    {
      type: 'users',
      targetExpr: '',
      expr: '',
      relationName: 'member-of',
    },
  ],
};

export enum orderBy {
  'ASC',
  'DESC',
}

export interface profileSearchRequestPayload {
  context: string;
  sortBy?: string;
  order?: string;
  expr?: string;
}

export interface profilesResponse {
  data: any[];
  meta: {
    pageNumber: number;
    pageSize: number;
    resultCount: number;
    hasMore: boolean;
  };
}

export const UsersListService = {
  async getUsersByRelationShips(
    accountId: string,
    pageIndex: number,
    pageSize: number
  ) {
    //console.log('PageSize : ' + pageSize);
    const usersData: ProfileUserResponse[] = [];
    if (pageIndex === 0) {
      pageIndex = 1;
    }
    const relationsRes = await axiosInstance.request({
      method: 'POST',
      url: `/accounts/${accountId}/relations/_filter`,
      params: {
        page: pageIndex,
        size: pageSize,
      },
      data: relationPostPayload,
    });

    if (relationsRes && relationsRes.data && relationsRes.data.data) {
      for (let i = 0; i < relationsRes.data.data.length; i++) {
        //console.log(`Current User data : `+JSON.stringify(relationsRes.data.data[i]));
        const uuid = relationsRes.data.data[i].sourceProfileUuid;
        const userRes = await axiosInstance.request({
          method: 'GET',
          url: `/users/${uuid}`,
        });
        //console.log(`User Res : ${uuid} | ` + JSON.stringify(userRes));
        if (userRes && userRes.data) {
          usersData.push(userRes.data);
        }
      }
    }else{
      console.error(`Error while fetching users : `+JSON.stringify(relationsRes));
    }
    return usersData;
  },

  async getUsersByProfileFilter(
    accountId: string,
    pageIndex: number,
    pageSize: number,
    searchTxt?: string
  ) {
    const responseData: any = {
      data: [],
      meta: {
        pageNumber: 1,
        pageSize: 0,
        resultCount: 0,
        hasMore: false,
      },
    };
    const usersData: ProfileUserResponse[] = [];
    if (pageIndex === 0) {
      pageIndex = 1;
    }
    const filterPostPayload: profileSearchRequestPayload = {
      context: 'trn:profiles:accounts:' + accountId,
      "sortBy": "firstName",
      "order" : "ASC"
    };

    if (searchTxt) {
      // profiles team analyzing on this - search with space word on first name, last name and email is not working
      if(searchTxt.trim().includes(' ')){
        const firstWord = searchTxt.trim().split(' ')[0];
        filterPostPayload.expr = `firstName=match='${firstWord}'`;
      }else{
        filterPostPayload.expr = `firstName=match=${searchTxt},lastName=match=${searchTxt},email=match=${searchTxt}`;
      }
    }

    const relationsRes = await axiosInstance.request({
      method: 'POST',
      url: `/users/_filter`,
      params: {
        page: pageIndex,
        size: pageSize,
      },
      data: filterPostPayload,
    });

    if (relationsRes && relationsRes.data && relationsRes.data.data) {
      for (let i = 0; i < relationsRes.data.data.length; i++) {
        usersData.push(relationsRes.data.data[i]);
      }
      responseData['data'] = this.convertIntoTableData(usersData);
      responseData['meta'] = relationsRes.data.meta;
      if (responseData['data'].length > 0) {
        const uuidDetails = await this.getRolesByUsersUUID(
          accountId,
          responseData['data']
        );
        //console.log(`uuidDetails : ` + JSON.stringify(uuidDetails));
        responseData['data'] = responseData['data'].map(
          (eachData: ICSUserData) => {
            const uuid = eachData.uuid;
            //console.log(`uuid : ` + uuid);
            if (uuid) {
              //console.log(`uuid role : ` + uuidDetails[uuid]);
              eachData.role = uuidDetails[uuid];
              eachData.firstName=eachData.userInfo?.firstName;
              eachData.lastName=eachData.userInfo?.lastName;
            }
            return eachData;
          }
        );
      }
      // console.log(`updated role Details : ` + JSON.stringify(responseData['data']));
    }
    return responseData;
  },

  async getRolesByUsersUUID(accountId: string, tableData: ICSUserData[]) {
    let uuidResponse: any = {};
    let uuids = '';
    tableData.forEach((user) => {
      uuids = uuids + user.uuid + ',';
    });
    uuids = uuids.substring(0, uuids.length - 1);
    const postPayload = {
      sortBy: 'updatedTimeStamp',
      order: 'DESC',
      relations: [
        {
          type: 'users',
          relationName: 'member-of',
          sourceExpr: `uuid=in=(${uuids})`,
        },
      ],
    };
    const relationsRes = await axiosInstance.request({
      method: 'POST',
      url: `/accounts/${accountId}/relations/_filter`,
      data: postPayload,
    });
    if (relationsRes && relationsRes.data && relationsRes.data.data) {

      /** grouping user by role */
      uuidResponse = relationsRes.data.data.reduce((x: any, y: any) => {
        const role = this.convertProfileRoleToIcsRole(y.attributes.role);
        (x[y.sourceProfileUuid] = x[y.sourceProfileUuid] || []).push(role);
        return x;
      }, {});
    }
    return uuidResponse;
  },

  convertIntoTableData(usersData: ProfileUserResponse[]) {
    const tableData: ICSUserData[] = [];
    usersData.forEach((user) => {
      const tableRow: ICSUserData = {
        status: user.deleted ? 'Inactive' : 'Active',
        name: user.firstName + ' ' + user.lastName,
        //name: user.firstName,
        email: user.email,
        role: 'Member',
        uuid: user.uuid,
        activeFrom: new Date(user.createdTimeStamp).toDateString().substring(4),
        userInfo: user
      };
      tableData.push(tableRow);
    });
    return tableData;
  },

  /**
   * based on the profile x user role
   * it will convert into ICS tole
   * @param profileRole 
   * @returns 
   */
  convertProfileRoleToIcsRole(profileRole: string) {
    let icsRole = t('Users.Tables.Role.User');
    if (profileRole === Constants.USER_PROFILE_ROLE_ADMIN_TEXT) {
      icsRole = t('Users.Tables.Role.Admin');
    } else if (profileRole === Constants.USER_PROFILE_ROLE_OWNER_TEXT) {
      icsRole = t('Users.Tables.Role.Owner');
    } else if (profileRole === Constants.USER_PROFILE_ROLE_READ_ONLY_TEXT) {
      icsRole = t('Users.Tables.Role.Reader');
    } else if (profileRole === Constants.USER_PROFILE_ROLE_MANAGER_TEXT) {
      icsRole = t('Users.Tables.Role.Manager');
    }    
    return ' '+icsRole ;
  }

  

};
