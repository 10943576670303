import axios from 'axios';
import {
  requestSuccessHandler,
  requestErrorHandler,
  responseSuccessHandler,
  responseErrorHandler,
} from '../hooks/axiosInstanceHelper';
import { globalConfig, isDevelopment } from './Utils/enivronment-util';
const axiosInstance = axios.create({
  baseURL: isDevelopment
    ? process.env['NX_API_BASE_URL'] + '' + process.env['NX_API_URL_PATTERN']
    : `${'APP.API.BASE.URL'}${'APP.API.URL.PATTERN'}`,
});

axiosInstance.interceptors.request.use(
  requestSuccessHandler,
  requestErrorHandler
);

axiosInstance.interceptors.response.use(
  responseSuccessHandler,
  responseErrorHandler
);
export const InviteUserAPI = {
  mailMarketingTeam(email: string | undefined, emailData: any) {
    const {
      content = '',
      subject = '',
      sender = '',
      userDetails = {},
    } = { ...emailData };
    return axiosInstance.request({
      method: 'POST',
      url: `/publish/v1`,
      data: {
        email: {
          template: '08e2ae58287741b7890064ce0480b19a',
          locale: 'en',
          template_variables: {
            notification_type: subject,
            user_name: ' Team',
            content_line_2: content,
            sender_name: sender,
          },
          to: email,
        },
      },
    });
  },
  mailUser(email: string | undefined, emailData: any) {
    const {
      content = '',
      subject = '',
      sender = '',
      userDetails = {},
    } = { ...emailData };
    return axiosInstance.request({
      method: 'POST',
      url: `/publish/v1`,
      data: {
        email: {
          template: '08e2ae58287741b7890064ce0480b19a',
          locale: 'en',
          template_variables: {
            notification_type: subject,
            user_name: ' ' + userDetails.name,
            content_line_2: content,
            sender_name: sender,
          },
          to: email,
        },
      },
    });
  },
};