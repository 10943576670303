import React, { useEffect, useState } from 'react';
import { IField } from './models/IFormTemplate';
import { UiFormInput, UiForm } from '@workflow/shared/ui-form';
import { UseFormReturn, useForm } from 'react-hook-form';
import moment from 'moment';
import { getCurrentMomentWithIncrement, ActionType } from '@workflow/ui';
import { t } from 'i18next';

export interface FormRendererProps {
  formData?: any;
  formTemplate?: IField[];
  onSubmit?: (data: any) => void;
  triggerSubmit: number;
  actionId?: ActionType;
  cssStyle?: { [key: string]: string };
  defaultValues?: any;
  resetForm?: boolean;
}
export function FormRenderer(props: FormRendererProps) {
  const { formData, formTemplate, triggerSubmit } = { ...props };
  const [formDataState, setFormDataState] = useState<any | undefined>({});
  const formRef: any = React.createRef();
  const onSubmit = () => {
    if (formRef.current) {
      (formRef.current as any).dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
  };

  const methods: UseFormReturn<any> | undefined = useForm<any>({
    values: formDataState,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });
  useEffect(() => {
    if (props.actionId === ActionType.ADD) {
      const data: any = formData ? formData : {};
      const fields = formTemplate?.filter(
        (template) => template.type === 'date-time-picker'
      );
      fields?.forEach((field) => {
        data[field.name] = field.defaultValue;
      });
      setFormDataState(data);
    } else {
      formTemplate?.map((field: any) => {
        if (field.type === 'date-time-picker') {
          field.validate['invalidDate'] = (value: any) => {
            if (
              (value &&
                formData[field.name] &&
                value == formData[field.name]) ||
              (value &&
                moment(value).isSameOrAfter(
                  getCurrentMomentWithIncrement('29', 'minutes')
                ))
            ) {
              return undefined;
            } else {
              return t('Workflows.Date.InvalidValue');
            }
          };
        }
        return field;
      });
      setFormDataState(formData);
    }
  }, [formData, formTemplate]);

  useEffect(() => {
    if (triggerSubmit > 0) onSubmit();
  }, [triggerSubmit]);

  useEffect(() => {
    if (props.resetForm) {
      methods.reset({
        email: '',
        securityRole: '',
      });
    }
  }, [props.resetForm]);

  if (formTemplate) {
    return (
      <div style={props.cssStyle}>
        <UiForm
          ref={formRef}
          hasSubmit={false}
          methods={methods}
          onSubmit={props.onSubmit}
        >
          {formTemplate?.map((field: any) => {
            let watchValue = field.watch;
            if (field && field.watch && field.watch.options) {
              const optionsKeys = Object.keys(field.watch.options);
              optionsKeys.forEach((eachKey) => {
                if (
                  typeof field.watch.options[eachKey] === 'string' &&
                  field.watch.options[eachKey].includes('...')
                ) {
                  const optionValue = field.watch.options[eachKey];
                  const start = optionValue.split('...')[0];
                  const end = optionValue.split('...')[1];
                  const finalValue = [];
                  for (let i = start; i <= end; i++) {
                    finalValue.push(i + '');
                  }
                  field.watch.options[eachKey] = finalValue;
                }
                watchValue = field.watch;
              });
            }
            return (
              <UiFormInput
                key={field.name}
                name={field.name}
                required={field.required}
                pattern={new RegExp(field.regex)}
                label={field.label ? field.label : field.name}
                defaultValue={field.defaultValue}
                type={field.type}
                options={field.options}
                message={field.message}
                row={field.row}
                size={field.size}
                watch={watchValue}
                description={field.description}
                onChange={field.onChange}
                isControlled={field.isControlled}
                min={field.min}
                validate={field.validate}
                helperText={field.helperText}
                disabled={field.disabled}
              />
            );
          })}
        </UiForm>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default FormRenderer;
