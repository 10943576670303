import { Ability, Action } from './Ability';
import {
  FeaturePermissions,
  RoleMapping,
  RoleActionMapping,
} from './FeatureCapabilities';

export class Authorization {
  private permissions: FeaturePermissions | undefined;
  public currentRole: string | undefined;
  public init(roleName: string): boolean {
    this.currentRole = roleName;
    this.permissions = RoleMapping[roleName];
    return false;
  }

  public updatePermissions(featureIds: string[]): void {
    if (this.currentRole && featureIds && featureIds.length) {
      const actionMap = RoleActionMapping[this.currentRole];
      featureIds.forEach((featureId) => {
        if (this.permissions && !this.permissions[featureId]){
          //console.log("featureId", featureId)
          this.permissions[featureId] = { permissions: actionMap };
        }
      });
    }
    //console.log('this.permissions', this.permissions);
  }

  public for(featureName: string): Ability {
    const permission = this.permissions
      ? this.permissions[featureName]
      : undefined;

    return new Ability({}, permission || { permissions: [] });
  }

  public hasPermission(authorize: {
    featureName: string | string[];
    actions: Action;
  }): boolean {
    if (Array.isArray(authorize.featureName)) {
      const permissions: boolean[] = authorize.featureName.map((feature) => {
        return this.for(feature).can(authorize.actions);
      });

      return permissions && permissions.length
        ? permissions.some((perm) => perm)
        : false;
    } else {
      return this.for(authorize.featureName).can(authorize.actions);
    }
  }

  public clear(): void {
    this.permissions = undefined;
  }
}
