import styles from './chart-box.module.scss';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import addCircle from './assets/images/add-circle.svg';
import FullScreenChartPage from '../full-screen/full-screen';
import deleteicon from './assets/images/delete.svg';
import fullscreenicon from './assets/images/full-screen.svg';
import { SessionData } from '../../types/VisualizationTypes';
import { Entryvalues } from '../../types/DataRepresentationTypes';
import {
  getChartStyle,
  customStyles,
  chartStyles,
  getChartComponent,
} from '../../utils/chartUtils';
import {
  getSelectedFeatureMeta,
  optionsForSpecificDimension,
} from '../../utils/transformUtils/dataTransformations';
import { optionsData } from '../../utils/transformUtils/chartOptions';
import { processAndFilterData } from '../../utils/dataProcessing';
import { CircleSpinner } from '@workflow/shared/info-graphics';

interface BoxProps {
  index: number;
  title?: string;
  featureId: string;
  selectedChart: string | null;
  showText: boolean;
  dimension: string;
  chartComponent: React.ReactNode | null;
  features?: any;
  setFeatures?: any;
  sessionData: SessionData | null;
  setWidgetCharts?: any;
  showTextCallback?: any;
  startDate: any;
  endDate: any;
  widgetChartData?: any;
  setIsWidgetVisible?: any;
  setFinalFeature?: any;
  handlerCallback?: {
    getData: any;
    processAndFilterData?: any;
  };
  accountId?: any;
  handleDeleteChart?: any;
  chartOptions?: any;
  initialLoad?: boolean;
}

export const ChartBox: React.FC<BoxProps> = ({
  index,
  featureId,
  selectedChart,
  title,
  showText,
  dimension,
  chartComponent,
  features,
  setFeatures,
  sessionData,
  setWidgetCharts,
  showTextCallback,
  startDate,
  endDate,
  widgetChartData,
  setIsWidgetVisible,
  setFinalFeature,
  handlerCallback,
  accountId,
  handleDeleteChart,
  chartOptions,
  initialLoad,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [boxData, setBoxData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const chartStyle = getChartStyle(selectedChart);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let selectedDimensionData: any;
    if (featureId && dimension) {
      if (widgetChartData) {
        selectedDimensionData = getSelectedFeatureMeta(
          Array.isArray(widgetChartData)
            ? widgetChartData.find((item: any) => item.value === featureId)
            : widgetChartData,
          dimension
        );
      }

      if (selectedDimensionData && optionsData) {
        setOptions(
          optionsForSpecificDimension(selectedDimensionData, optionsData)
        );
      }
    }
  }, [dimension, featureId, widgetChartData]);

  useEffect(() => {
    if (featureId && dimension && !initialLoad) {
      setIsLoading(true);
      handlerCallback?.getData(featureId, dimension).then(
        (result: any) => {
          console.log('I am inside box,analyticsData', result);
          setBoxData(result);
          setIsLoading(false);
        },
        (error: any) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    }
  }, [accountId, startDate, endDate, initialLoad]);

  useEffect(() => {
    console.log('changed data inside box ', boxData, featureId, dimension);
    let updatedSessionData: any = {
      labels: [],
      datasets: [
        {
          label: dimension,
          data: [],
          backgroundColor: [
            'rgba(75,192,192,1)',
            '#ecf0f1',
            '#50AF95',
            '#f3ba2f',
            '#2a71d0',
          ],
        },
      ],
    };

    if (boxData && featureId && dimension) {
      if (handlerCallback && handlerCallback.processAndFilterData) {
        const processedData = handlerCallback.processAndFilterData(
          boxData,
          featureId,
          dimension
        );
        console.log(processedData);
        updatedSessionData = processedData;
      } else if (boxData?.data?.length) {
        const { labels, datavals } = processAndFilterData(
          boxData.data,
          dimension,
          startDate,
          endDate
        );

        updatedSessionData = {
          labels: labels,
          datasets: [
            {
              label: dimension,
              data: datavals,
              backgroundColor: [
                'rgba(75,192,192,1)',
                '#ecf0f1',
                '#50AF95',
                '#f3ba2f',
                '#2a71d0',
              ],
            },
          ],
        };
      }
    }
    if (!initialLoad)
      setFeatures((prevFeatures: any) => {
        return prevFeatures.map((feature: any) => {
          if (feature.featureId === featureId) {
            const updatedBoxes = feature.boxes.map(
              (box: any, boxIndex: number) => {
                if (boxIndex === index) {
                  box.sessionData = updatedSessionData;
                  if (updatedSessionData.datasets[0].data?.length <= 0) {
                    box.chartComponent = <h4>No Data</h4>;
                  } else {
                    box.chartComponent = getChartComponent(
                      box.selectedChart,
                      boxIndex,
                      updatedSessionData,
                      chartOptions
                    );
                  }
                }
                return box;
              }
            );

            return {
              ...feature,
              boxes: updatedBoxes,
            };
          }
          return feature;
        });
      });
  }, [boxData, startDate, endDate]);

  const handleText = () => {
    setIsWidgetVisible(true);
    showTextCallback(false);
  };

  const handleDeleteBox = (
    featureId: string,
    dimension: string,
    index: number
  ) => {
    console.log('featureId, dimension, index', featureId, dimension, index);

    setFeatures((prevFeatures: any) =>
      prevFeatures.map((feature: any) =>
        feature.featureId === featureId
          ? {
              ...feature,
              boxes: feature.boxes.filter(
                (box: any, boxIndex: number) => boxIndex !== index
              ),
            }
          : feature
      )
    );

    setFinalFeature((prevFeatures: any) => {
      // Ensure prevFeatures is an array, otherwise initialize as an empty array
      const updatedFeatures = Array.isArray(prevFeatures)
        ? [...prevFeatures]
        : [];

      // Define the featureId and chartType you want to remove
      const featureIdToRemove = featureId;
      const chartTypeToRemove = selectedChart;

      // Filter out the feature with the specified featureId and chartType
      const filteredFeatures = updatedFeatures.filter((feature) => {
        if (feature.featureId === featureIdToRemove) {
          // Remove the chart with the specified chartType
          feature.charts = feature.charts.filter(
            (chart: any) =>
              chart.chartType !== chartTypeToRemove ||
              chart.dimensionType !== dimension
          );

          // Return true to keep the feature if it still has charts after filtering
          return feature.charts.length > 0;
        } else {
          // Keep the feature if its featureId doesn't match the one to remove
          return true;
        }
      });

      return filteredFeatures;
    });
    setWidgetCharts((prevWidgetCharts: any) => {
      const updatedCharts = { ...prevWidgetCharts };
      const featureCharts = updatedCharts[featureId];
      if (featureCharts) {
        if (dimension in featureCharts) {
          delete featureCharts[dimension];

          if (Object.keys(featureCharts).length === 0) {
            delete updatedCharts[featureId];
          }
        }
      }
      return updatedCharts;
    });
    handleDeleteChart();
  };

  const handleSelectChange = (
    featureId: string,
    selectedOption: any,
    boxIndex: number
  ) => {
    setFeatures((prevFeatures: any) => {
      const updatedFeatures = prevFeatures.map((feature: any) => {
        if (feature.featureId === featureId) {
          console.log('featureid in handleSelectChange', featureId);
          const updatedBoxes = feature.boxes.map((box: any, index: number) => {
            if (index === boxIndex) {
              chartComponent = getChartComponent(
                selectedOption.value,
                boxIndex,
                box.sessionData
              );
              return {
                ...box,
                chartComponent,
                selectedChart: selectedOption.value,
              };
            }
            return box;
          });

          return {
            ...feature,
            boxes: updatedBoxes,
          };
        }
        return feature;
      });

      console.log('handleSelectChange', selectedOption, updatedFeatures);
      return updatedFeatures;
    });
    setFinalFeature((prevFeatures: any) => {
      const updatedFeatures = prevFeatures?.features?.map((feature: any) => {
        if (feature.featureId === featureId) {
          const updatedCharts = feature.charts.map(
            (chart: any, index: number) => {
              if (index === boxIndex) {
                return {
                  ...chart,
                  chartType: selectedOption.value,
                };
              }
              return chart;
            }
          );

          return {
            ...feature,
            charts: updatedCharts,
          };
        }
        return feature;
      });

      return updatedFeatures;
    });
  };

  const DropdownOption = (props: any) => (
    <components.Option {...props}>
      <div>
        <img
          src={props.data.image}
          alt={props.data.label}
          className={styles['optionImage']}
        />
      </div>
    </components.Option>
  );

  let chart_Style;
  if (selectedChart) {
    chart_Style = chartStyles(selectedChart);
  }

  const formatOptionLabel = (option: any) => (
    <div>
      <img
        src={option.image}
        alt={option.label}
        className={styles['optionImage']}
      />
    </div>
  );

  return (
    <div
      key={`${index}-${dimension}`}
      className={`${styles['box']}`}
      style={chart_Style}
    >
      {selectedChart === null && !showText && (
        <div className={styles['addchartfixed']}>
          <button className={styles['addchartbutton']} onClick={handleText}>
            <img
              style={{ paddingRight: '8px' }}
              src={addCircle}
              alt="add chart"
            ></img>
            <span> Add Chart</span>
          </button>
        </div>
      )}

      {selectedChart && sessionData && (
        <div className={styles['contentContainer']}>
          <div className={styles['iconsContainer']}>
            <div className={styles['dimensionaName']}>{title}</div>
            <div>
              {options.length > 0 && options && (
                <Select
                  options={options}
                  placeholder=""
                  isSearchable={false}
                  styles={customStyles}
                  components={{ Option: DropdownOption }}
                  formatOptionLabel={formatOptionLabel}
                  value={options.find(
                    (option: any) => selectedChart === option.value
                  )}
                  onChange={(selectedOption) =>
                    handleSelectChange(featureId, selectedOption, index)
                  }
                />
              )}
              {/* <div className={styles['dustbinContainer']}>
                <img
                  src={deleteicon}
                  alt="deleteicon"
                  width="18px"
                  height="18px"
                  onClick={() => {
                    handleDeleteBox(featureId, dimension, index);
                  }}
                />
              </div> */}
            </div>
          </div>
          {isLoading && (
            <div>
              <CircleSpinner size="48px" text={''}></CircleSpinner>
            </div>
          )}
          {!isLoading && (
            <div
              key={`${index}-${chartComponent}-${selectedChart}-${startDate}-${endDate}`}
              className={styles['graphContainer']}
              style={chartStyle}
            >
              {chartComponent}
            </div>
          )}
          {isFullScreen && (
            <FullScreenChartPage
              chartComponent={chartComponent}
              onClose={toggleFullScreen}
            />
          )}
          <div>
            <img
              className={styles['fullscreenicon']}
              src={fullscreenicon}
              alt="fullscreenicon"
              onClick={toggleFullScreen}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartBox;
