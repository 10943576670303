import bargraph from '../../components/assets/images/bar-graph.svg';
import linegraph from '../../components/assets/images/line-graph.svg';
import piegraph from '../../components/assets/images/circle-dot-outline.svg';
import barchartdemo2 from '../../components/assets/images/barchart2.jpg';
import linechartdemo2 from '../../components/assets/images/linechart2.jpg';
import piechartdemo from '../../components/assets/images/piechartdemo.png';

export const optionsData = [
  {
    BAR_CHART: {
      value: 'BAR_CHART',
      label: 'Bar Chart',
      image: bargraph,
    },
  },
  {
    STACKED_BAR_CHART: {
      value: 'STACKED_BAR_CHART',
      label: 'Bar Chart',
      image: bargraph,
    },
  },
  {
    LINE_CHART: {
      value: 'LINE_CHART',
      label: 'Line Chart',
      image: linegraph,
    },
  },
  {
    PIE_CHART: {
      value: 'PIE_CHART',
      label: 'Pie Chart',
      image: piegraph,
    },
  },
];

export const chartData = [
  {
    BAR_CHART: {
      src: barchartdemo2,
      alt: 'barchart',
    },
  },
  {
    STACKED_BAR_CHART: {
      src: barchartdemo2,
      alt: 'barchart',
    },
  },
  {
    LINE_CHART: {
      src: linechartdemo2,
      alt: 'linechart',
    },
  },
  {
    PIE_CHART: {
      src: piechartdemo,
      alt: 'piechart',
    },
  },
];
