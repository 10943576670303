import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuthProviderHook';
import { globalConfig } from '../../API/Utils/enivronment-util';
import { CircleSpinner } from '@workflow/shared/info-graphics';

/* eslint-disable-next-line */
export interface LogoutProps {}

export function Logout(props: LogoutProps) {
  const { onLogout } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (onLogout) {
      onLogout().then(
        (result) => {
          identityLogout(result);
        },
        (error) => {
          setError(error);
          navigate("/signin");
        }
      );
    }
  }, []);

  if (error) {
    return <Navigate to="/signin" />;
  } else if (!isLoaded) {
    return (
      <div className="modus-layout">
        <CircleSpinner size="48px" text={'Logging Out.....'} />
      </div>
    );
  } else {
    return <Navigate to="/signin" />;
  }
}

export default Logout;

function identityLogout(id_token: string) {
  const url =
    globalConfig.config?.identityURL + '/oauth/logout?post_logout_redirect_uri=' + globalConfig.config?.appRedirectURL +'&id_token_hint=' +
    id_token;
  window.location.href = url;
}
