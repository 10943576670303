import i18next from 'i18next';
import { axiosInstance } from '../hooks/axiosInstanceHelper';
import { FeatureType } from '../models/FeatureType';
import { Product } from '../models/Product';

export async function fetchFeatureProducts(
  productSku: string | undefined,
  accountId?: string,
  type?: FeatureType
): Promise<Product> {
  const result = await axiosInstance().request({
    method: 'GET',
    url: `/products/v1`,
    params: {
      accountId,
      productSku: productSku ? productSku : 'PSD-CONSTRUCTION-CLOUD',
      featureType:type,
      locale: i18next.language
    },
  });
  return result && result.data ? result.data : undefined;
}
