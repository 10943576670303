import styles from './widget.module.scss';
import React, { ReactNode, useEffect, useState } from 'react';
import close from './assets/images/close.svg';
import { chartData } from '../../utils/transformUtils/chartOptions';
import { ModusSelect, ModusButton } from '@trimble-oss/modus-react-components';
import { WidgetCharts, Dimensions } from '../../types/DataTypes';
import { SessionData } from '../../types/VisualizationTypes';
import { getChartComponent } from '../../utils/chartUtils';
import { processAndFilterData } from '../../utils/dataProcessing';

export const Widget = ({
  featuresData,
  handleAddFeature,
  setIsWidgetVisible,
  setWidgetCharts,
  widgetCharts,
  startDate,
  endDate,
  widgetFeatures,
  widgetChartData,
  setCount,
  setData,
  data,
  setFinalFeature,
  handlerCallback,
  checkboxStatus,
  setCheckboxStatus,
  selectedChart,
  setselectedChart,
}: {
  featuresData: any;
  setFeaturesData: any;
  handleAddFeature: (
    featureId: string,
    selectedChart: string,
    selectedDimension: string,
    sessionData: any,
    index: number,
    chartComponent: React.ReactNode,
    isLastIndexValue: boolean
  ) => void;
  setIsWidgetVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setWidgetCharts: any;
  widgetCharts: WidgetCharts;
  startDate: any;
  endDate: any;
  widgetFeatures: any;
  widgetChartData?: any;
  setCount: any;
  handleIsLoading?: any;
  setData: any;
  data: any;
  setFinalFeature: any;
  handlerCallback: {
    getData: any;
    processAndFilterData?: any;
  };
  //dhanush added
  checkboxStatus: any;
  setCheckboxStatus: any;
  selectedChart: any;
  setselectedChart: any;
}) => {
  const [selectedFeatureData, setSelectedFeatureData] =
    useState<Dimensions | null>(null);
  const [totalValue, setTotalValue] = useState<{
    [feature: string]: {
      [dimension: string]: {
        sessionData?: SessionData;
        chartComponent?: ReactNode;
      }[];
    };
  }>({});
  const [selectedDimension, setselectedDimension] = useState('');
  const [supportedDimension, setSupportedDimension] = useState<any>();
  const [selectedFeature, setSelectedFeature] = useState('');
  const [sessionData, setSessionData] = useState<SessionData | null>(null);
  const [chartAddedMessage, setChartAddedMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const handleSaveClick = () => {
    if (Object.keys(totalValue).length === 0) {
      setChartAddedMessage(`select your chart!`);
      setMessageType('failure');
      setTimeout(() => {
        setChartAddedMessage('');
      }, 1000);
      return;
    }

    const newFeatures = Object.keys(totalValue).map((featureId) => {
      const charts: { chartType: string; dimensionType: string }[] = [];

      const dimensions = Object.keys(totalValue[featureId]);

      dimensions.forEach((dimension) => {
        const chartEntries = totalValue[featureId][dimension];
        if (
          chartEntries &&
          typeof chartEntries === 'object' &&
          checkboxStatus[featureId]?.[dimension]
        ) {
          Object.entries(chartEntries).forEach(([chartType]: [string, any]) => {
            charts.push({
              chartType,
              dimensionType: dimension,
            });
          });
        }
      });

      return {
        featureId,
        charts,
      };
    });

    setFinalFeature((prevFeatures: any) => {
      const updatedFeatures = Array.isArray(prevFeatures)
        ? [...prevFeatures]
        : [];

      newFeatures.forEach((newFeature) => {
        const existingFeatureIndex = updatedFeatures.findIndex(
          (feature) => feature.featureId === newFeature.featureId
        );
        if (existingFeatureIndex !== -1) {
          updatedFeatures[existingFeatureIndex].charts = updatedFeatures[
            existingFeatureIndex
          ].charts.concat(newFeature.charts);
        } else {
          updatedFeatures.push(newFeature);
        }
      });

      return updatedFeatures;
    });

    Object.keys(totalValue).forEach((feature) => {
      let indexval = 0;

      featuresData.forEach((featureData: any) => {
        if (featureData?.featureId === feature) {
          console.log(
            'IMPORTANTT',
            featureData?.boxes?.length,
            featureData.boxes
          );
          indexval = featureData?.boxes?.length - 1;
          console.log('INDEXVAL-feature', indexval, feature);
        }
      });

      const dimensions = Object.keys(totalValue[feature]);

      dimensions.forEach((dimension, dimensionIndex) => {
        if (dimension !== '') {
          const chartEntries = totalValue[feature][dimension];
          if (
            chartEntries &&
            typeof chartEntries === 'object' &&
            checkboxStatus[feature]?.[dimension]
          ) {
            Object.entries(chartEntries).forEach(
              ([chartType, { chartData, chartComponent }]: [string, any]) => {
                const matchingFeature = featuresData.find(
                  (featureData: any) => featureData.featureId === feature
                );
                let lastIndex;
                if (matchingFeature) {
                  lastIndex = matchingFeature.boxes.length - 1;
                } else {
                  lastIndex = 0;
                }

                const isLastIndexValue =
                  dimensionIndex === dimensions.length - 1 &&
                  chartType ===
                    Object.keys(chartEntries)[
                      Object.keys(chartEntries).length - 1
                    ];
                console.log('isLastIndexValue', isLastIndexValue);
                console.log(
                  'ALL VALUES',
                  feature,
                  chartType,
                  dimension,
                  chartData,
                  indexval,
                  chartComponent,
                  isLastIndexValue
                );
                handleAddFeature(
                  feature,
                  chartType,
                  dimension,
                  chartData,
                  indexval,
                  chartComponent,
                  isLastIndexValue
                );
                setCount((prevCount: number) => prevCount + 1);
                indexval += 1;
              }
            );
          }
        }
      });
    });

    setIsWidgetVisible((prev) => !prev);
    setselectedChart('');
    setselectedDimension('');
    setCheckboxStatus({});
    setTotalValue({});
  };

  const handleFeatureChange = (e: any) => {
    const select = document.querySelector(
      '#feature-select'
    ) as HTMLModusSelectElement;
    if (select) {
      select.value = e.detail;
    }
    const newSelectedFeature = e.detail.value;
    setSelectedFeature(newSelectedFeature);
  };

  const addValues = (chartType: string, dimension: string, feature: string) => {
    setselectedChart(chartType);
    setselectedDimension(dimension);

    setCheckboxStatus((prevStatus: any) => ({
      ...prevStatus,
      [feature]: {
        ...prevStatus[feature],
        [dimension]: !prevStatus[feature]?.[dimension],
      },
    }));

    setWidgetCharts((prevWidgetCharts: any) => {
      const featureData = prevWidgetCharts[feature] || {};
      const dimensionData = featureData[dimension] || [];

      const isChartTypeExist = dimensionData.includes(chartType);

      if (!isChartTypeExist) {
        return {
          ...prevWidgetCharts,
          [feature]: {
            ...featureData,
            [dimension]: [...dimensionData, chartType],
          },
        };
      }
    });

    setChartAddedMessage('Data updated successfully!');
    setMessageType('success');
    setTimeout(() => {
      setChartAddedMessage('');
    }, 1000);
  };

  const closeWidget = () => {
    setIsWidgetVisible(false);
    setTotalValue({});
    setselectedChart('');
    setselectedDimension('');
    Object.keys(totalValue).forEach((feature) => {
      const dimensions = Object.keys(totalValue[feature]);

      dimensions.forEach((dimension) => {
        if (dimension !== '') {
          setCheckboxStatus((prevStatus: any) => {
            const updatedStatus = { ...prevStatus };
            delete updatedStatus[feature][dimension];
            return updatedStatus;
          });

          setWidgetCharts((prevWidgetCharts: any) => {
            const updatedCharts = { ...prevWidgetCharts };

            if (updatedCharts[feature] && updatedCharts[feature][dimension]) {
              delete updatedCharts[feature][dimension];
            }

            return updatedCharts;
          });
        }
      });
    });
  };
  const cancelWidget = () => {
    setTotalValue({});
    setselectedChart('');
    setselectedDimension('');
    Object.keys(totalValue).forEach((feature) => {
      const dimensions = Object.keys(totalValue[feature]);

      dimensions.forEach((dimension) => {
        if (dimension !== '') {
          setCheckboxStatus((prevStatus: any) => {
            const updatedStatus = { ...prevStatus };
            delete updatedStatus[feature][dimension];
            return updatedStatus;
          });

          setWidgetCharts((prevWidgetCharts: any) => {
            const updatedCharts = { ...prevWidgetCharts };

            if (updatedCharts[feature] && updatedCharts[feature][dimension]) {
              delete updatedCharts[feature][dimension];
            }

            return updatedCharts;
          });
        }
      });
    });

    setChartAddedMessage(`Removed Chart successfully!`);
    setMessageType('failure');
    setTimeout(() => {
      setChartAddedMessage('');
    }, 1000);
  };

  useEffect(() => {
    console.log('yes widget', selectedFeature, JSON.stringify(widgetChartData));
    const featureDataValue =
      selectedFeature && widgetChartData && widgetChartData[selectedFeature]
        ? widgetChartData[selectedFeature]
        : widgetFeatures.find(
            (feature: any) => feature.value == selectedFeature
          );
    setSelectedFeatureData(featureDataValue);

    console.log(
      'selected feature data for widgets ',
      selectedFeature,
      featureDataValue
    );
  }, [selectedFeature, widgetChartData]);

  useEffect(() => {
    const dimensions =
      selectedFeatureData && selectedFeatureData['supportedDimensions']
        ? selectedFeatureData['supportedDimensions']
        : [];
    setSupportedDimension(dimensions);
  }, [selectedFeatureData]);

  useEffect(() => {
    console.log('useffect for api call', selectedFeature, selectedDimension);
    if (selectedDimension && selectedFeature) {
      setData([]);
      // handleIsLoading(true)
      handlerCallback.getData(selectedFeature, selectedDimension).then(
        (result: any) => {
          console.log('I am inside widget,analyticsData', result);
          setData(result as any);
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }, [selectedDimension, selectedFeature]);

  useEffect(() => {
    let updatedSessionData: SessionData | null = null;
    if (data && selectedDimension && selectedFeature) {
      if (handlerCallback && handlerCallback.processAndFilterData) {
        const processedData = handlerCallback.processAndFilterData(
          data,
          selectedFeature,
          selectedDimension,
          startDate,
          endDate
        );
        console.log(processedData);
        updatedSessionData = processedData;
      } else if(data?.data?.length){
        const { labels, datavals } = processAndFilterData(
          data.data,
          selectedDimension,
          startDate,
          endDate
        );

        updatedSessionData = {
          labels: labels,
          datasets: [
            {
              label: selectedDimension,
              data: datavals,
              backgroundColor: [
                'rgba(75,192,192,1)',
                '#ecf0f1',
                '#50AF95',
                '#f3ba2f',
                '#2a71d0',
              ],
            },
          ],
        };
      }

      console.log('updatedSessionData in widget', updatedSessionData);
      setSessionData(updatedSessionData);
      if(updatedSessionData)
      setTotalValue((prevTotalValue: any) => {
        const updatedValue = {
          ...prevTotalValue,
          [selectedFeature]: {
            ...(prevTotalValue[selectedFeature] || {}),
            [selectedDimension]: {
              ...(prevTotalValue[selectedFeature]?.[selectedDimension] || {}),
              [selectedChart]: {
                chartData: updatedSessionData,
              },
            },
          },
        };
        return updatedValue;
      });
    }
  }, [selectedFeature, selectedDimension, startDate, endDate, data]);

  useEffect(() => {
    let chartComponentvalue: any;

    if (sessionData) {
      chartComponentvalue = getChartComponent(
        selectedChart,
        `${selectedFeature}-${selectedDimension}-${selectedChart}`,
        sessionData
      );

      setTotalValue((prevTotalValue) => ({
        ...prevTotalValue,
        [selectedFeature]: {
          ...prevTotalValue[selectedFeature],
          [selectedDimension]: {
            ...prevTotalValue[selectedFeature]?.[selectedDimension],
            [selectedChart]: {
              chartData: sessionData,
              chartComponent: chartComponentvalue,
            },
          },
        },
      }));
    } else {
      chartComponentvalue = <h4>No Data</h4>;
    }
  }, [
    sessionData,
    selectedChart,
    startDate,
    endDate,
    selectedFeature,
    selectedDimension,
  ]);

  return (
    <div className={styles['select-images-sidebar']}>
      <div className={styles['widgets']}>
        <h4 className={styles['widget-heading']}>Select Widget</h4>
        <img
          className={styles['close-icon']}
          src={close}
          alt="close-icon"
          onClick={closeWidget}
        />
      </div>
      <span
        className={
          messageType === 'success'
            ? styles['success-message']
            : styles['failure-message']
        }
      >
        {chartAddedMessage}
      </span>
      <div className={styles['feature-selection']}>
        <form>
          <ModusSelect
            id="feature-select"
            label="Select Option:"
            options={widgetFeatures}
            options-display-prop="label"
            onValueChange={handleFeatureChange}
          />
        </form>
      </div>
      <div className={styles['dimension-container']}>
        {supportedDimension?.map((obj: any, objIndex: number) => {
          let count = 0;
          return (
            <div key={objIndex} className={styles['dimension-wrapper']}>
              <div className={styles['image-container']}>
                {obj?.chartTypes?.map(
                  (chartType: string, chartIndex: number) => {
                    const chartTypeExistsInWidgetCharts =
                      widgetCharts?.[selectedFeature]?.[obj.type];

                    if (count === 0) {
                      count += 1;
                      const isChecked =
                        checkboxStatus[selectedFeature]?.[obj.type] || false;
                      const isDisabled =
                        chartTypeExistsInWidgetCharts &&
                        chartTypeExistsInWidgetCharts.includes(chartType);

                      const chart = chartData.find(
                        (chart: any) => chart[chartType]
                      ) as
                        | { [key: string]: { src: string; alt: string } }
                        | undefined;

                      if (chart && chart[chartType]) {
                        return (
                          <div key={chartIndex} className={styles['image-box']}>
                            <h3 className={styles['dimension-name']}>
                              {obj.name}
                            </h3>
                            <img
                              src={chart[chartType].src}
                              alt={chart[chartType].alt}
                              className={
                                chart[chartType].alt === 'piechart'
                                  ? styles['select-images-display-large']
                                  : styles['select-images-display']
                              }
                            />
                            <input
                              type="checkbox"
                              className={styles['add-button']}
                              onChange={() =>
                                addValues(chartType, obj.type, selectedFeature)
                              }
                              checked={isChecked}
                              disabled={isDisabled}
                            />
                          </div>
                        );
                      }
                    }
                    return null;
                  }
                )}
              </div>
            </div>
          );
        })}
      </div>
      {supportedDimension && (
        <div className={styles['modification-button']}>
          <ModusButton onClick={cancelWidget} color="danger">
            Cancel
          </ModusButton>
          <ModusButton onClick={() => handleSaveClick()} color="primary">
            Save
          </ModusButton>
        </div>
      )}
    </div>
  );
};
