import { useEffect, useState } from 'react';
import { useEntitlements } from '../../components/app-view';
import { useAuth } from '../../hooks/useAuthProviderHook';
import { getFormData, getFormTemplate } from '../../API/featureCatalogueList';
import { SubscriptionStatusTypes } from '../../models/FeatureDeployment';
import { ProvisioningAPIs } from '../../API/provisioingservice';
import ListFeatures from '../../components/list-features/list-features';
import { ModalMessage } from '@workflow/ui';
import { Feature, Parameter } from '../../models/Product';
import { globalConfig } from '../../API/Utils/enivronment-util';
import { useNavigate } from 'react-router-dom';
import { sendCustomEvent } from '../../assets/js_files/gs';
import { t } from 'i18next';
import i18next from 'i18next';
import { ModalCreate } from '../../components/modal-create/modal-create';
import { ActionType } from '../../../../../libs/ui/src/action-button/action-props';
import {
  AlertType,
  useNotificationContext,
} from '../../components/notification-provider/NotificationProvider';
import { RouteType } from '../../models/RouteType';
import { FeatureType } from '../../models/FeatureType';
import { fetchFeatureProducts } from '../../API/ProductAPI';
import { FeatureListItem } from '../../models/FeatureListItem';
import { WorkflowEntitlement } from '../../models/EntitlementDBResponse';
import { isNullOrUndefined } from '../../../../../libs/ui/src/utils/Common';
import { ProvisioningAction } from '../../models/ProvisioningAction';
import { Constants } from '../../API/AppConstants';
import FeatureDetails from '../feature-details/feature-details';
import { useAuthorizationHandler } from '../../hooks/use-authorization-handler/use-authorization-handler';

export interface CatalogueStoreProps {
  type: 'store_view' | 'add_parameters' | 'details';
  title: string;
  ownedFeatures?: boolean;
}
/* eslint-disable-next-line */
export interface CatalogueStoreProps {}

export function CatalogueStore(props: CatalogueStoreProps) {
  const { userInfo } = useAuth();
  let userInfoObj: any;
  if (userInfo) {
    userInfoObj = {
      userId: userInfo?.sub,
      email: userInfo?.email,
    };
  }
  const cloudProductSKU = globalConfig.config?.productName;
  const { entitlements, accountId } = useEntitlements();
  const {authorizationInstance} = useAuthorizationHandler();
  const [error, setError] = useState<AlertType | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [catalogueList, setCatalogueList] = useState<FeatureListItem[]>([]);
  const [showConfirmDialog, setShowDialog] = useState(false);
  const [unSubscribeId, setId] = useState('');
  const [modalMessage, setModalMessage] = useState<ModalMessage>();
  const modalUnsubscribeTitle = t('Workflows.Remove.Modal.Title');
  const modalConfirmTitle = t('Workflows.Remove.Modal.Confirm');
  const [showCreateDialog, setCreateShowDialog] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<{
    actionId: ActionType;
    featureData?: any;
    formData?: any;
  }>();

  const getModalUnsubscribeMessage = (productName: string) => {
    return t('Workflows.Remove.Modal.Message', { productName });
  };
  const navigate = useNavigate();

  const { setNotification, setAlertMesage, alertCloseHandler, alert } =
    useNotificationContext();

  const setToast = ({ id, message, status, title, source }: any) => {
    if (setNotification) {
      setNotification({
        message: message,
        status: status,
        title: title,
        id: id,
        source: source,
      });
    }
  };

  const setMessage = (message: AlertType) => {
    if (setAlertMesage) {
      setAlertMesage(message);
    }
  };

  const { authInfo } = useAuth();

  const updateButtonState = (
    actionId: ActionType,
    id: string,
    productName: string,
    status: SubscriptionStatusTypes,
    updateNotification = true,
    isOnload?: boolean
  ) => {
    const eventData = {
      id: id,
      type: 'success',
      status: status,
      name: productName,
    };
    let refresh = false;
    const statusInProgress = t('Common.InProgress');
    const statusCompleted = t('Common.Completed');
    const statusFailed = t('Common.Failed');
    const Removal = t('Workflows.Removal');
    const Provisioning = t('Workflows.Provisioning');
    sendCustomEvent('workflow', eventData);
    switch (status) {
      case SubscriptionStatusTypes.SUBSCRIBE_INPROGRESS:
      case SubscriptionStatusTypes.SUBSCRIBE_PENDING:
        updateState(
          actionId,
          id,
          t('Common.InProgress'),
          true,
          true,
          status,
          null,
          isOnload
        );
        if (updateNotification) {
          setToast({
            status: 'In Progress',
            message: i18next.t('Workflows.InProgress.Message', {
              task: Provisioning,
            }),
            title: i18next.t('Workflows.Add.Toast.Title', {
              productName,
              status: statusInProgress,
            }),
            id: getMessageId(actionId, id),
            source: RouteType.Workflows,
          });
        }
        break;
      case SubscriptionStatusTypes.SUBSCRIBE_SUCCESS:
        updateState(
          actionId,
          id,
          '- ' + t('Common.Remove'),
          false,
          false,
          status,
          handleUnsubscribe,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'success',
            status: 'Completed',
            message: t('Workflows.AddSuccess.AlertMessage', { productName }),
            source: RouteType.Workflows,
          });
          setToast({
            status: 'Completed',
            message: i18next.t('Workflows.Success.Toast.Message', {
              task: Provisioning,
            }),
            title: i18next.t('Workflows.Add.Toast.Title', {
              productName,
              status: statusCompleted,
            }),
            id: getMessageId(actionId, id),
            source: RouteType.Workflows,
          });
        }
        refresh = true;
        break;
      case SubscriptionStatusTypes.SUBSCRIBE_FAILED:
        updateState(
          actionId,
          id,
          t('Common.Failed'),
          true,
          false,
          status,
          null,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'error',
            status: 'Failed',
            message: t('Workflows.AddFailure.AlertMessage', { productName }),
            source: RouteType.Workflows,
          });
          setToast({
            status: 'Failed',
            message: i18next.t('Workflows.Failure.Message', {
              task: Provisioning,
            }),
            title: i18next.t('Workflows.Add.Toast.Title', {
              productName,
              status: statusFailed,
            }),
            id: getMessageId(actionId, id),
            source: RouteType.Workflows,
          });
        }
        break;
      case SubscriptionStatusTypes.UNSUBSCRIBE_SUCCESS:
        updateState(
          actionId,
          id,
          '+ ' + t('Common.Add'),
          false,
          false,
          status,
          getSubscribed,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'success',
            status: 'Completed',
            message: t('Workflows.RemoveSuccess.AlertMessage', { productName }),
            source: RouteType.Workflows,
          });
          setToast({
            status: 'Completed',
            message: i18next.t('Workflows.Success.Toast.Message', {
              task: Removal,
            }),
            title: i18next.t('Workflows.Removal.Toast.Title', {
              productName,
              status: statusCompleted,
            }),
            id: getMessageId(actionId, id),
            source: RouteType.Workflows,
          });
        }
        refresh = true;
        break;
      case SubscriptionStatusTypes.UNSUBSCRIBE_INPROGRESS:
      case SubscriptionStatusTypes.UNSUBSCRIBE_PENDING:
        updateState(
          actionId,
          id,
          t('Common.InProgress'),
          true,
          true,
          status,
          null,
          isOnload
        );
        if (updateNotification) {
          setToast({
            status: 'In Progress',
            message: i18next.t('Workflows.InProgress.Message', {
              task: Removal,
            }),
            title: i18next.t('Workflows.Removal.Toast.Title', {
              productName,
              status: statusInProgress,
            }),
            id: getMessageId(actionId, id),
            source: RouteType.Workflows,
          });
        }
        break;
      case SubscriptionStatusTypes.UNSUBSCRIBE_FAILED:
        updateState(
          actionId,
          id,
          t('Common.Failed'),
          true,
          false,
          status,
          null,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'error',
            status: 'Failed',
            message: t('Workflows.RemoveFailure.AlertMessage', { productName }),
            source: RouteType.Workflows,
          });
          setToast({
            status: 'Failed',
            message: i18next.t('Workflows.Failure.Message', { task: Removal }),
            title: i18next.t('Workflows.Removal.Toast.Title', {
              productName,
              status: statusFailed,
            }),
            id: getMessageId(actionId, id),
            source: RouteType.Workflows,
          });
        }
        break;
      case SubscriptionStatusTypes.SUBSCRIBE_CONFLICT:
        updateState(
          actionId,
          id,
          t('Common.Failed'),
          true,
          false,
          status,
          null,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'error',
            status: 'Conflict',
            message: t('Workflows.AddConflict.AlertMessage', { productName }),
            source: RouteType.Workflows,
          });
          setToast({
            status: 'Failed',
            message: i18next.t('Workflows.AlreadyInProgress.Message', {
              task: Provisioning,
            }),
            title: i18next.t('Workflows.Add.Toast.Title', {
              productName,
              status: statusFailed,
            }),
            id: getMessageId(actionId, id),
            source: RouteType.Workflows,
          });
        }
        break;
      case SubscriptionStatusTypes.UNSUBSCRIBE_CONFLICT:
        updateState(
          actionId,
          id,
          t('Common.Failed'),
          true,
          false,
          status,
          null,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'error',
            status: 'Conflict',
            message: t('Workflows.RemoveConflict.AlertMessage', {
              productName,
            }),
            source: RouteType.Workflows,
          });
          setToast({
            status: 'Failed',
            message: i18next.t('Workflows.AlreadyInProgress.Message', {
              task: Removal,
            }),
            title: i18next.t('Workflows.Removal.Toast.Title', {
              productName,
              status: statusFailed,
            }),
            id: getMessageId(actionId, id),
            source: RouteType.Workflows,
          });
        }
        break;
    }
    if (refresh) {
      navigate('/marketplace/capabilities/temp');
      navigate(-1);
    }
  };

  const getMessageId = (actionId: ActionType, id: string) => {
    return [actionId, id].filter((val) => !!val).join('-');
  };
  const updateState = (
    actionId: ActionType,
    id: string,
    titleP: string,
    isDisabledP: boolean,
    isInProgressP: boolean,
    statusP: SubscriptionStatusTypes,
    onClickMethod: any,
    isOnload?: boolean
  ) => {
    setCatalogueList((prevState) => {
      const currentState = [...prevState];
      const productToUpdate = currentState.filter((val) => {
        if (val.id === id) {
          return true;
        }
        return false;
      });
      if (
        productToUpdate.length > 0 &&
        productToUpdate[0] !== undefined &&
        productToUpdate[0].detailActions !== undefined
      ) {
        const actionsUpdated = productToUpdate[0].detailActions.map((val) => {
          if (val.actionId === actionId && val.type === 'button') {
            return {
              ...val,
              title: titleP,
              isDisabled: isDisabledP,
              isInProgress: isInProgressP,
              status: setButtonStatus(statusP),
              clickHandlerLabel: onClickMethod,
              isOnload: isOnload,
            };
          } else {
            return {
              ...val,
              isDisabled: isDisabledP,
              show: !isDisabledP,
            };
          }
        });
        const isAssigned =
          statusP === SubscriptionStatusTypes.SUBSCRIBE_SUCCESS;
        return currentState.map((val) => {
          if (val.id === id) {
            return {
              ...val,
              borderTop: isAssigned ? '5px solid var(--primary)' : undefined,
              statusIcon: setStatusIcon(statusP, isAssigned),
              detailActions: actionsUpdated,
            };
          }
          return val;
        });
      }
      return currentState;
    });
  };

  const parseParameters = (parameter: any) => {
    if (parameter) {
      const Keys = Object.keys(parameter);
      const finalParameterArray: { name: string; value: any }[] = [];
      Keys.forEach((eachKey) => {
        const obj = {
          name: eachKey,
          value: parameter[eachKey],
        };
        finalParameterArray.push(obj);
      });
      return finalParameterArray;
    } else {
      return [];
    }
  };

  const getSubscribed = async (
    actionId: ActionType,
    featureId: string,
    productName: string,
    params?: any
  ) => {
    updateButtonState(
      actionId,
      featureId,
      productName,
      SubscriptionStatusTypes.SUBSCRIBE_INPROGRESS
    );
    const catalogueItem = catalogueList.find((item) => item.id === featureId);
    const data = {
      accountId,
      userId: userInfoObj.userId,
      skuCode: cloudProductSKU,
      email: userInfoObj.email,
      action: ProvisioningAction.SUBSCRIBE,
      featureId,
      parameters: parseParameters(params),
      featureType: FeatureType.WORKFLOW,
    };
    if (
      actionId === ActionType.UPDATE &&
      catalogueItem?.deploymentInfo?.deploymentId
    ) {
      ProvisioningAPIs.updateProvision(
        data,
        productName,
        catalogueItem?.deploymentInfo?.deploymentId,
        handlers,
        actionId
      );
    } else {
      ProvisioningAPIs.startProvision(
        data,
        productName,
        handlers,
        actionId,
        undefined,
        true
      );
    }
  };

  const handleUnsubscribe = (
    actionId: ActionType,
    cardId: string,
    productName: string
  ) => {
    setId(cardId);
    setModalMessage({
      title: modalUnsubscribeTitle,
      message: getModalUnsubscribeMessage(productName),
      confirmBtnTitle: modalConfirmTitle,
    });
    setShowDialog(true);
  };

  const handlers = {
    getSubscribed,
    authInfo,
    updateButtonState,
    userInfoObj,
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const isInProgress = (status: SubscriptionStatusTypes) => {
    return (
      status === SubscriptionStatusTypes.UNSUBSCRIBE_INPROGRESS ||
      status === SubscriptionStatusTypes.UNSUBSCRIBE_PENDING ||
      status === SubscriptionStatusTypes.SUBSCRIBE_INPROGRESS ||
      status === SubscriptionStatusTypes.SUBSCRIBE_PENDING ||
      status === SubscriptionStatusTypes.REINSTATE_INPROGRESS ||
      status === SubscriptionStatusTypes.REINSTATE_PENDING
    );
  };

  const isFailed = (status: SubscriptionStatusTypes) => {
    return (
      status === SubscriptionStatusTypes.SUBSCRIBE_FAILED ||
      status === SubscriptionStatusTypes.UNSUBSCRIBE_FAILED ||
      status === SubscriptionStatusTypes.REINSTATE_FAILED ||
      status === SubscriptionStatusTypes.SUSPEND_FAILED
    );
  };

  const isSuccess = (status: SubscriptionStatusTypes) => {
    return (
      status === SubscriptionStatusTypes.SUBSCRIBE_SUCCESS ||
      status === SubscriptionStatusTypes.REINSTATE_SUCCESS ||
      status === SubscriptionStatusTypes.UNSUBSCRIBE_SUCCESS
    );
  };

  const setStatusIcon = (
    featureDeployStatus: SubscriptionStatusTypes | undefined,
    isAssigned?: boolean
  ) => {
    return featureDeployStatus
      ? isAssigned &&
        (featureDeployStatus === SubscriptionStatusTypes.SUBSCRIBE_SUCCESS ||
          featureDeployStatus === SubscriptionStatusTypes.REINSTATE_SUCCESS)
        ? 'check_circle'
        : isFailed(featureDeployStatus)
        ? 'error'
        : isInProgress(featureDeployStatus)
        ? 'pending'
        : undefined
      : undefined;
  };
  const setButtonStatus = (status: SubscriptionStatusTypes) => {
    return isSuccess(status)
      ? 'default'
      : isFailed(status)
      ? 'error'
      : isInProgress(status)
      ? 'loading'
      : 'default';
  };

  const getActionButtonStatus = (
    status: SubscriptionStatusTypes | undefined
  ) => {
    switch (status) {
      case SubscriptionStatusTypes.SUBSCRIBE_INPROGRESS:
      case SubscriptionStatusTypes.SUBSCRIBE_PENDING:
      case SubscriptionStatusTypes.UNSUBSCRIBE_INPROGRESS:
      case SubscriptionStatusTypes.UNSUBSCRIBE_PENDING:
      case SubscriptionStatusTypes.REINSTATE_INPROGRESS:
      case SubscriptionStatusTypes.REINSTATE_PENDING:
        return {
          title: 'In Progress',
          isDisabled: true,
          status: 'loading',
        };
      case SubscriptionStatusTypes.SUBSCRIBE_FAILED:
      case SubscriptionStatusTypes.UNSUBSCRIBE_FAILED:
      case SubscriptionStatusTypes.REINSTATE_FAILED:
        return {
          title: 'Failed',
          isDisabled: true,
          status: 'error',
        };
      default:
        return undefined;
    }
  };

  const getActionButtons = (
    isAssigned?: boolean,
    id?: string,
    buttonStatus?: {
      title: string;
      isDisabled: boolean;
      status: string;
    },
    isDisabled?: boolean,
    hasEntitlement?: boolean,
    hasParameters?: boolean
  ) => {
    const actionBtnArr = [];
    const ability =id ? authorizationInstance?.for(id) : null;
    const hasAddRemovePermission =ability ? ability.can(isAssigned ? 'delete':'create'):false;
    if (hasEntitlement && hasAddRemovePermission) {
      actionBtnArr.push({
        type: 'button',
        isDisabled: buttonStatus ? buttonStatus.isDisabled : isDisabled,
        title: buttonStatus
          ? buttonStatus.title
          : isAssigned
          ? t('Common.Remove')+' Capability'
          : t('Common.Add')+' Capability',
        clickHandlerLabel: buttonStatus
          ? undefined
          : isAssigned
          ? 'handleUnsubscribe'
          : hasParameters && !isAssigned
          ? 'showModalCreate'
          : 'getSubscribed',
        status: buttonStatus ? buttonStatus.status : 'default',
        isOnload: true,
        actionId: isAssigned ? ActionType.REMOVE : ActionType.ADD,
        show: true,
      });
      if (isAssigned && hasParameters && ability?.can('update')) {
        actionBtnArr.push({
          type: 'button',
          isDisabled: buttonStatus ? buttonStatus.isDisabled : isDisabled,
          title: buttonStatus ? buttonStatus.title : t('Common.Update')+' Capability',
          clickHandlerLabel: buttonStatus ? undefined : 'showModalCreate',
          status: buttonStatus ? buttonStatus.status : 'default',
          isOnload: true,
          actionId: ActionType.UPDATE,
          show: true,
        });
      }
    }
    return actionBtnArr;
  };

  const showModalCreate = (actionId: ActionType, cardId: string) => {
    const featureData = catalogueList.find((val: any) => val.id === cardId);
    const formData = getFormData(
      featureData?.deploymentInfo?.details,
      featureData?.formTemplate as any
    );
    if (featureData) {
      setSelectedFeature({ actionId, formData, featureData: featureData });
      setCreateShowDialog(true);
    }
  };

  const handleCreateDialogClose = () => {
    setCreateShowDialog(false);
  };

  const handleCreateDialogConfirm = (
    actionId: ActionType,
    featureId: string,
    productName: string,
    data?: any
  ) => {
    setCreateShowDialog(false);
    getSubscribed(actionId, featureId, productName, data);
  };
  const handleConfirm = () => {
    const catalogueItem = catalogueList.find(
      (item) => item.id === unSubscribeId
    );
    const productName = catalogueItem ? catalogueItem.title : '';
    const data = {
      accountId,
      userId: userInfoObj.userId,
      featureId: unSubscribeId,
      skuCode: cloudProductSKU,
      email: userInfoObj.email,
      action: ProvisioningAction.UNSUBSCRIBE,
      data: {
        entitlementId: catalogueItem?.entitlement?.entitlementId,
      },
      featureType: FeatureType.WORKFLOW,
    };
    setShowDialog(false);
    updateButtonState(
      ActionType.REMOVE,
      unSubscribeId,
      productName,
      SubscriptionStatusTypes.UNSUBSCRIBE_INPROGRESS
    );
    ProvisioningAPIs.startProvision(
      data,
      productName,
      handlers,
      ActionType.REMOVE,
      undefined,
      true
    );
  };
  const clickHandlers = {
    getSubscribed: getSubscribed,
    handleUnsubscribe: handleUnsubscribe,
    showModalCreate: showModalCreate,
  };
  const getSubscribedFeatures = (
    featureData: Feature,
    entitlement: WorkflowEntitlement | undefined,
    hasAccount: boolean
  ) => {
    const featureDeployStatus = featureData
      ? featureData.deploymentInfo?.status
      : undefined;
    const hasEntitlement = !isNullOrUndefined(entitlement) ? true : false;
    const isAssigned =
      hasAccount &&
      entitlement &&
      featureData &&
      (featureDeployStatus === SubscriptionStatusTypes.SUBSCRIBE_SUCCESS ||
        featureDeployStatus === SubscriptionStatusTypes.REINSTATE_SUCCESS);
    const paramArray = featureData?.catalogue?.deploymentConfig
      ?.filter((config) => config.parameters.length)
      ?.map((val) => val.parameters);
    const parameters: Parameter[] | undefined = paramArray?.reduce(
      (elem1, elem2) => elem1.concat(elem2)
    );
    const formTemplate = getFormTemplate(
      parameters,
      isAssigned ? ActionType.REMOVE : ActionType.ADD
    );
    const buttonStatus = getActionButtonStatus(featureDeployStatus);

    return {
      entitlement: entitlement,
      accountId,
      active: featureData.active,
      service: featureData.service,
      deploymentInfo: {
        ...featureData.deploymentInfo,
        deploymentId: featureData.id,
      },
      title: featureData.name,
      info: featureData.summary,
      summary: featureData.summary,
      id: featureData.featureId,
      crtdDt: featureData.crtdDt,
      detailActions: getActionButtons(
        isAssigned,
        featureData.featureId,
        buttonStatus,
        !hasAccount,
        hasEntitlement,
        formTemplate?.length
      ),
      actions: [{
        isDisabled: false,
        type: 'link',
        title: t('Common.View'),
        to: featureData.featureId ? `/marketplace/catalogueDetails/${featureData.featureId}` : `#`,
        actionId: ActionType.VIEW_MORE,
        show: true
      }],
      borderTop: isAssigned ? '5px solid var(--primary)' : undefined,
      status: featureDeployStatus,
      statusIcon: setStatusIcon(featureDeployStatus, isAssigned),
      titleLink: featureData.featureId
        ? `/marketplace/catalogueDetails/${featureData.featureId}`
        : `#`,
      formTemplate,
      isAssigned,
      hasEntitlement,
      image:featureData.logoUrl,
      tags: featureData.tagNames,
      subtitle: t('Common.ByText',{owner:featureData.ownedBy}) ,
      feature: featureData 
    };
  };

  useEffect(() => {
    const hasAccount = !!accountId;
    const entitlement =
      entitlements && entitlements.length
        ? entitlements.find(
            (item) =>
              item.skuCode === cloudProductSKU && item.status === 'active'
          )
        : undefined;
    // if ((!hasAccount || !entitlement) && entitlementStatusCode === 404) {
    //   const alertProp: AlertType = {
    //     id: 'invalidAccount',
    //     type: 'warning',
    //     status: 'not_found',
    //     link: globalConfig.config?.trimbleCloudSubscriptionUrl,
    //     message: i18next.t('entitlement.not.found'),
    //     source: RouteType.Workflows,
    //   };
    //   setMessage(alertProp);
    // } else if (entitlementStatusCode === 403) {
    //   const alertProp: AlertType = {
    //     id: 'suspendedAccount',
    //     type: 'warning',
    //     status: 'suspend',
    //     link: globalConfig.config?.SaaSSubscriptionListUrl,
    //     message: i18next.t('Common.Account.Suspended'),
    //     source: RouteType.Workflows,
    //   };
    //   setMessage(alertProp);
    // } else {
    //   if (
    //     alertCloseHandler &&
    //     (alert?.id === 'invalidAccount' || alert?.id === 'suspendedAccount')
    //   )
    //     alertCloseHandler();
    // }
    fetchFeatureProducts(cloudProductSKU, accountId, FeatureType.WORKFLOW).then(
      (product) => {
        const featureIds = product && product?.features?.length ? product?.features?.map((feature) => feature.featureId) : [];
        authorizationInstance?.updatePermissions(featureIds);
        const featureWorkflow = product && product?.features?.length
          ? product?.features?.filter(value => {
            if(props.ownedFeatures) { 
              let status =value.deploymentInfo?.status as SubscriptionStatusTypes;
              return (value.type === FeatureType.WORKFLOW && (isFailed(status) || isSuccess(status) || isInProgress(status)) );
            }else{
              return value.type === FeatureType.WORKFLOW
            }   
          }) : null;
        const workflows = featureWorkflow && featureWorkflow.length ? featureWorkflow?.map((feature) => {
          return getSubscribedFeatures(feature, entitlement, hasAccount);
        }) : [];
        setCatalogueList(workflows);
        setIsLoaded(true);
      },
      (error) => {
        setError({
          message: t('product.api.failure'),
          type: 'error',
          status: 'Failed',
          id: 'product-failure',
        });
      }
    );
  }, [entitlements, accountId, userInfo,authorizationInstance]);

  const styles = {
    minWidth:'390px',
    minHeight:'240px'
  }
  return (
    <div className={isLoaded ? '' : 'loader'}>
      {props.type === 'store_view' ? (
          <ListFeatures
            type="PAGE"
            error={error}
            isLoaded={isLoaded}
            title={'Workflows'}
            catalogueList={catalogueList}
            showConfirmDialog={showConfirmDialog}
            modalMessage={modalMessage}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            clickHandlers={clickHandlers}
            styles={styles}
            source={Constants.ABSTRACT_PAGE_TAB_WORKFLOWS_ID}
        />
      ) : props.type === 'details' ? (
        <FeatureDetails
          showConfirmDialog={showConfirmDialog}
          modalMessage={modalMessage}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          featuresList={catalogueList}
          showLoadingIcon={isLoaded}
          clickHandlers={clickHandlers}
          showCreateDialog={showCreateDialog}
          handleCreateClose={handleCreateDialogClose}
          handleCreateConfirm={handleCreateDialogConfirm}
          selectedActionId={selectedFeature?.actionId}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default CatalogueStore;
