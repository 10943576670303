import styles from './action-button.module.scss';
import { Converter } from '../utils/conversion-util';
import { ActionProps } from './action-props';
import { ModusButton } from '@trimble-oss/modus-react-components';
/* eslint-disable-next-line */
export interface ActionButtonProps extends ActionProps {
  cardId: string;
  cardTitle: string;
  onClickHandler?:any;
}

export function ActionButton(props: ActionButtonProps) {
  const { variant,actionId,onClickHandler, isDisabled, title, status, cardTitle, cardId, isOnload, style } = props;
  const btnName = Converter.replaceSpaceAndColon('action_btn', cardTitle, '');
  if (onClickHandler !== undefined && isDisabled !== undefined) {
    return (
      // <Button
      //   key={props.title}
      //   type={props.type}
      //   variant={variant || 'primary'}
      //   block
      //   data-auto="subscribe"
      //   onClick={() => onClickHandler(actionId,cardId, cardTitle)}
      //   disabled={isDisabled}
      //   id={btnName}
      // >
      <ModusButton  
        key={props.title} 
        color={'primary'} 
        data-auto="subscribe" 
        onClick={() => onClickHandler(actionId,cardId, cardTitle)} 
        style={ style? style: {}}
        disabled={isDisabled} 
        id={btnName}
        buttonStyle={'outline'}
        >  
        {status === 'loading' && (
        isOnload ? (
              <div className={styles['action-onload-button']}>
                <span>{title}</span>
              </div>)
              :
              (<div className={styles['action-button']}>
                <i className="modus-icons left-icon loading-spin">circle_notch</i>
                <span>{title}</span>
              </div>
              )
          )}
        {status === 'error' && (
          <div className={styles['action-button']}>
            <i className="material-icons-outlined modus-icons left-icon">error</i>
            <span>{title}</span>
          </div>
        )}
        {status === 'default' && <span>{title}</span>}
      </ModusButton>
    );
  } else {
    return <span></span>;
  }
}
