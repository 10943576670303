
export const nestedValue = <T>(
    // eslint-disable-next-line @typescript-eslint/ban-types
    input: {},
    path: string,
    defaultValue: T | null = null
): T | null => {
    const paths: null | Array<number | string> = path
        ? path
            .split(".")
            .map(value => (isNaN(Number(value)) ? value : Number(value)))
        : null;

    return paths
        ? (paths.reduce((agg: any, value) => (agg ? agg[value] : defaultValue), input) as T)
        : defaultValue;
};

export const isNullOrUndefined: <T>(value: T) => boolean = value =>
    typeof value === 'undefined' || value === null;

export const notBlank: <T>(value: T) => boolean = value =>
    !(typeof value === 'undefined' || value === null || String(value) === '');

export const mapToArray: <W>(values: W | W[]) => W[] = values => {
    return Array.isArray(values) ? values : values ? [values] : [];
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const hasOwnProperty: <T extends Object, K extends keyof T>(
    property: T,
    key: K
) => boolean = (property, key): boolean =>
        // eslint-disable-next-line no-prototype-builtins
        property && property.hasOwnProperty(key);

// eslint-disable-next-line @typescript-eslint/ban-types
export const getOwnProperty: <T extends Object, K extends keyof T>(
    property: T,
    key: K
// eslint-disable-next-line @typescript-eslint/ban-types
) => T[K] | null = <T extends Object, K extends keyof T>(property: T, key: K): T[K] | null =>
        hasOwnProperty(property, key) ? property[key] : null;

export const isTruthy: (value: string|undefined, expect?: string) => boolean = (
    value,
    expect = "true"
) => !!value && value.toLowerCase() === expect.toLowerCase();

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
