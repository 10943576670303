import ReactDOM from 'react-dom';
import NotificationComponent from '../notification-component/notification-component';
import { NotificationMessage } from '../notification-provider/NotificationProvider';

export interface NotificationProps {
  notificatiomMesgList: NotificationMessage[];
  closeNotification: (id:string)=>void;
}

export function Notification(props: NotificationProps) { 
  const {notificatiomMesgList, closeNotification} = {...props};
  const ele = document.getElementById('notificationportal');
  if (ele) {
    return ReactDOM.createPortal(
      <div className={`notification-wrapper`}>
        {notificatiomMesgList && notificatiomMesgList.map( mesObj=>(
          <NotificationComponent key={mesObj.id} id={mesObj.id} message={mesObj.message} status={mesObj.status} title={mesObj.title} closeNotification={closeNotification}></NotificationComponent>
        ))}       
      </div>
      , ele);
  }
  return null;
}

export default Notification;
