export const RouteConstants: { [key: string]: string } = {
  login: '/login',
  signin: '/signin',
  workflow: '/workflow',
  catalogueDetail: '/workflow/catalogueDetails/',
  capabilities: '/capabilities',
  capabilitiesDetail: '/capabilities/details/',
  data: '/data',
  mycontent: '/drive/mycontent',
  recyclebin: '/drive/recyclebin',
  users: '/admin/users',
  usagedashboard:'/admin/dashboard',
  apis: '/apis',
  feedback: '/feedback',
  settings: '/settings',
  'cloud-support': '/cloud-support',
  logout: '/logout',
  myCapabilities: '/my-capabilities',
  marketplaceDeveloper: '/marketplace/developer',
  marketplaceCapabilities: '/marketplace/capabilities',
  workflowDetail: '/marketplace/capabilities/detail',
  authError: '/auth-error',
  inviteError: '/invite-error',
  noSubscription: '/no-subscription',
  accountSuspended: '/account-suspended',
  commonPageError:'/error-page'
};
