import { useState } from "react";

export const useLocalStorage = (keyName:string, defaultValue:string|undefined) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = window.localStorage.getItem(keyName);
            if (value) {
                return JSON.parse(value);
            } else {
                window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
                return defaultValue;
            }
        } catch (err) {
            return defaultValue;
        }
    });
    const setValue = (newValue:any) => {
        try {
            window.localStorage.setItem(keyName, JSON.stringify(newValue));
        } catch (err) { 
            //
        }
        setStoredValue(newValue);
    };
    return [storedValue, setValue];
};

 export const getLocalStorageValue = (key:string)=> {
    return localStorage.getItem(
        key
    );
}

export const removeLocalStorageValue = (key: string) => {
    return localStorage.removeItem(
        key
    );
}

  export const setLocalStorageValue = (key: string,
    value: string)=> {
    localStorage.setItem(
       key,
        value
    );
}