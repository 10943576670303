import styles from './data-store.module.scss';
import { t } from 'i18next';
import CommingSoonStore from '../comming-soon-store/comming-soon-store';

export interface DataStoreProps {
  type: 'store_view' | 'my_subscriptions';
  title: string;
}

export function DataStore(props: DataStoreProps) {
  const getMessage = () => (
    <div className={styles['comingSoonMsg']}>
      <p>{t('Data.Description.Message')}</p>
    </div>
  );
  return (
    <CommingSoonStore
      title={t('Data.Heading.Title')}
      subtitle={t('Data.Heading.Description')}
      comingSoonTitle={t('Common.ComingSoon.Title') + '!'}
      comingSoonMsg={getMessage()}
      inviteDescription1={t('Data.Subheading.Description1')}
      inviteDescription2={t('Data.Subheading.Description2')}
      inviteType={'type1'}
      source={'Connected Data Environment'}
    />
  );
}

export default DataStore;
