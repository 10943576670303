import { ModusModal } from '@trimble-oss/modus-react-components';
import { FormRenderer, IField } from '@workflow/shared/ui-form';
import { useEffect, useState } from 'react';
import {
  InvitationRequest,
  sendInvitations,
} from '../../API/UserInvitationService';
import { t } from 'i18next';
import { ActionType } from '@workflow/ui';
import styles from './create-user-dialog.module.scss';
import { ToastPosition } from 'react-bootstrap/esm/ToastContainer';
/* eslint-disable-next-line */
export interface CreateUserDialogProps {
  title: string;
  onInvitationComplete: (
    success: number,
    closeDialog?: boolean,
    toastPosition?: ToastPosition,
    conflict?: string[],
    error?: string[]
  ) => void;
  accountId: string | undefined;
  currentUserName: string;
  onClose: () => void;
  onError: (error: any) => void;
}

export function CreateUserDialog(props: CreateUserDialogProps) {
  const { title } = { ...props };
  const [triggerSubmit, setTriggerSubmit] = useState(0);
  const [resetForm, setResetForm] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [formTemplate, setTemplate] = useState<IField[]>();
  const [disableButtons, setdisableButtons] = useState(false);
  const [defaultSelectOption, setDefaultSelectOption] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string[]>();
  const [successCount, setSuccessCount] = useState<number>();
  const openAddDialog = () => {
    const modalElement = document.querySelector<Element>(
      'modus-modal#create-dialog'
    );
    (modalElement as unknown as HTMLModusModalElement).open();
  };
  const defaultOption = {
    id: 'TCP_COMPANY_ADMIN',
    label: t('Common.Role.Title.Administrator'),
    helperText: t('Common.Role.Description.Administrator'),
  };
  const options = [
    defaultOption,
    {
      id: 'TCP_END_USER',
      label: t('Common.Role.Title.User'),
      helperText: t('Common.Role.Description.User'),
    },
  ];
  const template = [
    {
      type: 'webinput',
      label: t('Users.AddModal.EnterEmail'),
      required: true,
      name: 'email',
      regex: /^(([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(,|$)\s?))*$/,
      isControlled: false,
      helperText: t('Users.AddModal.EmailHelper'),
      message: 'Please correct the email address.',
      validate: {
        noSameEmail: (value: any) => {
          const emails: string[] = getEmailArray(value);
          const hasDuplicates = (emails: string[]) => {
            return new Set(emails).size !== emails.length;
          };
          if (!hasDuplicates(emails)) {
            return undefined;
          } else {
            return 'Email Addresses should not contain duplicates entries.';
          }
        },
        maxEmail: (value: any) => {
          const emails: string[] = getEmailArray(value);

          if (emails.length <= 10) {
            return undefined;
          } else {
            return 'Please enter only up to 10 emails.';
          }
        },
      },
    },
    {
      type: 'webselect',
      label: t('Users.AddModal.SecurityRole'),
      required: true,
      name: 'securityRole',
      isControlled: false,
      defaultValue: defaultOption,
      options,
      onChange: (e: any) => {
        setDefaultSelectOption((prevState: any) => {
          return {
            ...prevState,
            id: e.detail.id,
            label: e.detail.label,
            helperText: e.detail.helperText,
          };
        });
        setTemplate(
          template
            ? [...template].map((temp) => {
                if (temp.name === 'securityRole') {
                  temp.defaultValue = e.detail;
                }
                return temp;
              })
            : undefined
        );
      },
    },
  ];
  useEffect(() => {
    setDefaultSelectOption(defaultOption);
    setTemplate(template);
    openAddDialog();
  }, []);
  const getEmailArray = (values: string) => {
    const emails = values
      ? values.includes(',')
        ? values.split(',')
        : values.includes(';')
        ? values.split(';')
        : [values]
      : [];
    return emails
      .map((email: string) => email.trim())
      .filter((value: string) => value.length > 0);
  };
  const onSubmit = (data: any) => {
    setdisableButtons(true);
    let request: { invitations: InvitationRequest[] };
    const emailArray = getEmailArray(data.email);

    if (emailArray.length > 0) {
      const invitations: InvitationRequest[] = emailArray.map(
        (email: string) => {
          return {
            inviteEmailAddress: email,
            role: data.securityRole?.id,
            accountId: props.accountId,
            inviterName: props.currentUserName,
          };
        }
      );
      request = {
        invitations,
      };
      setIsLoaded(false);
      sendInvitations(request).then(
        (res) => {
          setIsLoaded(true);
          setdisableButtons(false);
          if (res) {
            if (
              res.details.every(
                (val: any) => val.statusCode === 201 || val.statusCode === 200
              )
            ) {
              document.querySelector('modus-modal')?.close();
              props.onInvitationComplete(
                res.details.length,
                true,
                'top-center'
              );
            } else {
              const success = res.details.filter(
                (val: any) => val.statusCode === 201 || val.statusCode === 200
              );
              const conflict = res.details.filter(
                (val: any) => val.statusCode === 409
              );
              const errorMsg = conflict.map((val: any) => {
                const msg = val?.result.message.split(' ');
                return msg[0];
              });
              setResetForm(true);
              if (errorMsg.length) {
                setErrorMessage(errorMsg);
              }
              if (success.length) {
                setSuccessCount(success.length);
              }
            }
          }
        },
        (error) => {
          setIsLoaded(true);
          setdisableButtons(false);
          props.onError(error);
          document.querySelector('modus-modal')?.close();
        }
      );
    }
  };

  const onActionClick = () => {
    hideMessages();
    setTriggerSubmit((value) => value + 1);
  };
  const hideMessages =() =>{
    setSuccessCount(undefined);
    setErrorMessage(undefined);
  }
  return (
    <ModusModal
      id="create-dialog"
      backdrop={'static'}
      zIndex="1050"
      headerText={title}
      primaryButtonText={t('Common.Add')}
      primaryButtonDisabled={disableButtons}
      onPrimaryButtonClick={onActionClick}
      secondaryButtonText={t('Common.Cancel')}
      secondaryButtonDisabled={disableButtons}
      onSecondaryButtonClick={() => {
        setIsLoaded(true);
        document.querySelector('modus-modal')?.close();
      }}
      onClosed={() => {
        props.onClose();
      }}
    >
      <div>
        {!isLoaded && (
          <div className="progress-bar">
            <div className="progress-bar-value"></div>
          </div>
        )}
        <div className={styles['user-create-dialog-content']}>
          {successCount && successCount > 0 && (
            <div
              className={`${styles['user-create-dialog-alert']} ${styles['user-create-dialog-alert-success']} ${styles['user-create-dialog-alert__container']}`}
            >
              <i className="material-icons">check_circle</i>
              <div>
                <div>{t('Users.Page.UserAdd.Success')}</div>
                <div>
                  {t('Users.Page.UserAdd.SuccessMsg', { count: successCount })}
                </div>
              </div>
            </div>
          )}
          {errorMessage && errorMessage.length && (
            <div
              className={`${styles['user-create-dialog-alert']} ${styles['user-create-dialog-alert-error']} ${styles['user-create-dialog-alert__container']}`}
            >
              <i className="material-icons">error</i>
              <div>
                <span>{t('Users.Page.UserAdd.ConflictMsg')}</span>
                <ul className={styles['user-create-dialog-alert__list']}>
                  {errorMessage.map((val: string) => {
                    return (
                      <li>
                        <span>{val}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
          <FormRenderer
            cssStyle={{ width: '430px', height: '250px' }}
            formData={{
              securityRole: defaultSelectOption,
            }}
            formTemplate={formTemplate}
            onSubmit={onSubmit}
            triggerSubmit={triggerSubmit}
            actionId={ActionType.ADD}
            resetForm={resetForm}
          ></FormRenderer>
        </div>
      </div>
    </ModusModal>
  );
}

export default CreateUserDialog;
