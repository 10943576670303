import {t} from 'i18next';
import { ModusModal } from '@trimble-oss/modus-react-components';
import { useEffect, useRef } from 'react';
/* eslint-disable-next-line */
export interface ModalPopupProps {
  showConfirmDialog: any;
  handleClose: any;
  handleConfirm: any;
  title: string;
  message: string;
  confirmBtnTitle?: any;
}

export interface ModalMessage {
  title: string;
  message: string;
  confirmBtnTitle?: string;
}

export function ModalPopup(props: ModalPopupProps) {
  const modal = useRef<HTMLModusModalElement>(null);
  const {title, message, showConfirmDialog, handleClose, handleConfirm, confirmBtnTitle} = {...props};

  useEffect(() => {
    if(showConfirmDialog) {
      modal.current?.open();
    } else{
      modal.current?.close();
    }
  }, [showConfirmDialog]);


  return (
    <div>
    <ModusModal 
          headerText={title} 
          zIndex='1050'
          backdrop={'static'}
          primaryButtonText={(confirmBtnTitle) ? confirmBtnTitle : t('Common.Confirm')}
          secondaryButtonText= {t('Common.Cancel')}
          onPrimaryButtonClick={handleConfirm}
          onSecondaryButtonClick={handleClose}   
          onClosed={handleClose}
          ref={modal}    
        >
          <div>
            {message}
          </div>      
        </ModusModal>
    </div>
    
  );
}
