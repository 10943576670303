import styles from './page-header.module.scss';
import { ModusButton } from '@trimble-oss/modus-react-components';
import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface PageHeaderProps {
  pageType: string;
  title: string;
  shortNote: string;
  desc: any;
  margin?:string;
  onCloseClick:(event:any) => void;
}
export enum Constants {
  PAGE_MY_CAPABILITIES_ID                 = 'myCapabilities',
  PAGE_MARKETPLACE_CAPABILITIES_ID        = 'marketplaceCapabilities',
  PAGE_DEVELOPER_TOOLS_ID                 = 'developerTools',
}
export function PageHeader(props: PageHeaderProps) {
  const [closeIcon, setCloseIcon] = useState<boolean>(true);
  const [currentWidth, setCurrentWidth] = useState<number>(1200);
  let image: any;
  if (props.pageType) {
    if (props.pageType === Constants.PAGE_MARKETPLACE_CAPABILITIES_ID) {
      image = 'images/marketplace_capabilities.svg';
    } else if (props.pageType === Constants.PAGE_MY_CAPABILITIES_ID) {
      image = 'images/my_capabilities.svg';
    } else if (props.pageType === Constants.PAGE_DEVELOPER_TOOLS_ID) {
      image = 'images/developer_tools.svg';
    }
  } else {
    image = 'images/no_results_found.svg';
  }

  const stylesValue = {
    margin: props.margin ? props.margin?.includes('px') ? props.margin :`${props.margin}px`:'none',
    backgroundImage: "url('images/frame_background.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
  const closeIconHandler = (event: any) => {
    setCloseIcon(false);
    props.onCloseClick(event)
  };

  const windowResizing = () => {
    setCurrentWidth(window.innerWidth);
  };

  useEffect(() => {
    setCloseIcon(true);
    setCurrentWidth(window.innerWidth);
  }, [props.pageType]);

  if (closeIcon) {
    window.addEventListener('resize', windowResizing);
    return (
      <div className={styles['outerContainer']} style={stylesValue}>
        <div className={styles['text']}>
          <span className={styles['title']}>{props.title}</span>
          <div>
            <span className={styles['desc']}>{props.shortNote} </span>{' '}
            {/* <br></br> */}
            <p className={styles['desc']}>{props.desc}</p>
          </div>
        </div>
        { currentWidth > 800 && (<div className={styles['image']}>
          <img className={styles['imageSize']} src={image} alt="info-graphic" />
          <ModusButton
            iconOnly={'close'}
            buttonStyle={'borderless'}
            size={'small'}
            onClick={closeIconHandler}
          ></ModusButton>
        </div>) }
        
      </div>
    );
  } else {
    return null;
  }
}

export default PageHeader;
