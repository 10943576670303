import axios from 'axios';
import { isDevelopment } from '../API/Utils/enivronment-util';
import {
  requestSuccessHandler,
  requestErrorHandler,
  responseSuccessHandler,
  responseErrorHandler,
} from '../hooks/axiosInstanceHelper';
import { ApiProduct } from '../models/ApiProductResponse';

const apiProductsInstance = axios.create({
  baseURL: isDevelopment
    ? `${process.env['NX_CONSOLE_CLOUD_URL']}${process.env['NX_CONSOLE_CLOUD_URL_PATTERN']}`
    : 'APP.CONSOLE.CLOUD.URL' + 'APP.CONSOLE.CLOUD.PATTERN',
});

apiProductsInstance.interceptors.request.use(
  requestSuccessHandler,
  requestErrorHandler
);
apiProductsInstance.interceptors.response.use(
  responseSuccessHandler,
  responseErrorHandler
);

export const fetchApiProducts = async (): Promise<ApiProduct[]> => {
  const result = await apiProductsInstance.request({
    method: 'GET',
    url: `products`,
  });
  return result && result.data ? result.data : undefined;
};
