import React from 'react';
import { useEffect, useState } from 'react';
import { fetchEntitlementData } from '../API/FeatureDeployment';
import { useAuth } from '../hooks/useAuthProviderHook';
import { globalConfig } from '../API/Utils/enivronment-util';
import { useTranslation } from 'react-i18next';
import { WorkflowEntitlement } from '../models/EntitlementDBResponse';
import axios from 'axios';
import { useNotificationContext } from './notification-provider/NotificationProvider';
import { CircleSpinner } from '@workflow/shared/info-graphics';
import { Navigate } from 'react-router';
import { RouteConstants } from '../API/Utils/RouteConstants';
import ErrorPage from '../pages/inviterror/error-page';

/* eslint-disable-next-line */
export interface EntitlementProps {
  entitlements: WorkflowEntitlement[];
  accountId: string | undefined;
}
export const EntitlementContext = React.createContext<EntitlementProps>({
  entitlements: [],
  accountId: undefined,
});

export const useEntitlements = () => {
  return React.useContext(EntitlementContext);
};

export function AppView({ children }: any) {
  const { userInfo } = useAuth();
  const [error, setError] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [entitlements, setEntitlements] = useState<WorkflowEntitlement[]>([]);
  const [accountId, setAccountId] = useState<string | undefined>(undefined);

  const { setHasError } = useNotificationContext();
  const [navigateTo, setNavigate] = useState<string>();
  const { t } = useTranslation();
  const setErrorInContext = () => {
    if (setHasError) setHasError(true);
  };

  const isNotFound = (error: any) => {
    return error
      ? error.status === 404 &&
          (error.data?.details?.includes('No entitlement') ||
            error.data?.details?.includes('No domain found') ||
            error.data?.details?.includes('No tenant id'))
      : false;
  };
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    fetchEntitlementData(
      userInfo?.email,
      globalConfig.config?.productName,
      'active,suspend'
    )
      .then((result) => {
        const hasEntitlements =
          result &&
          result.data &&
          result.data.items &&
          result.data.items.length;
        if (hasEntitlements) {
          setAccountId(result.data.items[0].accountId);
          const isSuspended = result.data.items.some(
            (value) => value.status === 'suspend'
          );
          if (isSuspended) {
            setNavigate(RouteConstants['accountSuspended']);
            setErrorInContext();
          } else {
            setEntitlements(result.data.items);
            setIsLoaded(true);
          }
        } else {
          setNavigate(RouteConstants['noSubscription']);
          setErrorInContext();
        }
      })
      .catch((error) => {
        console.log(
          'Error occurred in rendering entitlement api details',
          error
        );
        if (isNotFound(error)) {
          setNavigate(RouteConstants['noSubscription']);
          setErrorInContext();
        } else {
          setErrorInContext();
          setError(true);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [userInfo]);

  const value = {
    // entitlementStatusCode,
    entitlements,
    accountId,
  };

  if (navigateTo) {
    return <Navigate to={navigateTo} />;
  } else if (error) {
    return (
      <ErrorPage
        pageType={RouteConstants['commonPageError']}
        message={t('ErrorPage.UnknownError.desc')}
        showSignOut={true}
      />
    );
  } else if (!isLoaded) {
    return (
      <CircleSpinner
        size="48px"
        text={t('Common.Loading.Message')}
      ></CircleSpinner>
    );
  } else {
    return (
      <EntitlementContext.Provider value={value}>
        {children}
      </EntitlementContext.Provider>
    );
  }
}

export default AppView;
