import { FeatureAction, Permission } from './Permission';

export type Action = 'associate' | 'read' | 'create' | 'delete' | 'update';

export const actionMap: { [key: string]: FeatureAction } = {
  read: 'R',
  associate: 'A',
  create: 'C',
  delete: 'D',
  update: 'U',
};

export type AbilityInputType = string;

export type AbilityCallback = (actions?: Action, feature?: any) => void;

export class Ability {
  constructor(
    private feature: any,
    private permission: Permission,
  ) {}

  public can(actions: Action): boolean;
  public can(
    actions: Action,
    success: AbilityCallback,
    fail?: AbilityCallback
  ): Ability;
  public can(
    actions: Action,
    success?: AbilityCallback,
    fail?: AbilityCallback
  ): boolean | Ability {
    return this.handleAbility(
      actions,
      this.hasPermission(actions),
      success,
      fail
    );
  }

  public cannot(actions: Action): boolean;
  public cannot(
    actions: Action,
    success?: AbilityCallback,
    fail?: AbilityCallback
  ): Ability;
  public cannot(
    actions: Action,
    success?: AbilityCallback,
    fail?: AbilityCallback
  ): boolean | Ability {
    return this.handleAbility(
      actions,
      !this.hasPermission(actions),
      success,
      fail
    );
  }

  private handleAbility(
    actions: Action,
    doSuccess: boolean,
    success?: AbilityCallback,
    fail?: AbilityCallback
  ): boolean | Ability {
    return success || fail
      ? this.handleCallback(actions, doSuccess, success, fail)
      : doSuccess;
  }

  private handleCallback(
    actions: Action,
    doSuccess: boolean,
    success?: AbilityCallback,
    fail?: AbilityCallback
  ): Ability {
    if (doSuccess && success) {
      success.apply(success, [actions, this.feature]);
    } else if (!doSuccess && fail) {
      fail.apply(fail, [actions, this.feature]);
    }
    return this;
  }

  private hasPermission(actions: Action): boolean {
    return this.permission
      ? this.permission?.permissions.some(
          (action) => action === actionMap[actions]
        )
      : false;
  }
}
