import { useEffect, useReducer, useState } from 'react';
import { useAuth } from '../../hooks/useAuthProviderHook';
import ListFeatures from '../../components/list-features/list-features';
import { FeatureListItem } from '../../models/FeatureListItem';
import { ModalMessage } from '@workflow/ui';
import { t } from 'i18next';
import { sendCustomEvent } from '../../assets/js_files/gs';
import { fetchFeatureProducts } from '../../API/ProductAPI';
import { FeatureType } from '../../models/FeatureType';
import { globalConfig } from '../../API/Utils/enivronment-util';
import { ActionType } from '../../../../../libs/ui/src/action-button/action-props';
import { useEntitlements } from '../../components/app-view';
import { Feature } from '../../models/Product';
import { WorkflowEntitlement } from '../../models/EntitlementDBResponse';
import { SubscriptionStatusTypes } from '../../models/FeatureDeployment';
import { ProvisioningAPIs } from '../../API/provisioingservice';
import { ProvisioningAction } from '../../models/ProvisioningAction';
import {
  AlertType,
  useNotificationContext,
} from '../../components/notification-provider/NotificationProvider';
import { RouteType } from '../../models/RouteType';
import { useNavigate } from 'react-router-dom';
import { Constants } from '../../API/AppConstants';
import FeatureDetails from '../feature-details/feature-details';
import { useAuthorizationHandler } from '../../hooks/use-authorization-handler/use-authorization-handler';

export interface CapabilitiesStoreProps {
  type: 'store_view' | 'my_subscriptions' | 'details';
  title: string;
  ownedFeatures?: boolean;
}

export function CapabilitiesStore(props: CapabilitiesStoreProps) {
  sendCustomEvent(
    'capability-store',
    'Customer is viewing the capability store'
  );
  const cloudProductSKU = globalConfig.config?.productName;

  const [error, setError] = useState<any | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [catalogueList, setItems] = useState<FeatureListItem[]>([]);
  const [showConfirmDialog, setShowDialog] = useState(false);
  const [modalMessage, setModalMessage] = useState<ModalMessage>();
  const { entitlements, accountId } = useEntitlements();
  const {authorizationInstance} = useAuthorizationHandler();
  const [userInfoObj, setUserInfoObj] = useState<any>();
  const { authInfo, userInfo } = useAuth();
  const { setAlertMesage } = useNotificationContext();
  const [unSubscribeId, setId] = useState('');
  const modalUnsubscribeTitle = t('Capabilities.Remove.Modal.Title');
  const modalConfirmTitle = t('Capabilities.Remove.Modal.Confirm');
  const navigate = useNavigate();
  const getModalUnsubscribeMessage = (productName: string) => {
    return t('Capabilities.Remove.Modal.Message', { productName });
  };

  const setMessage = (message: AlertType) => {
    if (setAlertMesage) {
      setAlertMesage(message);
    }
  };
  const updateButtonState = (
    actionId: ActionType,
    id: string,
    productName: string,
    status: SubscriptionStatusTypes,
    updateNotification = true,
    isOnload?: boolean
  ) => {
    const eventData = {
      id: id,
      type: 'success',
      status: status,
      name: productName,
    };
    let refresh = false;
    sendCustomEvent('capability', eventData);
    switch (status) {
      case SubscriptionStatusTypes.SUBSCRIBE_INPROGRESS:
        updateState(
          actionId,
          id,
          t('Common.InProgress'),
          true,
          true,
          status,
          null,
          isOnload
        );
        break;
      case SubscriptionStatusTypes.SUBSCRIBE_SUCCESS:
        updateState(
          actionId,
          id,
          '- ' + t('Common.Remove'),
          false,
          false,
          status,
          handleUnsubscribe,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'success',
            status: 'Completed',
            message: t('Capabilities.AddSuccess.AlertMessage', { productName }),
            source: RouteType.Capabilities,
          });
        }
        refresh = true;
        break;
      case SubscriptionStatusTypes.SUBSCRIBE_FAILED:
        updateState(
          actionId,
          id,
          t('Common.Failed'),
          true,
          false,
          status,
          null,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'error',
            status: 'Failed',
            message: t('Capabilities.AddFailure.AlertMessage', { productName }),
            source: RouteType.Capabilities,
          });
        }
        break;
      case SubscriptionStatusTypes.UNSUBSCRIBE_SUCCESS:
        updateState(
          actionId,
          id,
          '+ ' + t('Common.Add'),
          false,
          false,
          status,
          getSubscribed,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'success',
            status: 'Completed',
            message: t('Capabilities.RemoveSuccess.AlertMessage', {
              productName,
            }),
            source: RouteType.Capabilities,
          });
        }
        refresh = true;
        break;
      case SubscriptionStatusTypes.UNSUBSCRIBE_INPROGRESS:
        updateState(
          actionId,
          id,
          t('Common.InProgress'),
          true,
          true,
          status,
          null,
          isOnload
        );
        break;
      case SubscriptionStatusTypes.UNSUBSCRIBE_FAILED:
        updateState(
          actionId,
          id,
          t('Common.Failed'),
          true,
          false,
          status,
          null,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'error',
            status: 'Failed',
            message: t('Capabilities.RemoveFailure.AlertMessage', {
              productName,
            }),
            source: RouteType.Capabilities,
          });
        }
        break;
      case SubscriptionStatusTypes.SUBSCRIBE_CONFLICT:
        updateState(
          actionId,
          id,
          t('Common.Failed'),
          true,
          false,
          status,
          null,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'error',
            status: 'Conflict',
            message: t('Capabilities.AddConflict.AlertMessage', {
              productName,
            }),
            source: RouteType.Capabilities,
          });
        }
        break;
      case SubscriptionStatusTypes.UNSUBSCRIBE_CONFLICT:
        updateState(
          actionId,
          id,
          t('Common.Failed'),
          true,
          false,
          status,
          null,
          isOnload
        );
        if (updateNotification) {
          setMessage({
            id: getMessageId(actionId, id),
            type: 'error',
            status: 'Conflict',
            message: t('Capabilities.RemoveConflict.AlertMessage', {
              productName,
            }),
            source: RouteType.Capabilities,
          });
        }
        break;
    }
    if(refresh) {
      navigate('/marketplace/capabilities/temp');
      navigate(-1);
    }
  };

  const getMessageId = (actionId: ActionType, id: string) => {
    return [actionId, id].filter((val) => !!val).join('-');
  };
  const updateState = (
    actionId: ActionType,
    id: string,
    titleP: string,
    isDisabledP: boolean,
    isInProgressP: boolean,
    statusP: SubscriptionStatusTypes,
    onClickMethod: any,
    isOnload?: boolean
  ) => {
    setItems((prevState) => {
      const currentState = [...prevState];
      const productToUpdate = currentState.filter((val) => {
        if (val.id === id) {
          return true;
        }
        return false;
      });
      if (
        productToUpdate.length > 0 &&
        productToUpdate[0] !== undefined &&
        productToUpdate[0].actions !== undefined
      ) {
        const actionsUpdated = productToUpdate[0].actions.map((val) => {
          if (val.actionId === actionId && val.type === 'button') {
            return {
              ...val,
              title: titleP,
              isDisabled: isDisabledP,
              isInProgress: isInProgressP,
              status: setButtonStatus(statusP),
              clickHandlerLabel: onClickMethod,
              isOnload: isOnload,
            };
          } else {
            return {
              ...val,
              isDisabled: isDisabledP,
              show: !isDisabledP,
            };
          }
        });
        const isAssigned =
          statusP === SubscriptionStatusTypes.SUBSCRIBE_SUCCESS;
        return currentState.map((val) => {
          if (val.id === id) {
            return {
              ...val,
              borderTop: isAssigned ? '5px solid var(--primary)' : undefined,
              statusIcon: setStatusIcon(statusP, isAssigned),
              actions: actionsUpdated,
            };
          }
          return val;
        });
      }
      return currentState;
    });
  };

  const setButtonStatus = (status: SubscriptionStatusTypes) => {
    return isSuccess(status)
      ? 'default'
      : isFailed(status)
      ? 'error'
      : isInProgress(status)
      ? 'loading'
      : 'default';
  };

  const getSubscribed = async (
    actionId: ActionType,
    featureId: string,
    productName: string
  ) => {
    updateButtonState(
      actionId,
      featureId,
      productName,
      SubscriptionStatusTypes.SUBSCRIBE_INPROGRESS
    );
    const data = {
      accountId,
      userId: userInfoObj.userId,
      skuCode: cloudProductSKU,
      email: userInfoObj.email,
      action: ProvisioningAction.SUBSCRIBE,
      featureId,
      featureType: FeatureType.CAPABILITY,
    };
    ProvisioningAPIs.startProvision(data, productName, handlers, actionId);
  };
  const handleUnsubscribeConfirm = () => {
    const catalogueItem = catalogueList.find(
      (item) => item.id === unSubscribeId
    );
    const productName = catalogueItem ? catalogueItem.title : '';
    const data = {
      accountId,
      userId: userInfoObj.userId,
      featureId: unSubscribeId,
      skuCode: cloudProductSKU,
      email: userInfoObj.email,
      action: ProvisioningAction.UNSUBSCRIBE,
      data: {
        entitlementId: catalogueItem?.entitlement?.entitlementId,
      },
      featureType: FeatureType.CAPABILITY,
    };
    setShowDialog(false);
    updateButtonState(
      ActionType.REMOVE,
      unSubscribeId,
      productName,
      SubscriptionStatusTypes.UNSUBSCRIBE_INPROGRESS
    );
    ProvisioningAPIs.startProvision(
      data,
      productName,
      handlers,
      ActionType.REMOVE
    );
  };
  const handleUnsubscribe = (
    actionId: ActionType,
    cardId: string,
    productName: string
  ) => {
    setId(cardId);
    setModalMessage({
      title: modalUnsubscribeTitle,
      message: getModalUnsubscribeMessage(productName),
      confirmBtnTitle: modalConfirmTitle,
    });
    setShowDialog(true);
  };
  const handlers = {
    getSubscribed,
    authInfo,
    userInfoObj,
    updateButtonState,
  };
  const isInProgress = (status: SubscriptionStatusTypes) => {
    return (
      status === SubscriptionStatusTypes.UNSUBSCRIBE_INPROGRESS ||
      status === SubscriptionStatusTypes.UNSUBSCRIBE_PENDING ||
      status === SubscriptionStatusTypes.SUBSCRIBE_INPROGRESS ||
      status === SubscriptionStatusTypes.SUBSCRIBE_PENDING ||
      status === SubscriptionStatusTypes.REINSTATE_INPROGRESS ||
      status === SubscriptionStatusTypes.REINSTATE_PENDING
    );
  };

  const isSuccess = (status: SubscriptionStatusTypes) => {
    return (
      status === SubscriptionStatusTypes.SUBSCRIBE_SUCCESS ||
      status === SubscriptionStatusTypes.REINSTATE_SUCCESS ||
      status === SubscriptionStatusTypes.UNSUBSCRIBE_SUCCESS
    );
  };
  const isFailed = (status: SubscriptionStatusTypes) => {
    return (
      status === SubscriptionStatusTypes.SUBSCRIBE_FAILED ||
      status === SubscriptionStatusTypes.UNSUBSCRIBE_FAILED ||
      status === SubscriptionStatusTypes.REINSTATE_FAILED ||
      status === SubscriptionStatusTypes.SUSPEND_FAILED
    );
  };
  const setStatusIcon = (
    featureDeployStatus: SubscriptionStatusTypes | undefined,
    isAssigned?: boolean
  ) => {
    return featureDeployStatus
      ? isAssigned &&
        (featureDeployStatus === SubscriptionStatusTypes.SUBSCRIBE_SUCCESS ||
          featureDeployStatus === SubscriptionStatusTypes.REINSTATE_SUCCESS)
        ? 'check_circle'
        : isFailed(featureDeployStatus)
        ? 'error'
        : isInProgress(featureDeployStatus)
        ? 'pending'
        : undefined
      : undefined;
  };

  const getActionButtonStatus = (
    status: SubscriptionStatusTypes | undefined
  ) => {
    switch (status) {
      case SubscriptionStatusTypes.SUBSCRIBE_INPROGRESS:
      case SubscriptionStatusTypes.SUBSCRIBE_PENDING:
      case SubscriptionStatusTypes.UNSUBSCRIBE_INPROGRESS:
      case SubscriptionStatusTypes.UNSUBSCRIBE_PENDING:
      case SubscriptionStatusTypes.REINSTATE_INPROGRESS:
      case SubscriptionStatusTypes.REINSTATE_PENDING:
        return {
          title: 'In Progress',
          isDisabled: true,
          status: 'loading',
        };
      case SubscriptionStatusTypes.SUBSCRIBE_FAILED:
      case SubscriptionStatusTypes.UNSUBSCRIBE_FAILED:
      case SubscriptionStatusTypes.REINSTATE_FAILED:
        return {
          title: 'Failed',
          isDisabled: true,
          status: 'error',
        };
      default:
        return undefined;
    }
  };

  const handleClose = () => {
    setShowDialog(false);
  };
  const getActionButtons = (
    isAssigned?: boolean,
    id?: string,
    buttonStatus?: {
      title: string;
      isDisabled: boolean;
      status: string;
    },
    isDisabled?: boolean,
    hasEntitlement?: boolean,
    launchUrl?: string
  ) => {
    const actionBtnArr = [];
    const hasPermission =id ? authorizationInstance?.for(id).can(isAssigned ? 'delete':'create'):false;
    if (hasEntitlement && hasPermission) {
      actionBtnArr.push({
        type: 'button',
        isDisabled: buttonStatus ? buttonStatus.isDisabled : isDisabled,
        title: buttonStatus
          ? buttonStatus.title
          : isAssigned
          ? t('Common.Remove') + ' Capability'
          : t('Common.Add') + ' Capability',
        clickHandlerLabel: isAssigned ? 'handleUnsubscribe' : 'getSubscribed',
        status: buttonStatus ? buttonStatus.status : 'default',
        isOnload: true,
        actionId: isAssigned ? ActionType.REMOVE : ActionType.ADD,
        show: true,
      });
    }
    return actionBtnArr;
  };
  const clickHandlers = {
    getSubscribed: getSubscribed,
    handleUnsubscribe: handleUnsubscribe,
  };
  const getSubscribedFeatures = (
    feature: Feature,
    entitlement: WorkflowEntitlement | undefined,
    hasAccount: boolean
  ) => {
    const featureDeployStatus = feature
      ? feature.deploymentInfo?.status
      : undefined;
    const hasEntitlement = !!entitlement;
    const isAssigned =
      hasAccount &&
      entitlement &&
      feature &&
      (featureDeployStatus === SubscriptionStatusTypes.SUBSCRIBE_SUCCESS ||
        featureDeployStatus === SubscriptionStatusTypes.REINSTATE_SUCCESS);
    const buttonStatus = getActionButtonStatus(featureDeployStatus);

    return {
      entitlement: entitlement,
      accountId,
      active: feature.active,
      deploymentInfo: { ...feature.deploymentInfo, deploymentId: feature.id },
      title: feature.name,
      info: feature.summary,
      summary: feature.summary,
      id: feature.featureId,
      crtdDt: feature.crtdDt,
      detailActions: getActionButtons(
        isAssigned,
        feature.featureId,
        buttonStatus,
        !hasAccount,
        hasEntitlement
      ),
      actions: props.ownedFeatures
        ? feature?.catalogue?.urls?.launch
          ? [
              {
                isDisabled: false,
                type: 'launch',
                title: t('Common.Launch'),
                to: feature?.catalogue?.urls?.launch,
                target: feature?.catalogue?.urls?.launch ? '_blank' : undefined,
                actionId: ActionType.LAUNCH,
                show: true,
                icon: 'launch',
              },
            ]
          : undefined
        : [
            {
              isDisabled: false,
              type: 'link',
              title: t('Common.View'),
              to: feature.featureId
                ? `/marketplace/capabilities/details/${feature.featureId}`
                : '#',
              actionId: ActionType.VIEW_MORE,
              show: true,
            },
          ],
      borderTop: isAssigned ? '5px solid var(--primary)' : undefined,
      status: featureDeployStatus,
      statusIcon: setStatusIcon(featureDeployStatus, isAssigned),
      titleLink: feature.featureId
        ? `/marketplace/capabilities/details/${feature.featureId}`
        : `#`,
      isAssigned,
      hasEntitlement,
      image: feature.logoUrl,
      tags: feature.tagNames,
      subtitle: t('Common.ByText', { owner: feature.ownedBy }),
      feature: feature,
    };
  };
  useEffect(() => {
    const hasAccount = !!accountId;
    const entitlement = entitlements
      ? entitlements.find(
          (item) => item.skuCode === cloudProductSKU && item.status === 'active'
        )
      : undefined;
    fetchFeatureProducts(
      cloudProductSKU,
      accountId,
      FeatureType.CAPABILITY
    ).then(
      (result) => {
        const featureIds = result && result?.features?.length ? result?.features?.map((feature) => feature.featureId) : [];
        authorizationInstance?.updatePermissions(featureIds);
        const featureWorkflow =
          result && result?.features?.length
            ? result?.features?.filter((value) => {
                if (props.ownedFeatures) {
                    let status =value.deploymentInfo?.status as SubscriptionStatusTypes;
                    return (value.type === FeatureType.CAPABILITY && (isFailed(status) || isSuccess(status) || isInProgress(status)) );
                } else {
                  return value.type === FeatureType.CAPABILITY;
                }
              })
            : null;
        const capabilities: any = featureWorkflow
          ? featureWorkflow.map((val) => {
              return getSubscribedFeatures(val, entitlement, hasAccount);
            })
          : [];
        setItems(capabilities);
        setIsLoaded(true);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    );
  }, [accountId, cloudProductSKU, entitlements,authorizationInstance]);
  useEffect(() => {
    if (userInfo) {
      setUserInfoObj({
        userId: userInfo?.sub,
        email: userInfo?.email,
      });
    }
  }, [userInfo]);
  const styles = {
    minWidth: '400px',
    minHeight: '240px',
  };
  return (
    <div className={isLoaded ? '' : 'loader'}>
      {props.type === 'store_view' ? (
        <ListFeatures
          type="PAGE"
          error={error}
          isLoaded={isLoaded}
          title={'Capabilities'}
          catalogueList={catalogueList}
          showConfirmDialog={showConfirmDialog}
          modalMessage={modalMessage}
          handleClose={handleClose}
          handleConfirm={handleUnsubscribeConfirm}
          clickHandlers={clickHandlers}
          styles={styles}
          source={Constants.ABSTRACT_PAGE_TAB_CAPABILITIES_ID}
        />
      ) : props.type === 'details' ? (
        <FeatureDetails
          showConfirmDialog={showConfirmDialog}
          modalMessage={modalMessage}
          handleClose={handleClose}
          handleConfirm={handleUnsubscribeConfirm}
          featuresList={catalogueList}
          showLoadingIcon={isLoaded}
          clickHandlers={clickHandlers}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default CapabilitiesStore;
