import styles from './info-graphics.module.scss';

/* eslint-disable-next-line */
export interface InfoGraphicsProps {
  image: any;
  title: string;
  desc?: any;
  children?: any;
}

export function InfoGraphics(props: InfoGraphicsProps) {
  return (
    <div className={styles['outerContainer']}>
      <div className={styles['innerContainer']}>
        <img
          className={styles['imageContainer']}
          src={props.image}
          alt="info-graphic"
        />
        <div>
          <h3>{props.title}</h3>
        </div>
        {props.desc && (
          <div>
            <p>{props.desc}</p>
          </div>
        )}
        {props.children && <div>{props.children}</div>}
      </div>
    </div>
  );
}

export default InfoGraphics;
