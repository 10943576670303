import {
  ModusDateInput,
  ModusDatePicker,
} from '@trimble-oss/modus-react-components';
import styles from './date-picker-wrapper.module.scss';
import { getSubOptions, OptionType } from '../../utils/dateCalculations';
import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface DatePickerWrapperProps {
  showDuration: boolean;
  showDurationInterval: boolean;
  selectedDuration?: string;
  options?: OptionType;
  subOptions?: OptionType;
  selectedDuratrionInterval?: string;
  formattedStartDate?: string;
  formattedEndDate?: string;
  handleSelectChange?: (event: any) => void;
  handleSubOptionChange?: (event: any) => void;
  handleStartDateChange: (event: any) => void;
  handleEndDateChange: (event: any) => void;
  handleDateClick?: (event: any) => void;
  maxDate?:string;
}

export function DatePickerWrapper(props: DatePickerWrapperProps) {
  const [SubOptions, setSubOptions] = useState<OptionType>([]);
  const {
    showDuration,
    showDurationInterval,
    formattedStartDate,
    options,
    subOptions,
    formattedEndDate,
    selectedDuration,
    selectedDuratrionInterval,
    handleDateClick,
    handleStartDateChange,
    handleEndDateChange,
    handleSelectChange,
    handleSubOptionChange,
    maxDate
  } = { ...props };
useEffect(() => {
  if(subOptions)
  setSubOptions(subOptions);
},[subOptions]);
  return (
    <div className={styles['date-picker-container']}>
      {/* Selected Option dropdown */}
      {showDuration && (
        <div>
          <label
            htmlFor="date-range"
            className={styles['date-picker-duration-label']}
          >
            Selected Duration:
          </label>
          <select
            className={styles['date-range']}
            value={selectedDuration}
            onChange={(event: any) => {
              const selected = event?.target.value;
              setSubOptions(getSubOptions(selected));
             if(handleSelectChange) handleSelectChange(selected);
            }}
          >
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      )}
      {showDurationInterval && (
        <div className={styles['date-picker-select']}>
          {/* Selected option dropdowns */}
          {(selectedDuration === 'month' ||
            selectedDuration === 'week' ||
            selectedDuration === 'day') && (
            <div className={styles['filter-container']}>
              <select
                className={styles['filter-select']}
                value={selectedDuratrionInterval}
                onChange={(event) => {
                  if(handleSubOptionChange) handleSubOptionChange(event?.target.value);
                }}
              >
                {SubOptions?.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
      )}

      {/* Date picker */}
      <ModusDatePicker>
        <ModusDateInput
          type="start"
          label="Start"
          format="dd-mm-yyyy"
          allowed-chars-regex="[\d-]"
          show-calendar-icon="true"
          placeholder="From"
          value={formattedStartDate}
          onValueChange={handleStartDateChange}
          onClick={handleDateClick}
          max={maxDate}
        ></ModusDateInput>
        <ModusDateInput
          type="end"
          label="End"
          format="dd-mm-yyyy"
          allowed-chars-regex="[\d-]"
          placeholder="To"
          show-calendar-icon="true"
          value={formattedEndDate}
          onValueChange={handleEndDateChange}
          onClick={handleDateClick}
          max={maxDate}
        ></ModusDateInput>
      </ModusDatePicker>
    </div>
  );
}

export default DatePickerWrapper;
