import { ModusSpinner } from '@trimble-oss/modus-react-components';
import styles from './circle-spinner.module.scss';

/* eslint-disable-next-line */
export interface CircleSpinnerProps {
  size: string;
  text: string;
}

export function CircleSpinner(props: CircleSpinnerProps) {
  return (
    <div className={styles['loader-wrapper']}>
      <ModusSpinner size={props.size || '48px'}></ModusSpinner>
      <div className="h3 text-primary mt-3">{props.text}</div>
    </div>
  );
}

export default CircleSpinner;
