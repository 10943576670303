import styles from './plan-info-card.module.scss';
import { ModusCard } from '@trimble-oss/modus-react-components';
import circleCheckImg from './assets/check-circle-outlined.svg';
import checkImg from './assets/check-bold_outline.svg';
import { ActionButton } from '../action-button/action-button';

/* eslint-disable-next-line */
export interface PlanInfoCardProps {
  details: PlanCardDetails;
  width: string;
  height: string;
  featureData: any;
  onActionClick: any;
  actions?: any;
}

export interface PlanCardDetails {
  title: string;
  subTitle: string;
  price: {
    unit: string;
    amount: number;
    currency: string;
  };
  image: string;
  includedFunctionalities: string[];
  isEnabled: boolean;
}

export function PlanInfoCard(props: PlanInfoCardProps) {
  //console.log('PlanInfoCardProps', props);
  const { details, width, height, onActionClick, featureData, actions } = props;
  const unit = details.price ? details.price?.currency === 'dollar' ? '$' : '€' :undefined;
  const amount = details.price ? 
    details.price.amount === 0 ? 'Free' : `${unit}${details.price.amount}`:undefined;
  return (
    <ModusCard
      width={width}
      height={height}
      aria-label="plan-detials-card"
      showCardBorder={true}
      showShadowOnHover={true}
      borderRadius="8px"
    >
      <div className={styles['container']}>
        <div className={styles['container-layout']}>
          <div className={styles['container-header-icons']}>
            <img src={props.details.image} alt="view mode" />
            {featureData.deploymentInfo?.status === 'SUBSCRIBE-SUCCESS' && (
              <img src={circleCheckImg} alt="check circle" />
            )}
          </div>
          <div className={styles['container-header-content']}>
            <div className={styles['container-header-title']}>
              {details.title}
            </div>
            <div className={styles['container-header-description']}>
              {details.subTitle}
            </div>
          </div>
          <div className={styles['priceContent']}>
            <div className={styles['priceContent-layout']}>
              {details.price && amount && <><div className={styles['priceContent-text']}>{amount}</div><div className={styles['priceContent-unit']}>
                / {details.price.unit}
              </div></>}
              {!details.price && <div className={styles['priceContent-comingSoonText']}>Pricing: Coming Soon</div>}
            </div>
          </div>
          <div className={styles['feature-container']}>
            {details.includedFunctionalities &&
              details.includedFunctionalities.map((feature: any, key) => {
                return (
                  <div className={styles['feature-content']} key={key}>
                    <img src={checkImg} alt="check" />
                    <div className={styles['feature-text']}>{feature}</div>
                  </div>
                );
              })}
          </div>
        </div>
        {details.isEnabled && <div className={styles['button-content']}>
          {actions &&
            actions.length > 0 &&
            actions.map((item: any) => {
              return (
                <ActionButton
                  type="button"
                  key={item.actionId}
                  style={{display:'block', width:'100%', 'padding-bottom': '10px'}}
                  isDisabled={item.isDisabled}
                  onClickHandler={() =>
                    onActionClick(item.clickHandlerLabel, item.actionId)
                  }
                  title={item.title}
                  status={item.status}
                  cardId={featureData.id}
                  cardTitle={featureData.title}
                  isOnload={item.isOnload}
                  actionId={item.actionId}
                  variant="outline-primary"
                />
              );
            })}
        </div>}
      </div>
    </ModusCard>
  );
}

export default PlanInfoCard;
