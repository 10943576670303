import { Link, useLocation, useParams } from 'react-router-dom';
import FeatureInfo, {
  FeatureDeviceSupport,
} from '../../components/feature-info/feature-info';
import FeaturePlanInfo from '../../components/feature-plan-info/feature-plan-info';
import styles from './feature-details.module.scss';
import { t } from 'i18next';
import { useNotificationContext } from '../../components/notification-provider/NotificationProvider';
import { ActionType, ModalPopup } from '@workflow/ui';
import { sendCustomEvent } from '../../assets/js_files/gs';
import { getFormData } from '../../API/featureCatalogueList';
import { useState } from 'react';
import StatusAlertComp from '../../components/status-alert-comp/status-alert-comp';
import { ModalCreate } from '../../components/modal-create/modal-create';
import { Chips } from '../../../../../libs/shared/graphics/src/lib/chips'
import OverviewListFeatures from '../../components/overview-list-features/overview-list-features';
import { RouteConstants } from '../../API/Utils/RouteConstants';
import { useNavigate } from 'react-router-dom';
import ListKeyFeatures from '../../components/list-key-features/list-key-features';
import { CircleSpinner } from '@workflow/shared/info-graphics';

/* eslint-disable-next-line */
export interface FeatureDetailsProps {
  featuresList: any;
  showLoadingIcon: any;
  modalMessage: any;
  showConfirmDialog: any;
  handleClose: any;
  handleConfirm: any;
  //source: string;
  clickHandlers?: any;
  showCreateDialog?: boolean;
  handleCreateClose?: any;
  handleCreateConfirm?: any;
  selectedActionId?: ActionType;
}

export function FeatureDetails(props: FeatureDetailsProps) {
  const planTitle = t('Common.Plan.Title');
  const planDescription = t('Common.Plan.Description');


  const {
    featuresList,
    modalMessage,
    showConfirmDialog,
    handleClose,
    handleConfirm,
    showCreateDialog,
    handleCreateClose,
    handleCreateConfirm,
    selectedActionId
  } = { ...props };
  const { alert, alertCloseHandler, showAlertText } = useNotificationContext();
  const navigate  =useNavigate();
  const location = useLocation();

  const getFeature = (featureId?: string) => {
    if (featureId) {
      return props.featuresList.filter((val: any) => {
        return val.id === featureId
      });
    } else {
      return {};
    }
  };

  const { id } = useParams();
  const filteredList = getFeature(id);
  const featureData: any = filteredList[0] ? filteredList[0] : {};
  const hasParams = !!featureData?.formTemplate?.length;
  //const [actionId, setActionId] = useState<ActionType | undefined>();
  const buttonActions = featureData?.detailActions ? featureData.detailActions.filter((action:any) => action.type === 'button' && action.show):undefined;
  
  const formData = getFormData(
    featureData?.deploymentInfo?.details,
    featureData.formTemplate
  );
  const getHandlerCallBack = (label: string | undefined) => {
    return label ? props.clickHandlers[label] : () => null;
  };

  const onActionClick = (label: string | undefined, id: ActionType) => {
    const callBack = getHandlerCallBack(label);
    //setActionId(id);
    callBack(id, featureData.id, featureData.title);
  };
  
  const onBackClick = () => {
    if (location.key !== 'default') {
      navigate(-1);
    }
  }

  if (featureData && featureData.feature?.name) {
    sendCustomEvent('viewMore', { name: featureData.feature?.name });
    const back_button_id =
      'back_button_' +
      featureData.feature?.name.toLowerCase().replace(' ', '_').replace(' ', '_');
    return (
      <div className={styles['container']}>
        <div className={styles['header-layout']}>
          <div className={styles['header-content']}>
            <div className={styles['header-text']}>{featureData.feature?.name}</div>
          </div>
          <div className={styles['navigation']}>
            <Link
              onClick={onBackClick}
              data-auto="backicon"
              id={back_button_id}
              className="cust-back-link" to={''}            >
              <div className="pt-0dot18">
                <i className="modus-icons" aria-hidden="true">
                  chevron_left
                </i>
              </div>
              <div className="back-link pl-2">{t('Common.Back')}</div>
            </Link>
          </div>
        </div>
        {alert && (alert.id === featureData.id || alert.type === 'warning') && (
          <div className="pt-4">
            <StatusAlertComp
              showAlertText={showAlertText}
              alert={alert}
              alertCloseHandler={alertCloseHandler}
            ></StatusAlertComp>
          </div>
        )}
        <FeatureInfo
          header={featureData.feature?.description}
          title={featureData.feature?.note}
          body={featureData.feature?.summary}
          imageUrl={featureData.feature?.productImageUrl}
          deviceSupport={featureData.feature?.supportedDevices}
        />
        <FeaturePlanInfo
          title={planTitle}
          description={planDescription}
          planDetails={featureData.feature?.plans}
          onActionClick={onActionClick}
          featureData={featureData.feature}
          actions={buttonActions}
        />        
        <Chips items={featureData.feature?.catagories} title={t('Common.Category')}/>
        <ListKeyFeatures keyFeatures={featureData.feature?.keyFeatures} />
        <OverviewListFeatures title={t('Common.OtherFeatures')+':'} items={featureData.feature?.additionalFeatures}/>
        <ModalPopup
          title={modalMessage ? modalMessage.title : ''}
          message={modalMessage ? modalMessage.message : ''}
          confirmBtnTitle={modalMessage ? modalMessage.confirmBtnTitle : ''}
          showConfirmDialog={showConfirmDialog}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
        { ( hasParams && showCreateDialog ) && (
          <ModalCreate
            actionId={selectedActionId}
            showConfirmDialog={showCreateDialog}
            featureData={featureData}
            formData={formData}
            handleClose={handleCreateClose}
            handleConfirm={handleCreateConfirm}
          ></ModalCreate>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <CircleSpinner size="48px" text={t('Common.Loading.Title')}></CircleSpinner>
      </div>
    );
  }
}

export default FeatureDetails;
