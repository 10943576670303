import { axiosInstance } from '../hooks/axiosInstanceHelper';
import { t } from 'i18next';
import { Constants } from './AppConstants';
import { ModusDataTableCellLink, ModusDataTableCellBadge } from '@trimble-oss/modus-web-components';

export interface InvitationRequest {
  firstName?: string;
  lastName?: string;
  inviteEmailAddress: string;
  role: string;
  inviterName: string;
  accountId?: string;
}

export interface EventDetail {
  _type       : string;
  display     : string;
  url         : string; 
  id          : string; 
  email       : string; 
  firstName   : string; 
  lastName    : string; 
  role        : string; 
  inviterName : string; 
  accountId   : string; 
}

export interface ResendInvitationRequest {
  invitationId : string
}

export interface ICSUserTableData {
  status: any;
  name: string;
  email: any;
  role: string;
  uuid?: string;
  expiryDate?: string;
  invitedOn?: string;
  _id?: string;
  _selected?: boolean;
  [key: string]: any;
}

export interface responseData  {
  data: any[];
  meta: {
    pageNumber: number;
    pageSize: number;
    resultCount: number;
    hasMore: boolean;
  };
};

export interface icsDBUserData {
  id                : string,
  accountId         : string,
  uuid              : string,
  firstName         : string,
  lastName          : string,
  inviteEmailAddress : string,
  invitationData  : {
    role            : string,
    invitationId    : string,
    inviterName     : string,
    status          : string,
    statusDescription : string,
    expiryAtDateTime  : string,
  },
  lifeState       : string,
  createdAtDateTime : string,
}

export async function sendInvitation(request: InvitationRequest): Promise<any> {
  const result = await axiosInstance().request({
    method: 'POST',
    url: `/users/v1/invitation`,
    data: request,
  });
  return result && result.data ? result.data : undefined;
}

export async function sendInvitations(request: {invitations:InvitationRequest[]}): Promise<any> {
  const result = await axiosInstance().request({
    method: 'POST',
    url: `/users/v1/invitations`,
    data: request,
  });
  return result && result.data ? result.data : undefined;
}

export async function invitationResend(request: ResendInvitationRequest): Promise<any> {
  const url = `/users/v1/invitation/resend`;
  const result = await axiosInstance().request({
    method: 'POST',
    url: url,
    data: request,
  });
  return result && result.data ? result.data : undefined;
}

export async function getPendingInvitations(accountId: string, pageIndex?: number, pageSize?: number, searchText?: string): Promise<any> {
  const responseData: responseData = {
    data: [],
    meta: {
      pageNumber: 1,
      pageSize: 0,
      resultCount: 0,
      hasMore: false,
    },
  };

  const params: any = {
    lifeState           : 'ACTV',
    'invitation.status' : 'pending',
    accountId           : accountId
  };

  // setting pagination construction
  if(pageSize){    
    if(pageIndex) {
      params.pageIndex = pageIndex - 1;
    }
    params.pageSize  = pageSize;
  }else{
    console.log(`getPendingInvitations : ${pageIndex} and ${pageSize} are not provided. Setting default values.`)
  }

  // saerch text construction
  if(searchText && searchText.length > 2){
    params.email = searchText;
  }

  const result = await axiosInstance().request({
    method: 'GET',
    url: `/users/v1`,
    params: params,
  });


  //console.log('getPendingInvitations : ' + JSON.stringify(result.data));
  if(result && result.data && result.data.items) {
    responseData.data = convertIntoTableData(result.data.items);
    responseData.meta.hasMore = false;    
    responseData.meta.pageNumber = result.data.pageIndex + 1;
    if(pageSize) {
      responseData.meta.pageSize = pageSize;
    }    
    responseData.meta.resultCount = result.data.totalCount;
  }
  //console.log('responseData : ' + JSON.stringify(responseData));
  return responseData;
}

function convertTCPRoleToIcsRole(profileRole: string) {
  let icsRole = t('Users.Tables.Role.User');
  if (profileRole === Constants.USER_TCP_ROLE_ADMIN_TEXT) {
    icsRole = t('Users.Tables.Role.Admin');
  } else if (profileRole === Constants.USER_TCP_ROLE_OWNER_TEXT) {
    icsRole = t('Users.Tables.Role.Owner');
  } else if (profileRole === Constants.USER_TCP_ROLE_READ_ONLY_TEXT) {
    icsRole = t('Users.Tables.Role.Reader');
  } else if (profileRole === Constants.USER_TCP_ROLE_MANAGER_TEXT) {
    icsRole = t('Users.Tables.Role.Manager');
  }    
  return icsRole + " \n";
}


function convertIntoTableData(usersData: icsDBUserData[]) {
  const tableData: ICSUserTableData[] = [];
  usersData.forEach((user) => {
    const role = convertTCPRoleToIcsRole(user.invitationData.role);
    const currentDay = new Date().getTime();
    const expiryDay = new Date(user.invitationData.expiryAtDateTime).getTime();
    const isExpired = currentDay > expiryDay;
    const status = isExpired ? t('Users.Tables.Action.Expired') : t('Users.Tables.Action.Pending');
    const OneDayBoundary = currentDay - (24 * 60 * 60 * 1000);
    const resendExpiryDay = new Date(user.createdAtDateTime).getTime();
    const isResendEligible = resendExpiryDay < OneDayBoundary;
    const tableRow: ICSUserTableData = {
      status: status,
      name: user.firstName + ' ' + user.lastName,
      //name: user.firstName,
      email: user.inviteEmailAddress,
      role: role,
      uuid: user.uuid,
      expiryDate: new Date(user.invitationData.expiryAtDateTime).toDateString().substring(4),
      _id: user.uuid,
      _selected: false,
      action : {
        _type: 'link',
        display: isExpired ? t('Users.Tables.Action.Reinvite') : t('Users.Tables.Action.Resend'),
        url: '', 
        id: user.invitationData.invitationId,
        email: user.inviteEmailAddress,
        firstName: user.firstName,
        lastName: user.lastName,
        name: user.firstName + ' ' + user.lastName,
        role: user.invitationData.role,
        inviterName: user.invitationData.inviterName,
        accountId: user.accountId,
      } as ModusDataTableCellLink

      // following code will be enabled for blocking 24hrs, sicne API is updating the DB on resend, so we are commenting this code
      // action : isResendEligible ? {
      //   _type: 'link',
      //   display: isExpired ? t('Users.Tables.Action.Reinvite') : t('Users.Tables.Action.Resend'),
      //   url: '', 
      //   id: user.invitationData.invitationId,
      //   email: user.inviteEmailAddress,
      //   firstName: user.firstName,
      //   lastName: user.lastName,
      //   role: user.invitationData.role,
      //   inviterName: user.invitationData.inviterName,
      //   accountId: user.accountId,
      // } as ModusDataTableCellLink  : t('Users.Invitation.Sent')
    };    
    tableData.push(tableRow);    
  });
  //console.log('convertIntoTableData : ' + JSON.stringify(tableData));
  return tableData;
}


export function constructDataAsLink(UserData: icsDBUserData, isExpired: boolean){ 
  return {
    _type: 'link',
    display: isExpired ? t('Users.Tables.Action.Reinvite') : t('Users.Tables.Action.Resend'),
    url: '', 
    id: UserData.invitationData.invitationId,
    email: UserData.inviteEmailAddress,
    firstName: UserData.firstName,
    lastName: UserData.lastName,
    role: UserData.invitationData.role,
    inviterName: UserData.invitationData.inviterName,
    accountId: UserData.accountId,
  } as ModusDataTableCellLink;
}

export function constructDataAsBadge(displayText: string, type: string, id: string) {
  let action;
  if (type && type === 'warning') {
    action = {
      _type: 'badge',
      text: displayText,
      color: 'warning',
      type: 'text',
      id: id,
    };
  } else if (type && type === 'danger') {
    action = {
      _type: 'badge',
      text: displayText,
      color: 'warning',
      type: 'text',
      id: id,
    };
  } else {
    action = {
      _type: 'badge',
      text: displayText,
      color: 'success',
      type: 'text',
      id: id,
    };
  }
  return action;
}
