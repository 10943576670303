import { t } from 'i18next';
import { useState } from 'react';
import { InviteUserAPI } from '../../API/inviteUser';
import { emailRegex } from '../../../../../libs/ui/src/utils/Common';
import { globalConfig } from '../../API/Utils/enivronment-util';
import Inviteuser from '../inviteuser/inviteuser';
import styles from './comingsoon-comp.module.scss';

/* eslint-disable-next-line */
export interface ComingsoonCompProps {
  title?: string;
  message?: string;
  description1?: string;
  description2?: string;
  inviteType?: string;
  source: string;
}

export enum StatusTypes {
  status_done = 'done', status_none = 'none', status_failed = 'failed'
}

export function ComingsoonComp(props: ComingsoonCompProps) {
  const { title, message, description1, description2, inviteType, source } = { ...props };
  const [submitStatus, setSubmitStatus] = useState<StatusTypes>(StatusTypes.status_none);
  const [errorMessage, setErrorMessage] = useState('');

  const submitInputs = (email: string, content: string, userInfoObj: any, sourceArg: string, type: string | undefined) => {
    console.log('submit clicked ' + type);
    if (type && type === 'type1') {
      if (!email) {
        setErrorMessage(t("Common.Required.Error.Message"));
      } else if (email && !emailRegex.test(email)) {
        setErrorMessage(t("Common.InvalidMail.Error.Message"));
      } else {
        setErrorMessage('');
        const emailList = globalConfig.config.marketingEmailCDE;
        const emailArr = emailList?.split(',');
        emailArr?.forEach(email => {
          InviteUserAPI
            .mailMarketingTeam(email, getEmailContent(userInfoObj, content, 'data-marketing'))
            .then((res) => console.log('feedback submit response ' + res ? res.status : ''))
            .catch((err) => console.log('feedback submit error ' + err ? JSON.stringify(err) : err));                 
        });
        InviteUserAPI
          .mailUser(userInfoObj.email, getEmailContent(userInfoObj, content, 'data-user'))
          .then((res) => console.log('feedback submit response ' + res ? res.status : ''))
          .catch((err) => console.log('feedback submit error ' + err ? JSON.stringify(err) : err));

        setSubmitStatus(StatusTypes.status_done);
      }
    } else if (type && type === 'type2') {
      if (!email || !content) {
        setErrorMessage(t("Common.Required.Error.Message"));
      } else if (email && !emailRegex.test(email)) {
        setErrorMessage(t("Common.InvalidMail.Error.Message"));
      } else {
        setErrorMessage('');
        const emailList = globalConfig.config.marketingEmailAPI;
        const emailArr = emailList?.split(',');
        emailArr?.forEach(email => {
          InviteUserAPI
            .mailMarketingTeam(email, getEmailContent(userInfoObj, content, 'api-marketing'))
            .then((res) => console.log('feedback submit response ' + res ? res.status : ''))
            .catch((err) => console.log('feedback submit error ' + err ? JSON.stringify(err) : err));          
        });

        InviteUserAPI
          .mailUser(userInfoObj.email, getEmailContent(userInfoObj, content, 'api-user'))
          .then((res) => console.log('feedback submit response ' + res ? res.status : ''))
          .catch((err) => console.log('feedback submit error ' + err ? JSON.stringify(err) : err));
        
        setSubmitStatus(StatusTypes.status_done);
      }
    }
    else if (type && type === 'type3') {
      if (!email ) {
        setErrorMessage(t("Common.Required.Error.Message"));
      } else if (email && !emailRegex.test(email)) {
        setErrorMessage(t("Common.InvalidMail.Error.Message"));
      } else {
        setErrorMessage('');
        const emailList = globalConfig.config.marketingEmailFeedBack;
        const emailArr = emailList?.split(',');
        emailArr?.forEach(email => {
          InviteUserAPI
            .mailMarketingTeam(email, getEmailContent(userInfoObj, content, 'feedback-marketing'))
            .then((res) => console.log('feedback submit response ' + res ? res.status : ''))
            .catch((err) => console.log('feedback submit error ' + err ? JSON.stringify(err) : err));
        });

        InviteUserAPI
          .mailUser(userInfoObj.email, getEmailContent(userInfoObj, content, 'feedback-user'))
          .then((res) => console.log('feedback submit response ' + res ? res.status : ''))
          .catch((err) => console.log('feedback submit error ' + err ? JSON.stringify(err) : err));
        
        setSubmitStatus(StatusTypes.status_done);
      }
    }

  }

  const getEmailContent = (userDetails: any, feedback: string, type: string ) => {
    const emailData = {
      content:'',
      subject:'',
      sender:'', 
      userDetails: userDetails
    };
    switch (type) {
      case 'api-marketing':
        emailData['content'] = `${userDetails.name} (${userDetails.email}) has submitted some feedback from the API section of the Construction Cloud Console.<br>
            <p><span>&nbsp;</span></p>
            <b>Feedback:</b>
            <br>
            ${feedback}`;
        emailData['subject']='User Feedback';
        emailData['sender']='Industry Clouds Team';
        break;
      case 'data-marketing':
        emailData['content']  = `${userDetails.name} (${userDetails.email}) has clicked <i>I express interest</i> from the Connected Data Environment section.`;
        emailData['subject']='User Feedback';
        emailData['sender']='Industry Clouds Team';
        break;
      case 'feedback-marketing':
        emailData['content']  = `${userDetails.name} (${userDetails.email}) has clicked <i>I express interest</i> from the Feedback Catalog section.`;
        emailData['subject']='User Feedback';
        emailData['sender']='Industry Clouds Team';
        break;
      case 'api-user':
        emailData['content']  = `We are delighted that you are interested in our Construction Cloud APIs.       
        Our team will reach out to you soon to help you get started.`;
        emailData['subject']='Thank you for the feedback';
        emailData['sender']='Trimble Construction Cloud Team';
        break;      
      case 'data-user':
        emailData['content']  = `We are delighted that you are interested in our Connected Data Environment. 
        Our team will reach out to you soon to help you get started.`;
        emailData['subject']='Thank you for the feedback';
        emailData['sender']='Trimble Construction Cloud Team';
        break;
      case 'feedback-user':
        emailData['content']  = `We have received your valuable feedback. Our team will get in touch with you soon.`;
        emailData['subject']='Thank you for the feedback';
        emailData['sender']='Trimble Construction Cloud Team';
        break;
    }
    return emailData;
  }

  return (
    <div className={styles['container'] + " d-flex flex-column"}>
      <div className="d-flex flex-column justify-content-flexstart pb-4">
        <p>
          {description1 ? description1 : ' '}
        </p>
        <p>
          {description2 ? description2 : ' '}
        </p>
      </div>
      <div className={styles['coming-soon-content']}>
        <div className={styles['coming-soon-item']+' d-flex flex-column align-items-flexstart pt-3'}>
          <div className='d-flex align-items-center'>
            <div><img
              src="images/comingsoonbg.png"
              alt="icon"
              height={'32px'}
              width={'32px'}
            /> </div>
            <div className='cust-display-heading pl-3'>{(message && title) ? title : 'Coming Soon!'}</div>
          </div>
          <div className='pt-3'>
            {
              message ?
                <div>
                    {message}
                  {(inviteType === 'type2') ?
                    <p>
                      {t("InviteUser.Comments.PlaceHolder")}
                    </p> : ''}
                </div>
                : `In the very near future, we’ll be launching ${title} Catalog. 
        Add your email below and be among the first to know when we officially launch.`
            }
          </div>
          <div className=''>
            <Inviteuser inviteType={inviteType} source={source} onClickHandler={submitInputs} status={submitStatus} errorMessage={errorMessage} />
          </div>
        </div>
        </div>
      </div>
  );
}

export default ComingsoonComp;
