import { format } from 'date-fns';
import { Entryvalues } from '../types/DataRepresentationTypes';

export function processAndFilterData(
  data: Entryvalues[],
  selectedDimension: string,
  startDate: Date,
  endDate: Date
) {
  const groupedData = data.reduce((result: any, entry: Entryvalues) => {
    const { featureId, date, quantity, dimension } = entry;
    const dateValue =
      typeof date === 'string' ? date : (date as { date: string }).date;
    const dateval: string = format(new Date(dateValue), 'yyyy-MM-dd') || '';
    if (!result[featureId]) {
      result[featureId] = {
        accountId: entry.accountId,
        featureId,
      };
    }

    if (!result[featureId][dateval]) {
      result[featureId][dateval] = {
        dateval,
        [dimension]: quantity,
      };
    } else {
      result[featureId][dateval][dimension] = quantity;
    }

    return result;
  }, {} as any);

  const finalResult = Object.values(groupedData).map((entry: any) => {
    const { accountId, featureId, ...dates } = entry;
    return {
      accountId,
      featureId,
      ...Object.values(dates),
    };
  });

  const validEntries =
    Array.isArray(finalResult) && finalResult.length > 0
      ? finalResult[0]
      : undefined;

  const dataArray = validEntries ? Object.values(validEntries) : [];

  const validEntriesFinal = dataArray.filter((entry: any) => {
    return (
      entry &&
      typeof entry === 'object' &&
      entry.dateval &&
      typeof entry.dateval === 'string'
    );
  });

  const labels = validEntriesFinal.map((entry: any) => entry.dateval);
  const datavals = validEntriesFinal
    .filter(
      (entry: any) => entry.dateval !== undefined && entry.dateval !== null
    )
    .map((entry: any) => Number(entry[selectedDimension]));

  return { labels, datavals };
}
