import React, { useEffect, useState } from 'react';
import { Authorization } from '@workflow/authorization';
import { useEntitlements } from '../../components/app-view';
import { get_is_admin } from '../../API/TAMRole';
import { CircleSpinner } from '@workflow/shared/info-graphics';
import { t } from 'i18next';
import { Navigate } from 'react-router';
import { RouteConstants } from '../../API/Utils/RouteConstants';
import { isTruthy } from '@workflow/ui';
import { useNotificationContext } from '../../components/notification-provider/NotificationProvider';
import ErrorPage from '../../pages/inviterror/error-page';
/* eslint-disable-next-line */
interface AuthProps {
  authorizationInstance: Authorization | null;
}

export const AuthorizationContext = React.createContext<AuthProps>({
  authorizationInstance: null,
});

function AuthorizationProvider(props: any) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [navigateTo, setNavigate] = useState<string>();
  const { accountId } = useEntitlements();
  const [authInstance, setAuthInstance] = useState<Authorization>(
    new Authorization()
  );
  const { setHasError } = useNotificationContext();
  const setErrorInContext = () => {
    if (setHasError) setHasError(true);
  };
  useEffect(() => {
    if (accountId) {
      get_is_admin(accountId).then(
        (response) => {
          const isAdmin =
            response.data && response.data.decision.result
              ? isTruthy(response.data.decision.result)
              : false;
          const roleName = isAdmin ? 'admin' : 'product_user';
          authInstance.init(roleName);
          setAuthInstance(authInstance);
          setIsLoaded(true);
        },
        (error) => {
          console.log(error);
          if (error.status === 403) {
            setNavigate(RouteConstants['authError']);
            setErrorInContext();
          } else {
            setErrorInContext();
            setError(true);
          }
        }
      );
    } else {
      setNavigate(RouteConstants['noSubscription']);
      setErrorInContext();
    }
  }, [accountId]);
  if (navigateTo) {
    return <Navigate to={navigateTo} />;
  } else if (error) {
    return (
      <ErrorPage
        pageType={RouteConstants['commonPageError']}
        message={t('ErrorPage.UnknownError.desc')}
        showSignOut={true}
      />
    );
  } else if (!isLoaded) {
    return (
      <CircleSpinner
        size="48px"
        text={t('Common.Loading.Message')}
      ></CircleSpinner>
    );
  } else {
    const authContext = {
      authorizationInstance: authInstance,
    };
    return (
      <AuthorizationContext.Provider value={authContext}>
        {props.children}
      </AuthorizationContext.Provider>
    );
  }
}
const useAuthorizationHandler = () => {
  return React.useContext(AuthorizationContext);
};
export { useAuthorizationHandler, AuthorizationProvider };
