import { useState, useEffect } from 'react';

import {
  Controller,
  ControllerRenderProps,
  useWatch,
  useFormContext,
} from 'react-hook-form';
import DateTimePicker from 'react-datetime-picker';
import { t } from 'i18next';
import moment from 'moment';
import { getCurrentTimeWithDefaultIncrement } from '@workflow/ui';
import {
  ModusSelect,
  ModusTextInput,
} from '@trimble-oss/modus-react-components';
import { StylesConfig, MultiValue, ActionMeta } from 'react-select';
import Select from 'react-select';
import styles from './ui-form-input.module.scss';
import { Form } from 'react-bootstrap';

export interface UiFormInputProps {
  label: string;
  type?: string;
  name: string;
  required?: boolean;
  defaultValue?: any;
  minLength?: number;
  maxLength?: number;
  min?: string;
  max?: string;
  pattern?: RegExp;
  // eslint-disable-next-line @typescript-eslint/ban-types
  validate?: any;
  disabled?: boolean;
  options?: any[];
  message?: string;
  row?: number;
  size?: 'large' | 'medium';
  watch?: any;
  description?: string;
  onChange?: any;
  isControlled?: boolean;
  helperText?: string;
  style?:any;
  labelClass?:string;
}

export interface FieldOption {
  readonly value: string;
  readonly label: string;
  readonly helperText?: string;
  readonly color?: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}


export function UiFormInput(props: UiFormInputProps) {

  
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const format = 'dd-MM-yy h:mm a';
  const [options, setOptions] = useState([]);
  const watchValue = props.watch
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useWatch({
        control,
        name: props.watch?.key,
      })
    : undefined;
  useEffect(() => {
    if (props.watch) {
      props.watch?.value.forEach((val: any) => {
        if (val === watchValue) {          
          setOptions(props.watch.options[val]);
        }
      });
    }
  }, [watchValue]);

  const getRegisterOptions = () => {
    return {
      required: props.required
        ? t('Workflows.Add.IsRequired', {
            field: props.label,
          })
        : undefined,
      pattern: props.pattern
        ? {
            value: props.pattern,
            message: props.message
              ? props.message
              : t('Workflows.Add.InvalidValue'),
          }
        : undefined,
      validate: props.validate,
      disabled: props.disabled,
    };
  };
  
  const getRenderField = ({ field }: { field: ControllerRenderProps }) => {
    switch (props.type) {
      case 'date-time-picker': {
        const value = field.value ? new Date(field.value) : undefined;
        return (
          <div className="date-time-picker">
            <DateTimePicker
              {...field}
              value={value}
              format={format}
              className={'date-time-picker-component'}
              disableClock={true}
              clearIcon={null}
              onChange={(e: any) => {
                const selected = e;
                field.onChange(selected);
                if (
                  moment(selected).isSameOrAfter(
                    moment(getCurrentTimeWithDefaultIncrement())
                  )
                ) {
                  setValue(props.name, selected);
                }
              }}
            />
          </div>
        );
      }
      case 'select': {
        const optionValue = options.length ? options : props.options;
        return (
          <Form.Select
          {...field}
          isInvalid={!!errors[props.name]}
          disabled={optionValue?.length ? props.disabled : true}
          as="select"
          className={styles['form-control']}
        >
          <option key="select" value="" className="d-none">
            {t('Workflows.Add.Select')}
          </option>
          {optionValue &&
            optionValue.map((option) => {
              return typeof option === 'string' ? (
                <option key={option} value={option}>
                  {option}
                </option>
              ) : (
                <option key={option.id} value={option.id}>
                  {option.label}
                </option>
              );
            })}
        </Form.Select>
        );
      }
      default:
        return <div></div>;
    }
  };

  const multiSelectFieldStyles: StylesConfig<FieldOption, true> = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: ' 0.75rem;',
        fontFamily: 'Open Sans'
      };
   },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      fontSize: ' 0.75rem;',
      fontFamily: 'Open Sans'
    })
  };

  const getField = () => {
    switch (props.type) {
      case 'input':
        return (
          <ModusTextInput
            {...register(props.name, getRegisterOptions())}
            helperText={props.helperText}
            size={props.size || 'medium'}
          />
        );
      case 'password':
          return (
            <ModusTextInput
              {...register(props.name, getRegisterOptions())}
              helperText={props.helperText}
              size={props.size || 'medium'}
              type={'password'}
              disabled={props.disabled}
            />
          );  
      case 'webinput':
        return (
          <ModusTextInput
            {...register(props.name, getRegisterOptions())}
            helperText={props.helperText}
            size={props.size || 'medium'}
            disabled={props.disabled}
          />
        );
      case 'webselect': {
        return (
          <ModusSelect
            id={props.name}
            {...register(props.name, getRegisterOptions())}
            size={props.size || 'medium'}
            helperText={props.defaultValue?.helperText}
            optionsDisplayProp="label"
            options={props.options}
            value={props.defaultValue}
            onValueChange={(e) => {
              if(props.onChange)
              props.onChange(e);
            }}
          />
        );
      }
      case 'multiselect': {
        return (
          <Select
            id={props.name}
            {...register(props.name, getRegisterOptions())}
            //closeMenuOnSelect={false}
            defaultValue={props.defaultValue}
            styles={multiSelectFieldStyles} 
            isDisabled={props.disabled}
            isMulti
            options={props.options}
            onChange={function (newValue: MultiValue<FieldOption>, actionMeta: ActionMeta<FieldOption>): void {
              setValue(props.name, newValue);
              if(props.onChange){
                props.onChange(newValue);
              }              
            } } 
            />
        );
      }
      case 'textarea':
        return (
          <ModusTextInput
            {...register(props.name, getRegisterOptions())}
            helperText={props.helperText}
            size={props.size || 'large'}
            disabled={props.disabled}
          />
        );
      default:
        return (
          <ModusTextInput
            {...register(props.name, getRegisterOptions())}
            helperText={props.helperText}
            size={props.size || 'medium'}
            disabled={props.disabled}
          />
        );
      case 'radio':
        return (
          <div className={`${styles['radio-button-group']}`} style={props.style}>
            {props.options &&
              props.options.map((option) => {
                return (
                  <div>
                    {typeof option !== 'string' && (
                      <div className={styles['radio-button']}>
                        <div className={styles['radio-button-style']}>
                          <input
                            type="radio"
                            id={option.id}
                            value={option.id}
                            {...register(props.name, getRegisterOptions())}
                            onChange={(e) => setValue(props.name, e.target.value)}
                          />
                        </div>
                          <label htmlFor={option.id} className={styles['radio-button-label']}>
                            {option.label}
                          </label>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>        );
    }
  };
  return (
    <div className={styles['form-group']}>
      {props.label && <label>
        {props.label}{' '}
        {props.required ? <span className="text-danger"> * </span> : ''}{' '}
      </label>}
      {props.isControlled && (
        <Controller
          name={props.name}
          control={control}
          rules={{
            required:
              props.required === undefined || props.required === null
                ? undefined
                : {
                    value: props.required,
                    message: t('Workflows.Add.IsRequired', {
                      field: props.label,
                    }),
                  },
            pattern: props.pattern
              ? {
                  value: props.pattern,
                  message: props.message
                    ? props.message
                    : t('Workflows.Add.InvalidValue'),
                }
              : undefined,
            validate: props.validate,
          }}
          render={getRenderField}
        />
      )}
      {!props.isControlled && <>{getField()}</>}
      <small className={styles['text-muted']}>
        {props.description}
      </small>
      {!props.isControlled && (
        <div className="invalid-field">
          {errors[props.name]?.type === 'required' &&
            t('Workflows.Add.IsRequired', { field: props.label })}
          {errors[props.name]?.type !== 'required' &&
            errors[props.name]?.message}
        </div>
      )}
      {props.isControlled && errors[props.name]?.type === 'invalidDate' && (
        <div className="invalid-field">
          <span>{errors[props.name]?.message} </span>
        </div>
      )}
      {props.isControlled && errors[props.name]?.type === 'required' && (
        <div className="invalid-field">
          <span>{errors[props.name]?.message} </span>
        </div>
      )}
      </div>
  );
}
