import { t } from 'i18next';
import { useEffect, useState } from 'react';
import ListFeatures from '../../components/list-features/list-features';
import CommingSoonStore from '../comming-soon-store/comming-soon-store';
import styles from './apis-store.module.scss';
import { fetchApiProducts } from '../../API/ApiProducts';
import { FeatureListItem } from '../../models/FeatureListItem';
import { ActionType } from '../../../../../libs/ui/src/action-button/action-props';
import { globalConfig } from '../../API/Utils/enivronment-util';
import { isTruthy } from '../../../../../libs/ui/src/utils/Common';
import { useEntitlements } from '../../components/app-view';
import { Constants } from '../../API/AppConstants';
export interface ApisStoreProps {
  type: 'store_view' | 'my_subscriptions';
  title: string;
}

export function ApisStore(props: ApisStoreProps) {
  const getMessage = () => (
    <div className={styles['comingSoonMsg']}>
      <p>{t('API.Description.Message')}</p>
    </div>
  );
  const isShowTDPPortal = isTruthy(globalConfig.config.tdpPortalShow);
  const [products, setProducts] = useState<FeatureListItem[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<any | null>(null);
  const { entitlements, accountId } = useEntitlements();
  const cloudProductSKU = globalConfig.config?.productName;

  const cardStyle = {
    minWidth: '280px',
    minHeight: '230px',
  };
  useEffect(() => {
    const hasAccount = !!accountId;
    const entitlement = entitlements
      ? entitlements.find(
          (item) => item.skuCode === cloudProductSKU && item.status === 'active'
        )
      : undefined;
    fetchApiProducts().then(
      (result) => {
        //console.log(result);
        if (result?.length) {
          const isSubscribed = hasAccount && !!entitlement;

          const list = result.map((item) => {
            let urlSegment;
            let tags='';
            if (item.type === 'api') {
              urlSegment = 'apis';
              tags = 'HTTP / REST API';
            } else if (item.type === 'event') {
              urlSegment = 'events';
              tags = 'Async Event';
            }
            const id = item.id ? item.id : item.uuid;
            return {
              title: item.displayName,
              info: item.description,
              id: id,
              crtdDt: item.createdAtDateTime,
              actions: isSubscribed
                ? [
                    {
                      isDisabled: false,
                      type: 'link',
                      title: t('Common.ViewMore'),
                      to: id
                        ? `${globalConfig.config.consoleCloudLaunchURL}/${urlSegment}/${id}`
                        : '#',
                      actionId: ActionType.VIEW_MORE,
                      show: true,
                      target: '_blank',
                    },
                  ]
                : [],
              tags: [tags],
            };
          }).sort((a, b) => a.title.localeCompare(b.title, 'en', { numeric: true }));
          setProducts(list);
          setIsLoaded(true);
        }
      },
      (error) => {
        setError({
          message: t("Common.api.failure"),
          type: "danger",
          status: "Failed",
          id: "product-failure"
        });
      }
    );
  }, [accountId, cloudProductSKU, entitlements]);
  return isShowTDPPortal ? (
    <div className={isLoaded ? '':'loader'}>
      <ListFeatures
        title={t('API.Title')}
        type="PAGE"
        error={error}
        isLoaded={isLoaded}
        catalogueList={products}
        styles={cardStyle}
        source={Constants.PAGE_DEVELOPER_TOOLS_ID}
      />
    </div>
    
  ) : (
    <CommingSoonStore
      title={t('API.Title')}
      subtitle={t('API.Heading.Description')}
      comingSoonTitle={t('Common.BetaInvite.Title')}
      comingSoonMsg={getMessage()}
      inviteType={'type2'}
      source={'Cloud Console APIs'}
    ></CommingSoonStore>
  );
}

export default ApisStore;
