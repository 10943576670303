import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import { logout,AuthenticationAPI } from '../API/authentication';
import { globalConfig, isDevelopment } from '../API/Utils/enivronment-util';
const axiosMockInstance = axios.create();
export const requestSuccessHandler = async (config: any) => {
  const regexStr = globalConfig.config?.urlRegexStr
    ? globalConfig.config?.urlRegexStr
    : '';
  const regex = new RegExp(regexStr);
  if (regex.test(config.baseURL)) {
    const tokenInfo: any = ((authInfo) =>
      authInfo ? JSON.parse(authInfo) : null)(localStorage.getItem('authInfo'));
    let token;
    const isExpired = tokenInfo?.expiresAt
      ? !(tokenInfo.expiresAt - Date.now() > 30000)
      : false;
      console.log("isExpired",isExpired,tokenInfo.expiresAt,(tokenInfo.expiresAt - Date.now() > 30000))
    if (isExpired && !config?.sent) {
      config.sent = true;
      console.log("refresh token")
      const result = await AuthenticationAPI.refreshAccessToken();
      console.log("access token",result);
      if (result?.access_token) {
        token = result.access_token;
      } else {
        return Promise.reject();
      }
    } else {
      token = tokenInfo ? tokenInfo['access_token'] : undefined;
    }
    const bearerToken = `Bearer ${token}`;
    if (config.headers) {
      config.headers.Authorization = undefined;
      config.headers.Authorization = bearerToken;
    } else {
      config.headers = {
        Authorization: bearerToken,
      };
    }
    if(config.data && config.data && config.data.auth == '') {
      config.data.auth = bearerToken;
    }
  }
  return config;
};
export const requestErrorHandler = (error: any) => {
  return Promise.reject(error);
};

export const responseSuccessHandler = (response: any) => {
  return response;
};
export const responseErrorHandler = async (error: any) => {
  let errorData;
  const config = error?.config;
  
  if (error?.response?.status === 401) {
    if (!config?.sent) {
      config.sent = true;
      const result = await AuthenticationAPI.refreshAccessToken();

      if (result?.access_token) {
        config.headers.Authorization = undefined;
        config.headers.Authorization = `Bearer ${result?.access_token}`;
      } else {
        logout();
      }
    } else {
      logout();
    }
    return axios(config);
  } else {
    errorData = {
      status: error.response.status,
      statusMessage: error.response?.statusMessage
        ? error.response?.statusMessage
        : error.response?.statusText,
      data: error.response.data,
    };
  }
  return Promise.reject(errorData);
};
const baseUrl = isDevelopment
  ? `${process.env['NX_API_BASE_URL']}${process.env['NX_API_URL_PATTERN']}`
  : 'APP.API.BASE.URL' + 'APP.API.URL.PATTERN';
const axiosLiveInstance = axios.create({
  baseURL: baseUrl,
});

axiosLiveInstance.interceptors.request.use(
  requestSuccessHandler,
  requestErrorHandler
);
axiosLiveInstance.interceptors.response.use(
  responseSuccessHandler,
  responseErrorHandler
);
export const axiosMockAdapterInstance = new AxiosMockAdapter(
  axiosMockInstance,
  { delayResponse: 100 }
);

export const axiosInstance = (isMock = false) => {
  return isMock ? axiosMockInstance : axiosLiveInstance;
};
