import axios from 'axios';
import { globalConfig, isDevelopment } from '../API/Utils/enivronment-util';
const axiosInstance = axios.create({
  baseURL: isDevelopment ? process.env['NX_IDENTITY_URL'] : 'APP.IDENTITY.URL',
});

export const getBasicAuth = () => {
  return { Authorization: 'Basic ' + globalConfig.config?.basicAuth };
};
export const logout = () => {
  const redirectUri = globalConfig.config?.appRedirectURL + '/#/logout';
  window.location.replace(redirectUri);
};

export function identityLogout(id_token: string) {
  const url =
    globalConfig.config?.identityURL + '/oauth/logout?post_logout_redirect_uri=' + globalConfig.config?.appRedirectURL +'&id_token_hint=' +
    id_token;
  window.location.href = url;
}

export const AuthenticationAPI = {
  getLoginURL() {
    return (
      globalConfig.config?.identityURL +
      '/oauth/authorize?client_id=' +
      globalConfig.config?.clientID +
      '&response_type=code&scope=openid ' +
      globalConfig.config?.identityAppName + ' ' +globalConfig.config?.consoleCloudAppName +
      '&redirect_uri=' +
      globalConfig.config?.appRedirectURL +
      '/auth'
    );
  },
  getAccessToken(code: string | null) {
    const request =
      'grant_type=authorization_code&redirect_uri=' +
      globalConfig.config?.appRedirectURL +
      '/auth&client_id=' +
      globalConfig.config?.clientID +
      '&code=' +
      code;
    return axiosInstance.request({
      method: 'POST',
      url: `/oauth/token`,
      headers: getBasicAuth(),
      data: request,
    });
  },
  tokenRevoke() {
    const authData = ((auth) => (auth ? JSON.parse(auth) : null))(
      localStorage.getItem('authInfo')
    );
    const request = 'token=' + authData.refresh_token;
    return axiosInstance.request({
      method: 'POST',
      url: `/oauth/revoke`,
      headers: getBasicAuth(),
      data: request,
    });
  },
  async refreshAccessToken() {
    const authStoreKey = 'authInfo';
    const userStoreKey = 'userInfo';
    const authData = ((auth) => (auth ? JSON.parse(auth) : null))(
      localStorage.getItem(authStoreKey)
    );
    if (authData?.refresh_token) {
      const request =
        'grant_type=refresh_token&refresh_token=' + authData.refresh_token;

      const clearStorage = () => {
        localStorage.removeItem(authStoreKey);
        localStorage.removeItem(userStoreKey);
      };

      try {
        const response = await axiosInstance.request({
          method: 'POST',
          url: `/oauth/token`,
          headers: getBasicAuth(),
          data: request,
        });
        const authResponse = response.data;
        authResponse.expiresAt = authResponse.expires_in ?
        new Date().getTime() + (authResponse.expires_in * 1000) : undefined;
        if (!authResponse?.access_token) {
          clearStorage();
        }
        localStorage.setItem(authStoreKey, JSON.stringify(authResponse));
        return authResponse;
      } catch (error) {
        clearStorage();
      }
    } else {
      return null;
    }
  },
  getIdentityUserInfo(access_token: string | null) {
    return axiosInstance.request({
      method: 'GET',
      url: `/oauth/userinfo`,
      headers: { Authorization: 'Bearer ' + access_token },
    });
  },
};
