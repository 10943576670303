import withClearCache from '../../../../../libs/ui/src/clear-cache/clear-cache';
import { isDevelopment } from '../../API/Utils/enivronment-util';
/* eslint-disable-next-line */
export interface AppRootProps {
  children: any;
}
const ClearCacheComponent = withClearCache(AppMain);

export function AppRoot(props:any) {
  return isDevelopment ? <AppMain {...props}/>: <ClearCacheComponent {...props}/>;
}
export function AppMain(props: AppRootProps) {
  return (    
   <>
   <div data-testid="clear-cache" >
   {props.children}
   </div>
   </> 
  );
}

export default AppRoot;
