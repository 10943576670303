import styles from './capabilities-abstract-page.module.scss';
import { Tab } from '@trimble-oss/modus-web-components/dist/types/components';
import { Constants } from '../../API/AppConstants';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ModusTabs } from '@trimble-oss/modus-react-components';
import CapabilitiesStore from '../capabilities-store/capabilities-store';
import CatalogueStore from '../catalogue-store/catalogue-store';
import PageHeader from '../../../../../libs/shared/info-graphics/src/lib/page-header';
import ApisStore from '../apis-store/apis-store';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

/* eslint-disable-next-line */
export interface CapabilitiesAbstractPageProps {
  pageType:
    | Constants.PAGE_DEVELOPER_TOOLS_ID
    | Constants.PAGE_MARKETPLACE_CAPABILITIES_ID
    | Constants.PAGE_MY_CAPABILITIES_ID;
}

export function CapabilitiesAbstractPage(props: CapabilitiesAbstractPageProps) {
  const { t, i18n } = useTranslation();
  const [enableContent, setEnableContent] = useState<boolean>(false);
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [isCapability, setIsCapability] = useState<boolean>(false);
  const [isApis, setisApis] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [showPageHeader,setshowPageHeader ] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  const capabilityTabChange = (tabId: string) => {
    const tabs: Tab[] = [];
    const capabilitiesTab: Tab = {
      active: true,
      id: Constants.ABSTRACT_PAGE_TAB_CAPABILITIES_ID,
      label: t('My.Capability.Tab.Web'),
    };
    const workflowsTab: Tab = {
      active: false,
      id: Constants.ABSTRACT_PAGE_TAB_WORKFLOWS_ID,
      label: t('My.Capability.Tab.workflow'),
    };

    if (tabId !== Constants.ABSTRACT_PAGE_TAB_CAPABILITIES_ID) {
      capabilitiesTab.active = false;
      workflowsTab.active = true;
    } else {
      setIsCapability(true);
    }
    tabs.push(capabilitiesTab);
    tabs.push(workflowsTab);
    setTabs(tabs);
  };

  const apiTabChange = (tabId: string) => {
    const tabs: Tab[] = [];
    const apisTab: Tab = {
      active: true,
      id: Constants.ABSTRACT_PAGE_TAB_DEVELOPER_APIs_ID,
      label: t('Developer.Tools.Tab.APIs'),
    };
    tabs.push(apisTab);
    setTabs(tabs);
  };

  const onTabChange = (event: any) => {
    //console.log('onTabChange', event);
    if(isApis) {
      setisApis(true);
    }else{
      setisApis(false);
      if (event.detail === Constants.ABSTRACT_PAGE_TAB_CAPABILITIES_ID) {
        setIsCapability(true);
        capabilityTabChange(Constants.ABSTRACT_PAGE_TAB_CAPABILITIES_ID);
        navigate(location.pathname+'?tab='+Constants.ABSTRACT_PAGE_TAB_CAPABILITIES_ID);
      } else {
        setIsCapability(false);
        capabilityTabChange(Constants.ABSTRACT_PAGE_TAB_WORKFLOWS_ID);
        navigate(location.pathname+'?tab='+Constants.ABSTRACT_PAGE_TAB_WORKFLOWS_ID);
      }
    }    
  };
  const onCloseClick = (event:any) => {
    setshowPageHeader(false);
  }
  useEffect(() => {
    const tab =searchParams.get('tab');
    if(props.pageType === Constants.PAGE_DEVELOPER_TOOLS_ID) {
      apiTabChange('');
    }else{
      capabilityTabChange(tab || Constants.ABSTRACT_PAGE_TAB_CAPABILITIES_ID);
    }
    setEnableContent(true);    
  }, [props.pageType]);

  if(enableContent) {
    if (props.pageType === Constants.PAGE_MARKETPLACE_CAPABILITIES_ID) {
      return (
        <div className={styles['container']}>
          {showPageHeader && <div className={styles['profile']}>
            <PageHeader
              pageType={Constants.PAGE_MARKETPLACE_CAPABILITIES_ID}
              title={t('Marketplace.Capability.Title')}
              shortNote={t('Marketplace.Capability.Note')}
              desc={t('Marketplace.Capability.Desc')}
              onCloseClick={onCloseClick}
            />
          </div>}
          <div className={styles['data']}>
            <ModusTabs onTabChange={onTabChange} tabs={tabs}></ModusTabs>
          </div>
          {isCapability ? (
            <CapabilitiesStore type="store_view" title="Capabilities" />
          ) : (
            <CatalogueStore type="store_view" title="Workflows" />
          )}
        </div>
      );
    } else if (props.pageType === Constants.PAGE_MY_CAPABILITIES_ID) {
      return (
        <div className={styles['container']}>
          {showPageHeader &&<div className={styles['profile']}>
            <PageHeader
              pageType={Constants.PAGE_MY_CAPABILITIES_ID}
              title={t('My.Capability.Title')}
              shortNote={t('My.Capability.Note')}
              desc={t('My.Capability.Desc')}
              onCloseClick={onCloseClick}
            />
          </div>}
          <div className={styles['data']}>
            <ModusTabs onTabChange={onTabChange} tabs={tabs}></ModusTabs>
          </div>
          {isCapability ? (
            <CapabilitiesStore
              type="store_view"
              title="Capabilities"
              ownedFeatures={true}
            />
          ) : (
            <CatalogueStore
              type="store_view"
              title="Workflows"
              ownedFeatures={true}
            />
          )}
        </div>
      );
    } else if (props.pageType === Constants.PAGE_DEVELOPER_TOOLS_ID) {
      return (
        <div className={styles['container']}>
         {showPageHeader && <div className={styles['profile']}>
            <PageHeader
              pageType={Constants.PAGE_DEVELOPER_TOOLS_ID}
              title={t('Developer.Tools.Title')}
              shortNote={t('Developer.Tools.Note')}
              desc={t('Developer.Tools.Desc')}
              onCloseClick={onCloseClick}
            />
          </div>}
          <div className={styles['data']}>
            <ModusTabs onTabChange={onTabChange} tabs={tabs}></ModusTabs>
          </div>
          <ApisStore type="store_view" title="APIs" />
        </div>
      );
    } else {
      return null;
    }
  }else{
    return null;
  }

}

export default CapabilitiesAbstractPage;
