import { ModusCard } from '@trimble-oss/modus-react-components';
import styles from './key-features.module.scss';

/* eslint-disable-next-line */
export interface KeyFeaturesProps {
  image: {src:string,size:string};
  title: string;
  desc?: any;
  children?: any;
}

export function KeyFeatures(props: KeyFeaturesProps) {
  return (
    <ModusCard showCardBorder={true} showShadowOnHover={true} height={'100%'} width={'100%'} borderRadius={'5px'}>
      <div className={styles['outerContainer']}>
        <div className={styles['imageContainer']}>
          <img
              src={props.image?.src}
              alt="key-features"
              style={{width:props.image?.size,height:props.image?.size}}
              className={styles['image']}
              />  
        </div>  
        <div className={styles['textContainer']}>        
          <div>
            <span className={styles['title']}>{props.title}</span>
            {props.desc && (
            <div className={styles['desc']}>
              <p>{props.desc}</p>
            </div>
            )}
            {props.children && <div>{props.children}</div>}
          </div>          
        </div>
      </div>
    </ModusCard>
    
  );
}

export default KeyFeatures;
