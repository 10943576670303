import { axiosInstance } from '../hooks/axiosInstanceHelper';


// export async function getRolesByAccountId(accountId: string): Promise<any> {
//   return postDecision(accountId, '/roles');
// }

export async function get_is_admin(accountId: string): Promise<any> {
  return postDecision(accountId, '/is_admin');
}
// export async function get_is_owner(accountId: string): Promise<any> {
//   return postDecision(accountId, '/is_owner');
// }

async function postDecision(
  accountId: string,
  urlFragment: string
): Promise<any> {
  const request = {
      accountId: accountId,
  };
  return axiosInstance().request({
    method: 'POST',
    url: '/roles/v1'+urlFragment,
    data: request,
  });
}
