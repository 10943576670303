import { ModusButton } from '@trimble-oss/modus-react-components';
import { RouteConstants } from '../../API/Utils/RouteConstants';
import { useEffect, useState } from 'react';
import {
  InfoGraphics,
  InfoGraphicsProps,
} from '@workflow/shared/info-graphics';
import { t } from 'i18next';
import { To, useNavigate } from 'react-router';
import { globalConfig } from '../../API/Utils/enivronment-util';
import styles from './error-page.module.scss';
import { useNotificationContext } from '../../components/notification-provider/NotificationProvider';
/* eslint-disable-next-line */
export interface ErrorPageProps {
  pageType: string;
  message?: string;
  showSignOut?: boolean;
}

interface InfoProps extends InfoGraphicsProps {
  buttonLink?: To;
  target?: string;
}

export function ErrorPage(props: ErrorPageProps) {
  const [infoGraphicObj, setInfoGraphicObj] = useState<InfoProps>();
  const navigate = useNavigate();
  const { hasError } = useNotificationContext();

  useEffect(() => {
    if (hasError) {
      switch (props.pageType) {
        case RouteConstants['inviteError']: {
          setInfoGraphicObj({
            image: 'images/cloud.svg',
            title: t('ErrorPage.UserInvitation.desc'),
            children: t('Common.Support.btnTitle'),
            buttonLink: RouteConstants['cloud-support'],
          });
          break;
        }
        case RouteConstants['authError']: {
          setInfoGraphicObj({
            image: 'images/no_access.svg',
            title: t('ErrorPage.NoAccess.text'),
            desc: t('ErrorPage.AuthError.desc'),
            children: t('Common.Support.btnTitle'),
            buttonLink: RouteConstants['cloud-support'],
          });
          break;
        }
        case RouteConstants['noSubscription']: {
          setInfoGraphicObj({
            image: 'images/no_access.svg',
            title: t('ErrorPage.NoAccess.text'),
            desc: t('ErrorPage.SubscriptionNotFound.desc'),
            children: t('Common.AzureMarketplace.btnTitle'),
            buttonLink: globalConfig.config?.trimbleCloudSubscriptionUrl,
            target: '_blank',
          });
          break;
        }
        case RouteConstants['accountSuspended']: {
          setInfoGraphicObj({
            image: 'images/no_access.svg',
            title: t('ErrorPage.NoAccess.text'),
            desc: t('ErrorPage.SubscriptionSuspended.desc'),
            children: t('ErrorPage.SaaSubscription.btnTitle'),
            buttonLink: globalConfig.config?.SaaSSubscriptionListUrl,
            target: '_blank',
          });
          break;
        }
        case RouteConstants['commonPageError']:
        default: {
          setInfoGraphicObj({
            image: 'images/no_access.svg',
            title: t('ErrorPage.CantAccess.text'),
            desc: props.message || t('ErrorPage.UnknownError.desc'),
            children: t('Common.Support.btnTitle'),
            buttonLink: RouteConstants['cloud-support'],
          });
          break;
        }
      }
    } else {
      navigate(RouteConstants['myCapabilities']);
    }
  }, []);
  const onClick = (event: any) => {
    event.preventDefault();
    if (infoGraphicObj?.buttonLink) {
      if (infoGraphicObj.target) {
        window.open(
          infoGraphicObj?.buttonLink as string,
          infoGraphicObj.target
        );
      } else {
        navigate(infoGraphicObj?.buttonLink);
      }
    }
  };
  const onClickSignout = (event: any) => {
    event.preventDefault();
    navigate(RouteConstants['logout']);
  };

  return infoGraphicObj ? (
    <InfoGraphics
      image={infoGraphicObj?.image}
      title={infoGraphicObj?.title}
      desc={infoGraphicObj?.desc}
    >
      <div className={styles['container-button']}>
        {props.showSignOut && (
          <ModusButton
            onButtonClick={onClickSignout}
            buttonStyle="outline"
            color="primary"
          >
            {t('Common.Signout')}
          </ModusButton>
        )}
        <ModusButton
          onButtonClick={onClick}
          buttonStyle="outline"
          color="primary"
        >
          {infoGraphicObj?.children}
        </ModusButton>
      </div>
    </InfoGraphics>
  ) : null;
}

export default ErrorPage;
