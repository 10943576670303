import ComingsoonComp from '../../components/comingsoon-comp/comingsoon-comp';
import styles from './comming-soon-store.module.scss';

/* eslint-disable-next-line */
export interface CommingSoonStoreProps {
  title?: string;
  subtitle?: string;
  comingSoonMsg?: any;
  comingSoonTitle?: string;
  inviteDescription1?: string;
  inviteDescription2?: string;
  inviteType?: string;
  source: string;
  children?:any;
  className?:any;
}

export function CommingSoonStore(props: CommingSoonStoreProps) {
  const { title, subtitle, comingSoonTitle, comingSoonMsg, inviteDescription1, inviteDescription2,
    inviteType, source,children } = { ...props };
  return (
    <div className={styles['container']}>
      <div className={styles['contents']}>
        {(source==='Feedback')?
        <div className={styles['items']}>
          <div className={styles['coming-soon-container']+' m-4'}>
          <ComingsoonComp inviteType={inviteType} title={comingSoonTitle} 
              message={comingSoonMsg} source={source}/>
        </div>
      </div>:
      <div className={styles['card-container']}>
        <div className={[styles['items'],styles[props.className]].join(" ")}>
        <h1 data-auto="marketplace-title" id="marketplace_title" className="cust-display-heading p-0.5">{title}</h1>
        {subtitle ? <h1 data-auto="marketplace-desc" id="marketplace_desc" className={styles['display-subheading']+" px-0.5 py-0.5"}>{subtitle}</h1> : ''}
        {!children && <div className={styles['coming-soon-container']}>
          <ComingsoonComp inviteType={inviteType} title={comingSoonTitle ? comingSoonTitle : title} 
              message={comingSoonMsg} description1={inviteDescription1} description2={inviteDescription2} 
              source={source}/>  </div>}
             
      </div>
      {children && <div> {children}</div>}
      </div>}
      </div>
    </div>
  );
}

export default CommingSoonStore;
