const getSelectedFeatureMeta = (originalData: any, dimension: string) => {
  const dimensionData = originalData.supportedDimensions.find(
    (value: any) => value.type === dimension
  );
  return dimensionData;
};

const isSupportedChartTypes = (chartTypes: any) => {
  return chartTypes && typeof chartTypes === 'object';
};

export { getSelectedFeatureMeta, isSupportedChartTypes };

export const optionsForSpecificDimension = (
  transformedData: any,
  optionsData: any
) => {
  console.log(transformedData);
  const value = transformedData.chartTypes
    .map((option: string) => {
      const optionObj = optionsData.find((obj: any) =>
        Object.prototype.hasOwnProperty.call(obj, option)
      );
      return optionObj ? optionObj[option] : null;
    }).filter((item:any) => item != null)
  return value.length > 1 ? value : [];
};
