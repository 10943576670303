import styles from './full-screen.module.scss';

const FullScreenChartPage = ({
  chartComponent,
  onClose,
}: {
  chartComponent: any;
  onClose: any;
}) => {
  return (
    <div className={styles['fullScreenContainer']}>
      <div className={styles['closeButton']} onClick={onClose}>
        Close
      </div>
      {chartComponent.type.name === 'pieChart' ? (
        <div className={styles['pieChartStyle']}>{chartComponent}</div>
      ) : (
        <div className={styles['chartContainer']}>{chartComponent}</div>
      )}
    </div>
  );
};

export default FullScreenChartPage;
