import React from 'react';
import { removeLocalStorageValue, useLocalStorage } from './useLocalStrorage';
import { AuthenticationAPI } from '../API/authentication';

/* eslint-disable-next-line */
export interface AuthProviderProps { }
interface AuthProps {
  authInfo: any | null;
  userInfo: any | null;
  onLoginRedirect?: () => void;
  onLogin?: (code: string | null) => Promise<any>;
  onLogout?: () => Promise<string>;
  isExpired?: () => boolean;
  updateAuthInfo?:any;
}
export const AuthenticationContext = React.createContext<AuthProps>({
  authInfo: null,
  userInfo: null,
});

 function AuthenticationProvider({ children }: any) {
  const [authInfo, setAuthInfo] = useLocalStorage('authInfo', undefined);
  const [userInfo, setUserInfo] = useLocalStorage('userInfo', undefined);

  const loginRedirect = () => {
    window.location.href = AuthenticationAPI.getLoginURL()
  };

  const handleLogin = async (code: string | null) => {
    const response = await AuthenticationAPI.getAccessToken(code);
    if (response?.data) {
      response.data.expiresAt = response.data.expires_in ?
        new Date().getTime() + (response.data.expires_in * 1000) : undefined;
      setAuthInfo(response.data);
      const userInfoResponse = await AuthenticationAPI.getIdentityUserInfo(response.data.access_token);
      setUserInfo(userInfoResponse.data);
      return response.data;
    }
    return null;
  };

  const handleLogout = async () => {
    let tokenRevokeError = null;
    try {
      const response = await AuthenticationAPI.tokenRevoke(
        
      );
    } catch (error) {
      tokenRevokeError = error;
    } finally {
      setAuthInfo(null);
      setUserInfo(null);
      removeLocalStorageValue("authInfo");
      removeLocalStorageValue("userInfo");

    }
    return tokenRevokeError ? Promise.reject(tokenRevokeError) : authInfo?.id_token;
  };
  const isExpired = () => {
    if (authInfo?.expiresAt) {
      return !((authInfo.expiresAt - Date.now()) > 30000);
    }
    return false;
  };

  const setAuthInfoLocalStorage = (data:any) => {
    window.localStorage.setItem("authInfo", JSON.stringify(data));
    setAuthInfo(data);
  }
  const value = {
    authInfo,
    onLoginRedirect: loginRedirect,
    onLogin: handleLogin,
    onLogout: handleLogout,
    isExpired: isExpired,
    userInfo,
    updateAuthInfo:setAuthInfoLocalStorage
  };
  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
}
 const useAuth = () => {
  return React.useContext(AuthenticationContext);
};
export {useAuth,AuthenticationProvider};
