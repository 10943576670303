import { AdditionalFeature } from '../../models/Product';
import styles from './overview-list-features.module.scss';

/* eslint-disable-next-line */
export interface OverviewListFeaturesProps {  
  title?: string;
  items: AdditionalFeature[];
}

export function OverviewListFeatures(props: OverviewListFeaturesProps) {
  const elements = props.items.map((item, key) => { 
    return (<div className={styles['list']} key={'auto-overview-list-'+key} id={'auto-overview-list-'+key}>
        <span className={styles['list-name']}>
          {item.name}
        </span><br/>
        <span className={styles['list-desc']}>
          {item.desc}
        </span>
      </div>) 
  });

  if(props.items.length > 0) {
    return (
      <div className={styles['outerContainer']}>
        {props.title && <div className={styles['title']}>{props.title}</div>}
        <div className={styles['innerContainer']}>
          {elements}
        </div>
      </div>
    );
  } else{
    return null;
  }
  
}

export default OverviewListFeatures;
