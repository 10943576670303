import { StrictMode, Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { Routes, Route, HashRouter, Outlet, Navigate } from 'react-router-dom';
import CatalogueStore from './pages/catalogue-store/catalogue-store';
import LoginProvider from './pages/login-provider/login-provider';
import LoginRedirect from './pages/login-provider/login-redirect';
import ProtectedLayout from './components/protected-layout';
import { AuthenticationProvider } from './hooks/useAuthProviderHook';
import { AppLauncher } from './components/app-launcher';
import NotificationProvider from './components/notification-provider/NotificationProvider';
import CapabilitiesStore from './pages/capabilities-store/capabilities-store';
import './i18n';
import {
  setEnvironmentConfig,
  globalConfig,
} from './API/Utils/enivronment-util';
import CloudSupport from './pages/cloud-support/cloud-support';
import AppRoot from './components/app-root/app-root';
import Logout from './pages/login-provider/logout';
import UsersPage from './pages/users-page/users-page';
import DashboardPage from './pages/metrics-dashboard/metrics-dashboard'
import { ErrorPage } from './pages/inviterror/error-page';
import DataTrimbleDrive from './pages/drive/data-trimble-drive';
import { defineCustomElements } from 'trimble-drive-ui/loader';
import CapabilitiesAbstractPage from './pages/capabilities-abstract-page/capabilities-abstract-page';
import { Constants } from './API/AppConstants';
import App from './components/app';
import { RouteConstants } from './API/Utils/RouteConstants';
import DataStore from './pages/data-store/data-store';

defineCustomElements();
globalConfig.set(setEnvironmentConfig());
ReactDOM.render(
  <StrictMode>
    {/*The Suspense component is designed to "suspend" the rendering of a component until some condition is met, such as until the data required for that component is loaded.
    component is in a suspended state (waiting for data)-display the "Loading..." message as a fallback content.*/}
    <Suspense fallback="Loading...">
      <AppRoot>
        <HashRouter>
          <NotificationProvider type={['toast', 'alertMessage']}>
            <AuthenticationProvider>
              <Routes>
                <Route element={<AppLauncher />}>
                <Route path="cloud-support" element={<CloudSupport />} />
                  <Route path="login" element={<LoginProvider />} />
                  <Route path="signin" element={<LoginRedirect />} />
                  <Route
                    path="invite-error"
                    element={
                      <ErrorPage pageType={RouteConstants['inviteError']} />
                    }
                  />
                  <Route path="logout" element={<Logout />} />
                </Route>
                <Route path="/" element={<ProtectedLayout />}>
                  <Route
                    path="*"
                    element={<Navigate to="my-capabilities" replace />}
                  />
                  <Route
                    path="my-capabilities"
                    element={
                      <CapabilitiesAbstractPage
                        pageType={Constants.PAGE_MY_CAPABILITIES_ID}
                      />
                    }
                  ></Route>
                  <Route path="marketplace" element={<Outlet />}>
                    <Route
                      path="capabilities"
                      element={
                        <CapabilitiesAbstractPage
                          pageType={Constants.PAGE_MARKETPLACE_CAPABILITIES_ID}
                        />
                      }
                    ></Route>
                    <Route
                      path="capabilities/details/:id"
                      element={
                        <CapabilitiesStore
                          type="details"
                          title="Capabilities"
                        />
                      }
                    />
                    <Route
                      path="catalogueDetails/:id"
                      element={
                        <CatalogueStore type="details" title="Workflows" />
                      }
                    />
                    <Route
                      path="developer"
                      element={
                        <CapabilitiesAbstractPage
                          pageType={Constants.PAGE_DEVELOPER_TOOLS_ID}
                        />
                      }
                    />
                  </Route>
                  <Route path="drive">
                    <Route
                      index
                      path="mycontent"
                      element={<DataTrimbleDrive pageType="my-content" />}
                    />
                    <Route
                      path="recyclebin"
                      element={<DataTrimbleDrive pageType="bin" />}
                    />
                  </Route>
                  <Route path="admin">
                    <Route path="users" element={<UsersPage />} />
                    <Route path="dashboard" element={<DashboardPage  />} />
                  </Route>

                  <Route
                    path="data"
                    element={<DataStore type="store_view" title="Data" />}
                  />
                </Route>

                <Route element={<App />}>
                  <Route
                    path="auth-error"
                    element={
                      <ErrorPage pageType={RouteConstants['authError']} />
                    }
                  />
                  <Route
                    path="error-page"
                    element={
                      <ErrorPage pageType={RouteConstants['commonPageError']} />
                    }
                  />
                  <Route
                    path="no-subscription"
                    element={
                      <ErrorPage pageType={RouteConstants['noSubscription']} />
                    }
                  />
                  <Route
                    path="account-suspended"
                    element={
                      <ErrorPage
                        pageType={RouteConstants['accountSuspended']}
                      />
                    }
                  />
                </Route>
              </Routes>
            </AuthenticationProvider>
          </NotificationProvider>
        </HashRouter>
      </AppRoot>
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
);
