import './ui-form.scss';
import {
  DeepPartial,
  FormProvider,
} from 'react-hook-form';
import React from 'react';
import { t } from 'i18next';
import { ModusButton } from '@trimble-oss/modus-react-components';

export interface UiFormProps {
  defaultValues: DeepPartial<any> | undefined;
  children: any;
  onSubmit: (data: any) => void;
  disable: boolean;
  title: string;
  hasSubmit:boolean;
  values?:any;
  methods?:any;
}
export const UiForm: any = React.forwardRef((props: UiFormProps, ref:any) => {

  return (
      <form ref={ref} onSubmit={props.methods.handleSubmit(props.onSubmit)}>
        <FormProvider {...props.methods}>
         {props.children}
          {props.hasSubmit  && 
          <ModusButton typeof='submit' color='primary'>{t('Common.Submit')}</ModusButton>
          }
           {/*  <Button type="submit" variant="primary">
            {t('Common.Submit')}
          </Button> */}
        </FormProvider>
      </form>
  );
});
