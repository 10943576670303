import styles from './users-page.module.scss';
import { UITableView } from '../../../../../libs/shared/ui-table/src/table-view/table-view';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UsersListService } from '../../API/UsersListService';
import { useEntitlements } from '../../components/app-view';
import { TColumn, TRow } from '@trimble-oss/modus-web-components';
import { ModusTableColumn} from '@trimble-oss/modus-web-components/';
import { Tab } from '@trimble-oss/modus-web-components/dist/types/components/modus-tabs/modus-tabs';
import noResultsImage from '../../assets/images/no_results_found.svg';
import { InfoGraphics } from '../../../../../libs/shared/info-graphics/src/lib/info-graphics';

import {
  ModusAlert,
  ModusButton,
  ModusTabs,
  ModusTextInput,
  ModusToast,
} from '@trimble-oss/modus-react-components';
import CreateUserDialog from '../../components/create-user-dialog/create-user-dialog';
import { useAuth } from '../../hooks/useAuthProviderHook';
import ToastLister, { ToastPosition } from '../../components/toast-lister/toast-lister';
import { NotificationMessage } from '../../components/notification-provider/NotificationProvider';
import { Constants } from '../../API/AppConstants';
import {
  EventDetail,
  InvitationRequest,
  ResendInvitationRequest,
  constructDataAsBadge,
  getPendingInvitations,
  invitationResend,
  sendInvitation,
} from '../../API/UserInvitationService';
import { CircleSpinner } from '@workflow/shared/info-graphics';
import UserEditRole from '../../components/user-edit-role/user-edit-role';


export interface ToastProp {
  enable: boolean;
  title: string;
  type:
    | 'danger'
    | 'dark'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning';
}


/* eslint-disable-next-line */
export interface UsersPageProps {}

export function UsersPage(props: UsersPageProps) {
  const allUserTableHeaders: ModusTableColumn<any>[] = [
    {
      header: t('Users.Tables.Name'),
      accessorKey: 'name',
      id: Constants.USER_TABLE_NAME_ID,
      dataType: 'text',
      size: 200,
      minSize: 80
    },
    {
      header: t('Users.Tables.Role'),
      accessorKey: 'role',
      id: Constants.USER_TABLE_ROLE_ID,
      dataType: 'text',
      size: 200,
      minSize: 80
    },
    {
      header: t('Users.Tables.Status'),
      accessorKey: 'status',
      id: Constants.USER_TABLE_STATUS_ID,
      dataType: 'text',
      size: 80,
      minSize: 80
    },
    {
      header:t('Users.Tables.Email'),
      accessorKey: 'email',
      id: Constants.USER_TABLE_EMAIL_ID,
      dataType: 'text',
      size: 320,
      minSize: 80
    },
  ];
  const editActions = [
    {
        "id": "editRole",
        "icon": "pencil",
        "label": "edit",
        "index": 0
    }
  ]   

  const pendingUserTableHeaders:ModusTableColumn<any>[] = [
    {
      header: t('Users.Tables.Email'),
      accessorKey: 'email',
      id: Constants.USER_TABLE_EMAIL_ID,
      dataType: 'text',
      size: 300,
      minSize: 80
    },
    {
      header: t('Users.Tables.Role'),
      accessorKey: 'role',
      id: Constants.USER_TABLE_ROLE_ID,
      dataType: 'text',
      size: 200,
      minSize: 80
    },
    {
      header: t('Users.Tables.Status'),
      accessorKey: 'status',
      id: Constants.USER_TABLE_STATUS_ID,
      dataType: 'text',
      size: 150,
      minSize: 80
    },
    {
      header:t('Users.Tables.Expired.On'),
      accessorKey: 'expiryDate',
      id: Constants.USER_TABLE_EXPIRED_ID,
      dataType: 'text',
      size: 150,
      minSize: 80
    },
    {
      header:'action',
      accessorKey: 'action',
      id: Constants.USER_TABLE_ACTION_ID,
      dataType: 'text',
      size: 150,
      minSize: 80
    }
  ];
  const [error, setError] = useState<any | null>(null);
  const [searchingText, setSearchingText] = useState<string>('');
  const [currentTabId, setCurrentTabId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isdefaultTab, setIsDefaultTab] = useState<boolean>(true);
  const [meta, setMeta] = useState<any>(null);
  const [usersHeaders, setUsersHeaders] = useState<any[]>([]);
  const [usersList, setUsersList] = useState<any[]>([]);
  const [usersTabs, setUsersTabs] = useState<Tab[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const { accountId } = useEntitlements();
  const [toastListProps, setToastListProps] = useState<{toastPosition?:ToastPosition,messages:NotificationMessage[]}>();
  const [openDialog, setOpenDialog] = useState(false);
  const [ showEditRoleModel, setShowEditRoleModel ] = useState(false);
  const [ userRoleEditInfo, setUserRoleEditInfo ] = useState<any>(null);
  const[rowAction,setRowAction]= useState<any>([]);
  const[resendLoading,setresendLoading]=useState(false);

  const availableRoles = [
    {
      value: 'TCP_COMPANY_ADMIN',
      label: t('Common.Role.Title.Administrator'),
      mapper: 'Account Administrator',
      helperText: t('Common.Role.Description.Administrator'),
    },
    {
      value: 'TCP_ACCOUNT_OWNER',
      label: t('Common.Role.Title.Owner'),
      mapper: 'Account Owner',
      helperText: t('Common.Role.Description.Owner'),
    },
    {
      value: 'TCP_END_USER',
      label: t('Common.Role.Title.User'),
      mapper: 'Standard User',
      helperText: t('Common.Role.Description.User'),
    },
  ];

  // resend / re invite props
  const [toastProp, setToastProp] = useState<ToastProp>({
    enable: false,
    title: t('Users.Page.UserAdd.SuccessMsg'),
    type: 'success',
  });
  const [searchErrorText, setSearchErrorText] = useState<string>('');
  const invalidSearchChars = ['<', '>', '(', ')', '{', '}', '[', ']', '"', "'", ';', ':', '|', '\\', '/', '?', ',', '~', '`', '!', '#', '$', '%', '^', '&', '*', '='];

  const paginationAction = async (clickEvent: CustomEvent) => {
    console.log('Clicked next button : ', clickEvent);
    setCurrentPage(clickEvent.detail);
    if (accountId) {
      if (isdefaultTab) {
        const waitForLoad = await constructAllUsers(
          accountId,
          clickEvent.detail,
          pageSize
        );
      } else {
        //TODO pagination for pending users
        const waitForLoad = await constructPendingUsers(
          accountId,
          clickEvent.detail,
          pageSize
        );
      }
    }
  };
  const onClose = () => {
    setOpenDialog(false);
  };
  const onError = (error: any) => {
    if (error) {
      if(error.status === 409) {
        setToastListProps({messages:[
          {
            title: t('Users.Page.UserAdd.ErrorCount',{count:1}),
            message: t('Users.Page.UserAdd.ConflictMsg'),
            status: 'Failed',
            id: 'user-create-failed',
          }
        ]});
      }else {
        setToastListProps({messages:[
          {
            message: '',
            status: 'Failed',
            title: t('Users.Page.UserAdd.Error'),
            id: 'user-create-failed',
          },
        ]});
      }

    }
  };

  const handleEditRoleError = ()=>{
    setShowEditRoleModel(false);
    setToastListProps({messages:[
      {
        message: t('Users.Page.UserRoleEdit.Error'),
        status: 'Failed',
        title: t('Users.Page.UserRoleEdit.Error.Title'),
        id: 'role-edit',
      },
    ]});
  }

  const handleEditRoleClose = ()=>{
    setShowEditRoleModel(false);
  }

  const handleEditRoleSuccess = (res: any)=>{
    setToastListProps({messages:[
      {
        message: t('Users.Page.UserRoleEdit.SuccessMsg'),
        status: 'Completed',
        title: t('Users.Page.UserRoleEdit.Success.Title'),
        id: 'role-edit',
      },
    ]});     
    if(searchingText && searchingText.length > 0){
      setTimeout(() => {
        searchUser();
        setShowEditRoleModel(false); 
      }, 2000);      
    }else {
      setTimeout(() => {
        loadDefaultData();
        setShowEditRoleModel(false); 
      }, 2000);
    }       
  }

  const onRefresh = async () => {
    setError(null);
    //console.log('tabSelectionHandler text : ', event);
    if (isdefaultTab) {
      updateTabToActive(Constants.USER_TAB_ALL_USERS_ID);
      setUsersHeaders(allUserTableHeaders);
      setIsDefaultTab(true);
      await loadDefaultData();
    } else {
      await loadDefaultPendingUsers();
    }
  };

  const loadDefaultData = async () => {
    //console.log('Loading default page  : ', currentPage, searchingText);
    if (accountId) {
      setIsLoaded(false);
      setCurrentPage(1);
      await failureHandle(
        UsersListService.getUsersByProfileFilter(
          accountId,
          1,
          pageSize
        )
        ,true
      );
      setRowAction(editActions)
    }
  };

  const onCellLinkClickAction = (event: any) => {
    console.log('Clicked on cell link : ', event);
    if(event.detail?.actionId && event.detail.actionId === 'editRole'){
      setUserRoleEditInfo(event.detail.row);
      setShowEditRoleModel(true);
    }else{
      onResendConfirm(event.detail);
    }    
  };

  const alterTheLinkToText = (event: any, type: string, colour?: string) => {
    const alteredUsers = usersList.map((user: any) => {
      //console.log('alterTheLinkToText : ', user, event);
      if (user.action.id === event.id) {
        if (type === 'text') {
          user.action = event.display;
        } else if (type === 'link') {
          user.action = event;
        } else {
          user.action = constructDataAsBadge(type, colour? colour : '', event.id);
        }
      }
      return user;
    });
    //console.log('alteredUsers : ', alteredUsers);
    setUsersList(alteredUsers);
  };

  const onResendConfirm = async (resendUserDetails: EventDetail) => {
    //console.log('Clicked on resend confirm : ', event);
    //resendConfirmDialog.current?.close();
    setIsLoaded(true);
    setresendLoading(true)     
    let requestPayload: ResendInvitationRequest | InvitationRequest;
    if (resendUserDetails) {
      if (
        resendUserDetails.display.toLowerCase() === Constants.USER_REINVITE_TEXT
      ) {
        requestPayload = {
          firstName: resendUserDetails.firstName,
          lastName: resendUserDetails.lastName,
          inviteEmailAddress: resendUserDetails.email,
          role: resendUserDetails.role,
          inviterName: userInfo.given_name,
          accountId: resendUserDetails.accountId,
        };
        const status = await failureHandle(sendInvitation(requestPayload));
        if (status > 0) {
          setToastProp({
            enable: true,
            title: t('Users.Page.UserResend.SuccessMsg', { email : resendUserDetails.email}),
            type: 'success',
          });
          setresendLoading(false)
        } else {
          setToastProp({
            enable: true,
            title: t('Users.Page.UserResend.ErrorMsg', { email : resendUserDetails.email}),
            type: 'danger',
          });

          setresendLoading(false)
        }
      } else {
        requestPayload = {
          invitationId: resendUserDetails.id,
        };
        const status = await failureHandle(invitationResend(requestPayload));
        if (status > 0) {
          setToastProp({
            enable: true,
            title: t('Users.Page.UserResend.SuccessMsg', { email : resendUserDetails.email}),
            type: 'success',
          });
          setresendLoading(false)
        } else {
          setToastProp({
            enable: true,
            title: t('Users.Page.UserResend.ErrorMsg', { email : resendUserDetails.email}),
            type: 'danger',
          });
          setresendLoading(false)
        }
      }
    }
    //alterTheLinkToText(resendUserDetails, 'batch', 'success');
    //await loadDefaultPendingUsers();
    setIsLoaded(true);
  };

  const onToastClose = async (event: any) => {
    setToastProp({
      enable: false,
      title: t('Users.Page.UserResend.ErrorMsg'),
      type: 'danger',
    });
  };

  const loadDefaultPendingUsers = async () => {
    //console.log('Loading default pending users page  : ', currentPage, searchingText);
    //setSearchingText('');
    if (accountId) {
      setCurrentPage(1);
      setRowAction([]);
      setIsLoaded(false);
      updateTabToActive(Constants.USER_TAB_ICS_USERS_ID);
      setUsersHeaders(pendingUserTableHeaders);
      await failureHandle(
        getPendingInvitations(accountId ? accountId : '', 0, pageSize),
        true
      );
    }
  };

  const failureHandle = async (response: any, isUserInfo?: boolean) => {
    let status = 0;
    await response.then(
      (loaddata: any) => {
        if (isUserInfo) {
          setUsersList(loaddata.data);
          setMeta(loaddata.meta);
          setIsDefaultTab(false);
          setIsLoaded(true);
        }
        status = 1;
      },
      (error: any) => {
        updateErrorPageDetails(error);
        status = -1;
      }
    );
    return status;
  };

  const updateErrorPageDetails = (error: any) => {
    console.log('error : ', error);
    if (error.status === 403) {
      setError({ message: t('Users.Page.ForbiddenError') });
    } else {
      setError({ message: t('Workflows.Failure.Message', { task: '' }) });
    }
    setIsLoaded(true);
  };

  const searchUser = async () => {
    //console.log('Searching this text : ', searchingText);
    setIsLoaded(false);
    if (accountId && searchingText && searchingText.length > 2) {
      setCurrentPage(1);
      const usersResponse = await UsersListService.getUsersByProfileFilter(
        accountId,
        1,
        pageSize,
        searchingText
      );
      setUsersList(usersResponse.data);
      setMeta(usersResponse.meta);
    }
    setIsLoaded(true);
  };

  const searchPendingUsers = async () => {
    //console.log('Searching this text in pending users: ', searchingText);
    setIsLoaded(false);
    if (accountId && searchingText && searchingText.length > 2) {
      setCurrentPage(1);
      const usersResponse = await getPendingInvitations(
        accountId,
        1,
        pageSize,
        searchingText
      );
      setUsersList(usersResponse.data);
      setMeta(usersResponse.meta);
    }
    setIsLoaded(true);
  };

  const searchingTextEventHandler = async (event: any) => {
    //console.log('searchingTextEventHandler text : ', event.detail);
    if (!event.detail) {      
      setSearchingText('');
      //console.log('updating empty text : ', event.detail);
      if(isdefaultTab) {        
        await loadDefaultData();
      }else{
        await loadDefaultPendingUsers();
      }
    } else {
      const searchTxt = event.detail;
      let hasInvalidChar = false;
      invalidSearchChars.forEach((eachInvalidChar) => {
        if(searchTxt.includes(eachInvalidChar)) {
          hasInvalidChar = true;
        }
      });
      if(hasInvalidChar) {        
        setSearchErrorText(t('Users.Search.Char.Error'));
        setSearchingText('');
      }else{
        setSearchErrorText('');
        setSearchingText(event.detail);
      }      
    }
  };

  const searchingTextEnter = async (event: any) => {
    if (searchingText.length > 2) {
      if (isdefaultTab) {
        await searchUser();
      } else {
        await searchPendingUsers();
      }
    }
  };
  const onComplete = (success:number,closeDialog?:boolean,toastPosition?:ToastPosition,conflict?:string[],error?:string[]) =>{
    const messages=[];
    if(success){
      messages.push({
        title: t('Users.Page.UserAdd.Success'),
        message: t('Users.Page.UserAdd.SuccessMsg',{count:success}),
        status: 'Completed',
        id: 'user-created',
      })
    }
    setToastListProps({messages,toastPosition});
    if(closeDialog)
    setOpenDialog(false);
  }
  // const onSuccess = async (reload: boolean, action?: string) => {
  //   let title = t('Users.Page.UserAdd.Success');
  //   let msg = t('Users.Page.UserAdd.SuccessMsg');
  //   if (action) {
  //     if (action.toLowerCase() === Constants.USER_RESEND_INVITE_TEXT) {
  //       title = t('Users.Page.UserResend.SuccessMsg');
  //       msg = '';
  //     } else if (action.toLowerCase() === Constants.USER_REINVITE_TEXT) {
  //       title = t('Users.Page.UserReinvite.SuccessMsg');
  //       msg = '';
  //     }
  //   } else {
  //     setOpenDialog(false);
  //   }
  //   setToastListProps({messages:[
  //     {
  //       title: title,
  //       message: msg,
  //       status: 'Completed',
  //       id: 'user-created',
  //     },
  //   ]});

  //   if (!isdefaultTab && reload) {
  //     await loadDefaultPendingUsers();
  //   }
  // };

  const closeNotification = (id: string) => {
    setToastListProps(undefined);
  };

  const tabSelectionHandler = async (event: any) => {
    setError(null);
    setSearchingText('');
    setSearchErrorText('');
    //console.log('tabSelectionHandler text : ', event);
    if (event.detail === Constants.USER_TAB_ALL_USERS_ID) {
      updateTabToActive(Constants.USER_TAB_ALL_USERS_ID);
      setUsersHeaders(allUserTableHeaders);
      setIsDefaultTab(true);
      await loadDefaultData();
    } else {
      await loadDefaultPendingUsers();
    }
  };

  const updateTabToActive = (tabId: string) => {
    const tabs: Tab[] = [];
    const profileUsersTab: Tab = {
      active: true,
      id: Constants.USER_TAB_ALL_USERS_ID,
      label: t('Users.Page.Tab.All'),
    };
    const invitationUsersTab: Tab = {
      active: false,
      id: Constants.USER_TAB_ICS_USERS_ID,
      label: t('Users.Page.Tab.Pending'),
    };

    if (tabId !== Constants.USER_TAB_ALL_USERS_ID) {
      profileUsersTab.active = false;
      invitationUsersTab.active = true;
    }
    tabs.push(profileUsersTab);
    tabs.push(invitationUsersTab);
    setUsersTabs(tabs);
  };

  const constructAllUsers = async (
    accountId: string,
    currentPage: number,
    pageSize: number
  ) => {
    setIsLoaded(false);
    //console.log('searchingText on constructUsers  : ' + searchingText);
    const usersResponse = await UsersListService.getUsersByProfileFilter(
      accountId,
      currentPage,
      pageSize,
      searchingText
    );
    setCurrentTabId(Constants.USER_TAB_ALL_USERS_ID);
    setRowAction(editActions);
    setUsersList(usersResponse.data);
    setMeta(usersResponse.meta);
    setIsLoaded(true);
  };

  const constructPendingUsers = async (
    accountId: string,
    currentPage: number,
    pageSize: number
  ) => {
    setIsLoaded(false);
    //console.log('searchingText on constructUsers  : ' + searchingText);
    const usersResponse = await getPendingInvitations(
      accountId,
      currentPage,
      pageSize,
      searchingText
    );
    setCurrentTabId(Constants.USER_TAB_ICS_USERS_ID);
    setUsersList(usersResponse.data);
    setRowAction([]);
    setMeta(usersResponse.meta);
    setIsLoaded(true);
  };

  const setScreenSize = () => {
    const screenHeight = window.innerHeight;
    console.log('screenHeight : ', screenHeight);
    if (screenHeight < 700) {
      setPageSize(5);
    } else if (screenHeight > 700 && screenHeight < 900) {
      setPageSize(10);
    } else if (screenHeight > 900 && screenHeight < 1100) {
      setPageSize(12);
    } else {
      setPageSize(15);
    }
  };

  useEffect(() => {
    const hasAccount = !!accountId;
    if (hasAccount) {
      //setScreenSize();
      failureHandle(constructAllUsers(accountId, currentPage, pageSize));
    } else {
      setError({
        message: t('entitlement.not.found'),
      });
    }

    // setting tab details
    updateTabToActive(Constants.USER_TAB_ALL_USERS_ID);
    setUsersHeaders(allUserTableHeaders);

    return () => {
      //cleanup
      setSearchingText('');
      setCurrentPage(1);
      setMeta(null);
      setUsersList([]);
    };
  }, [accountId]); //, currentPage, usersList

  function provideContent() {
    if (error) {
      const refreshButton = (<ModusButton
        buttonStyle="borderless"
        color="primary"
        iconOnly="refresh"
        onClick={() => {
          onRefresh();
        }}
        size="small"
      ></ModusButton>);
      return (
        <div className={styles['table']}>
          <InfoGraphics
                image={'images/api_failure_image.svg'}
                title={t('Workflows.Failure.Message', { task: '' })}
                children={refreshButton}
              />
        </div>
      );
    } else {
      if (isLoaded) {
        if (usersList.length > 0) {
          return (
            <div className={styles['table']}>
              {/* <ModusTooltip text="Resend can be done after 1 day"></ModusTooltip> */}
              <UITableView
                headers={usersHeaders}
                values={usersList}
                rowActions={rowAction}
                currentPageNumber={currentPage}
                paginationAction={paginationAction}
                metaData={meta}
                onRowActionClick={onCellLinkClickAction}
                onCellLinkClick={onCellLinkClickAction}
              />
            </div>
          );
        } else {
          return (
            <div>
              <InfoGraphics
                image={noResultsImage}
                title={t('Users.No.Results.title')}
                desc={t('Users.No.Results.desc')}
              />
            </div>
          );
        }
      } else {
        return (
          <CircleSpinner size="48px" text={t('Common.Loading.Title')}></CircleSpinner>
        );
      }
    }
  }

  return (
    <div>
      <ToastLister
        toastMesgList={toastListProps?.messages}
        closeToast={closeNotification}
        toastPosition={toastListProps?.toastPosition}
      ></ToastLister>
      <div id="titleBox" className={styles['headerContainer']}>
        <h1 data-auto="users-title" className="cust-display-heading p-0.5">
          {t('Users.title')}
        </h1>
        {toastProp.enable && (
          <div className={styles['toast']}>
            <ModusToast
              dismissible={true}
              showIcon={true}
              type={toastProp.type}
              onDismissClick={onToastClose}
            >
              {toastProp.title}
            </ModusToast>
          </div>
        )}

        <div className={styles['action-container']}>
          {/* <ProvideSearchContainer/> */}
          <div className={styles['search-container']} id="">
              <ModusTextInput
                placeholder={t('Users.Search.Users')}
                minLength={3}
                size="medium"
                value={searchingText}
                onValueChange={searchingTextEventHandler}
                clearable={true}
                includeSearchIcon={true}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    searchingTextEnter(e);
                  }
                }}
                helperText=''
                errorText={searchErrorText}
              ></ModusTextInput>
            </div>
          <div className={styles['emptySpaceContainer']}></div>
          <ModusButton
            color="primary"
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            {t('Users.Add.Users')}
          </ModusButton>
          {/* <ModusButton
            buttonStyle="borderless"
            color="primary"
            iconOnly="refresh"
            onClick={() => {
              onRefresh();
            }}
            size="small"
          ></ModusButton> */}
        </div>
      </div>
      <br></br>
      <ModusTabs
        fullWidth={false}
        ariaLabel={'ics-tabs'}
        size={'medium'}
        tabs={usersTabs}
        onTabChange={tabSelectionHandler}
      />
        {resendLoading && (
          <div className={styles['progress-bar']}>
            <div className={styles['progress-bar-value']}></div>
          </div>
        )}
      {provideContent()}
      {openDialog && (
        <CreateUserDialog
          title={t('Users.AddModal.Title')}
          onInvitationComplete={onComplete}
          accountId={accountId}
          currentUserName={userInfo.given_name}
          onClose={onClose}
          onError={onError}
        ></CreateUserDialog>
      )}
      {showEditRoleModel && (
        <UserEditRole
          title={t('Users.EditRoleModal.Title')}
          userInfo={userRoleEditInfo}
          accountId={accountId}
          onSuccess={handleEditRoleSuccess}
          onClose={handleEditRoleClose}
          onError={handleEditRoleError}
        />
      )}
    </div>
  );
}

export default UsersPage;


