export enum ActionType {
    ADD="add",UPDATE="update", REMOVE="remove",VIEW_MORE="view-more",LAUNCH="launch"
}

export interface ActionProps {
    type: string;
    isDisabled?: boolean;
    clickHandlerLabel?: string|undefined;
    title: string;
    actionId:ActionType;
    status?: string;
    to?: string;
    target?:string;
    isOnload?: boolean;
    show?:boolean;
    variant?:string;
    size?:any;
    icon?:string;
    style?: any;
}