import styles from './app.module.scss';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuthProviderHook';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setUserAndAccountContext } from '../assets/js_files/gs';
import { ModusNavbar } from '@trimble-oss/modus-react-components';
import { RouteConstants } from '../API/Utils/RouteConstants';
import folderIcon from '@trimble-oss/modus-icons/dist/modus-outlined/svg/folder-closed.svg';
import binIcon from '@trimble-oss/modus-icons/dist/modus-outlined/svg/delete.svg';
import settings from '@trimble-oss/modus-icons/dist/modus-solid/svg/settings.svg';
import manageAccount from '@trimble-oss/modus-icons/dist/modus-solid/svg/manage-accounts.svg';
import dashboard from '@trimble-oss/modus-icons/dist/modus-solid/svg/dashboard.svg';
import widgets from '@trimble-oss/modus-icons/dist/modus-solid/svg/widgets.svg';
import code from '@trimble-oss/modus-icons/dist/modus-solid/svg/code.svg';
import flowChart from '@trimble-oss/modus-icons/dist/modus-solid/svg/flowchart.svg';
import flowChartOutlined from '@trimble-oss/modus-icons/dist/modus-outlined/svg/flowchart.svg';
import cloudConnected from '@trimble-oss/modus-icons/dist/modus-solid/svg/cloud-connected.svg';
import { globalConfig } from '../API/Utils/enivronment-util';
import { FeatureCapabilities } from '@workflow/authorization';
import { useAuthorizationHandler } from '../hooks/use-authorization-handler/use-authorization-handler';
import { isTruthy } from '@workflow/ui';

const blueTheme = `--modus-side-navigation-bg: #0e416c;--modus-side-navigation-item-color: #ffffff;--modus-side-navigation-item-active-bg: #217cbb;--modus-side-navigation-item-hover-bg: #0063a3;--modus-side-navigation-item-icon-color: #ffffff;--modus-side-navigation-item-chevron-color: #ffffff;--modus-side-navigation-item-icon-filter: brightness(0) invert(1);--modus-link-color:#ffffff`;

export function App() {
  const { userInfo, authInfo } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { authorizationInstance } = useAuthorizationHandler();
  const onSideNavItemClicked = (event: any) => {
    if (event.detail && event.detail.selected) {
      if (RouteConstants[event.detail.id])
        navigate(RouteConstants[event.detail.id]);
    }
  };
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const newMenuItems: any = [
    {
      label: 'My Capabilities',
      id: 'myCapabilities',
      link: RouteConstants['myCapabilities'],
      menuIcon: flowChart,
      onSideNavItemClicked,
      authorize: {
        featureName: FeatureCapabilities.myCapabilities,
        actions: 'read',
      },
    },
    {
      label: 'Marketplace',
      id: 'marketplace',
      menuIcon: widgets,
      children: [
        {
          label: t('Capabilities.Title'),
          id: 'marketplaceCapabilities',
          link: RouteConstants['marketplaceCapabilities'],
          menuIcon: flowChartOutlined,
          onSideNavItemClicked,
          authorize: {
            featureName: FeatureCapabilities.marketplaceCapabilities,
            actions: 'read',
          },
        }
      ],
    },
    {
      label: t('Common.AdminCenter'),
      id: 'admin-center',
      menuIcon: settings,
      children: [
        {
          label: t('Users.title'),
          id: 'users',
          link: RouteConstants['users'],
          menuIcon: manageAccount,
          onSideNavItemClicked,
          authorize: {
            featureName: FeatureCapabilities.accountUsers,
            actions: 'read',
          },
        },
        {
          label: 'Usage Metrics',
          id: 'usagedashboard',
          link: RouteConstants['usagedashboard'],
          menuIcon: dashboard,
          onSideNavItemClicked,
          authorize: {
            featureName: FeatureCapabilities.accountUsers,
            actions: 'read',
          },
        }
      ],
    },
  ];
  const developerTools = {
    label: 'Developer',
    id: 'marketplaceDeveloper',
    link: RouteConstants['marketplaceDeveloper'],
    menuIcon: code,
    onSideNavItemClicked,
    authorize: {
      featureName: FeatureCapabilities.developerPortal,
      actions: 'read',
    },
  };
  const enableDeveloperPageBasedOnFlag = () => {
    const isShowDeveloperPage = isTruthy(globalConfig.config.tdpPortalShow);
    if (isShowDeveloperPage && newMenuItems[1].children) {
      newMenuItems[1].children.push(developerTools);
    }
  };
  const enableDrivePageBasedOnFlag = () => {
    const isShowDrivePage = isTruthy(globalConfig.config.drivePageShow);
    if (isShowDrivePage) {
      newMenuItems.splice(1, 0, {
        label: t('Data.Title'),
        id: 'data',
        link: '/drive',
        menuIcon: cloudConnected,
        authorize: {
          featureName: FeatureCapabilities.TDrive,
          actions: 'read',
        },
        children: [
          {
            label: 'My Content',
            id: 'mycontent',
            link: '/drive/mycontent',
            menuIcon: folderIcon,
            onSideNavItemClicked,
          },
          {
            label: 'Recycle Bin',
            id: 'recyclebin',
            link: '/drive/recyclebin',
            menuIcon: binIcon,
            onSideNavItemClicked,
          },
        ],
      });
    }
  };
  enableDeveloperPageBasedOnFlag();
  enableDrivePageBasedOnFlag();

  useEffect(() => {
    const menus: any = [];
    newMenuItems.forEach((item: any) => {
      if (
        item.authorize &&
        authorizationInstance?.hasPermission(item.authorize) &&
        (!item.children ||
          item.children.every((child: any) => !child.authorize))
      ) {
        menus.push(item);
      } else if (item.children) {
        const children: any[] = [];
        item.children.forEach((child: any) => {
          if (
            child.authorize &&
            authorizationInstance?.hasPermission(child.authorize)
          ) {
            children.push(child);
          }
        });
        if (children.length > 0) {
          menus.push({ ...item, children });
        }
      }
    });
    setMenuItems(menus);
  }, [authorizationInstance]);

  useEffect(() => {
    let navbar: HTMLModusNavbarElement | null;
    const sidenav = document.querySelector('modus-side-navigation');
    const expand = () => {
      if (sidenav) sidenav.expanded = !sidenav.expanded;
    };
    if (hasMenuItems()) {
      if (sidenav) {
        sidenav.data = [...menuItems];
        (sidenav as any).setAttribute('style', blueTheme);
        navbar = document.querySelector('modus-navbar');
        (navbar as any)?.addEventListener('mainMenuClick', expand);
      }
    }
    return () => {
      if (navbar) (navbar as any)?.removeEventListener('mainMenuClick', expand);
    };
  }, [menuItems]);

  useEffect(() => {
    if (userInfo && globalConfig.config.gainSightToken) {
      const domain = userInfo?.email.split('@')[1].split('.com')[0];
      setUserAndAccountContext(
        {
          id: userInfo?.sub,
          email: userInfo?.email,
          firstName: userInfo?.given_name,
        },
        { id: domain, name: domain.toUpperCase() }
      );
    }
  }, [userInfo]);

  const onProfileMenuLinkClick = (event: any) => {
    if (event.detail && event.detail === 'identity-myprofile') {
      window.open(globalConfig.config.myProfileURL, '_blank', 'noreferrer');
    }
  };
  const signOutApp = (): void => {
    navigate(RouteConstants['logout']);
  };
  const hasMenuItems = () => {
    return menuItems && menuItems.length > 0;
  };
  const onHelpClick = (event: any) => {
    const currentPage = location.pathname;
    if(RouteConstants['cloud-support'] !== currentPage){
      navigate(RouteConstants['cloud-support']);
    }    
  };

  // const onItemClick = (id: string) => {
  //   if (id) {
  //     const menu = helpMenu.find((val) => val.id === id);
  //     if (menu?.link) navigate(menu?.link);
  //   }
  // };
  const onProductLogoClick = (event: any) => {
    navigate(RouteConstants['myCapabilities']);
  };
  // const navButtons = [
  //   {
  //     id: 'helpMenu',
  //     icon: 'help',
  //     orderIndex: 0,
  //     tooltip: { text: 'Help', ariaLabel: 'Help' },
  //   },
  // ];
  const logoOptions = {
    primary: {
      height: '32',
      url: 'images/TCC_Logo.svg',
    },
  };
  const profileMenuOptions = userInfo
    ? {
        username: `${userInfo?.given_name} ${userInfo?.family_name}`,
        email: userInfo?.email,
        initials: `${userInfo?.given_name[0]}${userInfo?.family_name[0]}`,
        links: [
          {
            display: t('AccountInfo.MyProfile.Title'),
            id: 'identity-myprofile',
          },
        ],
      }
    : undefined;
  // const itemStyle = { '--modus-list-item-border-color': 'transparent' };
  // const helpMenu = [
  //   {
  //     id: 'cloud-support',
  //     link: '/cloud-support',
  //     title: t('Common.Support'),
  //     style: itemStyle,
  //   },
  //   {
  //     id: 'feedback',
  //     link: '/feedback',
  //     title: t('Common.SendFeedback'),
  //     style: itemStyle,
  //   },
  // ];
  return (
    <div className={styles['container-layout']} id="appContainer">
      {authInfo && (
        <div className={styles['header-panel']}>
          <ModusNavbar
            logoOptions={logoOptions}
            profileMenuOptions={profileMenuOptions}
            showMainMenu={hasMenuItems()}
            showShadow
            showHelp
            onProfileMenuSignOutClick={() => signOutApp()}
            onAppsMenuAppOpen={() => console.log('APPS MENU APP OPEN')}
            onAppsMenuOpen={() => console.log('APPS MENU OPEN')}
            onHelpOpen={onHelpClick}
            onNotificationsMenuOpen={() =>
              console.log('NOTIFICATIONS MENU OPEN')
            }
            onProductLogoClick={onProductLogoClick}
            onProfileMenuLinkClick={onProfileMenuLinkClick}
            onProfileMenuOpen={() => console.log('PROFILE MENU OPEN')}
          />
        </div>
      )}
      <div className={styles['container-content']}>
        {hasMenuItems() && (
          <modus-side-navigation
            max-width="250px"
            id="sideNav"
            target-content="#appcontent"
            mode="push"
            collapse-on-click-outside="false"
            expanded="true"
          ></modus-side-navigation>
        )}
        <div className={'modus-content border-top p-3'} id="appcontent">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;
