export function setUserAndAccountContext(userFields, accountFields) {
    if (!userFields.id && userFields.email) {
      // eslint-disable-next-line no-undef
      userFields.id =userFields.email;  //md5(userFields.email);
    }
    if (accountFields && accountFields.id) {
      window.aptrinsic('identify', userFields, accountFields);
    }
}


export function simplegsUpdate(email) {
    window.aptrinsic('identify', { "id": Date.now(), "email":email } );
}

export function sendCustomEvent(featureName, props) {
  window.aptrinsic('track', featureName , props );
}

