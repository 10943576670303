import i18next from 'i18next';
import moment from 'moment';
export enum AppContants {
  MINIMUM_INTERVAL_IN_FUTURE = '30',
  MINIMUM_INTERVAL_IN_FUTURE_FOR_VALIDATION = '29',
}
export class DateUtil {
  public static shorttimeFormat: 'LT' = 'LT'; // without seconds
  public static longTimeFormat: 'LTS' = 'LTS'; // with seconds

  public static localize() {
    return moment.locale(i18next.resolvedLanguage);
  }

  public static parseLocalize(input: string, format: string): moment.Moment {
    return moment(input, format, i18next.resolvedLanguage, true);
  }
}

export const format_date = (date: string, format: string) => {
  return date ? moment(date).format(format) : '';
};

export const format_date_default = (date: string) => {
  return date ? moment(date).format('LLL') : '';
};

export const getCurrentMomentWithIncrement = (
  increment: any,
  incrementType: any
) => {
  return moment().add(increment, incrementType).toDate();
};

export const getCurrentTimeWithDefaultIncrement = () => {
  return getCurrentMomentWithIncrement(
    AppContants.MINIMUM_INTERVAL_IN_FUTURE_FOR_VALIDATION,
    'minutes'
  );
};
