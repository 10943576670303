import { useOutlet } from 'react-router-dom';
import styles from './app-launcher.module.scss';
/* eslint-disable-next-line */
export interface AppLauncherProps {}

export function AppLauncher(props: AppLauncherProps) {
  const outlet = useOutlet();
  return (
    <div className={styles['container-content']}>
      <div className="p-4" id="modusContent">
        {outlet}
      </div>
    </div>
  );
}

export default AppLauncher;
