import styles from './chips.module.scss';

/* eslint-disable-next-line */
export interface ChipsProps {
  title?: string;
  items: string[];
}

export function Chips(props: ChipsProps) {

  const colours = ['#65BACD', '#6F89FF', '#8F54B4', '#FF88B3', '#FF8F88', '#0063A3'];

  const elements = props.items.map((item, key) => { 
    const randomColour = colours[Math.floor(Math.random()*colours.length)];
    return <div className={styles['chip']} style={ {color: randomColour, border:'1px solid '+randomColour } } id={item} key={item}>{item}</div> 
  });

  return (
    <div className={styles['outerContainer']}>
      {props.title && <div className={styles['title']}>{props.title}</div>}
      <div className={styles['innerContainer']}>
        {elements}
      </div>
    </div>
  );
}

export default Chips;
