import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { StatusTypes } from '../comingsoon-comp/comingsoon-comp';
import { useAuth } from '../../hooks/useAuthProviderHook';
import styles from './inviteuser.module.scss';
import { ModusButton, ModusTextInput } from '@trimble-oss/modus-react-components';
import { ModusAlert } from '@trimble-oss/modus-react-components';

export interface InviteuserProps {
  onClickHandler: (email: string, content: string, userInfoObj: any, source: string, type: string | undefined) => void;
  status: StatusTypes;
  errorMessage: string;
  inviteType?: string;
  source: string;
}

export function Inviteuser(props: InviteuserProps) {
  const { onClickHandler, status, inviteType, source } = { ...props };
  const [email, setEmail] = useState('');
  const [content, setContent] = useState('');

  const { userInfo } = useAuth();
  let userInfoObj: any;
  if (userInfo) {
    userInfoObj = {
      userId: userInfo.sub,
      email: userInfo.email,
      name: [userInfo.given_name, userInfo.family_name]
        .filter((x) => !!x)
        .join(' ')
    };
  }

  useEffect(()=>{
    if(userInfoObj && userInfoObj.email){
      setEmail(userInfoObj.email);
    }
  },[]);

  return (
    <div>
      {status === StatusTypes.status_none ? (
        <form className={styles['emailForm']}>
          <ModusAlert message={props.errorMessage} type="error" />
          <div className={styles['form-group']}>
            {inviteType === 'type2' ? (
              <div>
                <div className={styles['text-area-heading']}>
                  {t('Common.Comments')}
                </div>
                <ModusTextInput 
                  data-testid="comment-input"
                  className={'mt-2'}
                  size={'large'}
                  onValueChange={(e) => setContent(e.target.value)}
                />
              </div>
            ) : (
              ''
            )}
            <div className={styles['submitBtn']}>
              {inviteType === 'type1' || inviteType === 'type3' ? (
                <ModusButton
                  onClick={() =>
                    onClickHandler(
                      email,
                      content,
                      userInfoObj,
                      source,
                      inviteType
                    )
                  }
                  id="type13-submitBtn"
                >
                  {t('Common.Submit2')}
                </ModusButton>
              ) : inviteType === 'type2' ? (
                <ModusButton
                  onClick={() =>
                    onClickHandler(
                      email,
                      content,
                      userInfoObj,
                      source,
                      inviteType
                    )
                  }
                  id="type2-submitBtn"
                >
                  {t('Common.Submit')}
                </ModusButton>
              ) : (
                ''
              )}
            </div>
          </div>
        </form>
      ) : status === StatusTypes.status_done ? (
        <div className={styles['input-success-msg']}>
          <div>
            <i className="material-icons-outlined">check_circle</i>
          </div>
          <div className="pl-2">{t('Common.Email.Success.Message')}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default Inviteuser;
