import loader from '../../assets/images/loaderGif.gif'

/* eslint-disable-next-line */
export interface NotificationComponentProps {
  id: string;
  message: string;
  closeNotification: (id:string)=>void;
  status: string;
  title: string;
}

export function NotificationComponent(props: NotificationComponentProps) {
  const {id, message, status, title, closeNotification} = {...props};
  const getStatusIcon = (
    status: string
  ) => {
    let icon="";
    switch(status){
      case "Completed":
        icon= "check_circle";
        break;
      case "Failed":
        icon= "error";
        break;
    }
    return icon;
  };
  return (
    <div className={`notification-item`}>
    <div className={'notification-contents'}>
      <div className={'notification-header'}>
        {
          (status === "In Progress") ?
            <div className={'notification-image-div'}>
              <img height="5" width="20" src={loader} alt='loader...'></img>
            </div> : <i className="material-icons-outlined">{getStatusIcon(status)}</i>
        }
        <div className={"notificationTitle"}>{title}</div>
        <div onClick={()=>closeNotification(id)} className={"closebutton"} id="closeBtn">&#x2715;
        </div>
      </div>
      <div className={"notificationmessage"}>{message}</div>
    </div>
  </div>
  );
}

export default NotificationComponent;
