import styles from './shared-graphics.module.scss';
import { ModusButton } from '@trimble-oss/modus-react-components';
import { useNavigate } from 'react-router-dom';

/* eslint-disable-next-line */
export interface SharedGraphicsProps {
  title: string;
  description: string;
  btnText: string;
  btnLink: string;
  source?:string;
}

export function SharedGraphics(props: SharedGraphicsProps) {
  const navigate = useNavigate();
  return (
    <div className={styles['outerContainer']}>
      <span className={styles['title']}>{props.title}</span>
      <span className={styles['descr']}>{props.description}</span>
      <ModusButton
        size={'small'}
        id={'browse-marketplace'}
        data-auto="browse_marketplace"
        buttonStyle="outline"
        color="primary"
        onClick={() => {
          navigate(`/marketplace/capabilities?tab=${props.source}`);
        }}
      >
        {' '}
        {props.btnText}
      </ModusButton>
    </div>
  );
}

export default SharedGraphics;
