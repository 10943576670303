import styles from './table-meta.module.scss';
import { ModusButton, ModusPagination } from '@trimble-oss/modus-react-components';
import { TColumn } from '@trimble-oss/modus-web-components/';
import { t } from 'i18next';

/* eslint-disable-next-line */
export interface UIMetaInfoProps {
  currentPage: number;
  paginationAction: any;
  meta: any
}

export function UIMetaInfo(props: UIMetaInfoProps) {

  function PrevButton(){
    if(props.currentPage > 1){
        return (<ModusButton
          color="primary"
          button-style="outline"
          onClick={() => props.paginationAction}   
        >
          Prev
        </ModusButton>)
    }else{            
      return (<ModusButton
        color="primary"
        button-style="outline"
        disabled={true}
      >
        Prev
      </ModusButton>)
    }
  }

  function ProvideResultsMeta() {
    const currentStartCount = (props.meta.pageNumber - 1) * props.meta.pageSize + 1;
    let endCount = props.meta.pageNumber * props.meta.pageSize;
    if(endCount > props.meta.resultCount){
      endCount = props.meta.resultCount;
    }
    if (props.meta.resultCount > props.meta.pageSize) {
      const replacer = {
        FROM_COUNT: currentStartCount,
        SEPARATOR: '-',
        TO_COUNT: endCount,
        TOTAL_COUNTS: props.meta.resultCount,
      };
      return (
        <div className={styles['resultsMeta']}>
          <span>{t('Users.Tables.Results', replacer)}</span>
        </div>
      );
    } else {
      return <div className={styles['resultsMeta']}></div>;
    }
  }

  function NextButton(){
    if(props.meta.hasMore){
        return (<ModusButton
          color="primary"
          button-style="outline"
          onClick={() => props.paginationAction}
        >
          Next
        </ModusButton>)
    }else{
      return (<ModusButton
        color="primary"
        button-style="outline"
        disabled={true}
      >
        Next
      </ModusButton>)
    }
  }

  function GetNewPagination(){
    const totalPages = Math.ceil(props.meta.resultCount / props.meta.pageSize);
    if(totalPages === 1){
      return null;
    }else{
      return <ModusPagination activePage={props.meta.pageNumber} minPage={1} maxPage={totalPages} onPageChange={props.paginationAction}/>
    }
  }
 

  return (
    <div className={styles['container']}>
      <ProvideResultsMeta/>
      <GetNewPagination/>
    </div>
  );
}

export default UIMetaInfo;
