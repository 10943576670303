import { Toast } from 'react-bootstrap';
import { isNullOrUndefined } from '../../../../../libs/ui/src/utils/Common';
import loader from '../../assets/images/loaderGif.gif';
import { NotificationMessage } from '../notification-provider/NotificationProvider';
import styles from './toast-lister.module.scss';
import ToastContainer from 'react-bootstrap/ToastContainer';

export type ToastPosition ='top-start'
| 'top-center'
| 'top-end'
| 'middle-start'
| 'middle-center'
| 'middle-end'
| 'bottom-start'
| 'bottom-center'
| 'bottom-end';
/* eslint-disable-next-line */
export interface ToastListerProps {
  toastMesgList?: ToastMessage[];
  closeToast?: (id: string) => void;
  toastPosition?:ToastPosition;
  delay?:number;
}

export interface ToastMessage extends NotificationMessage {
  id: string;
  message: string;
  status: string;
  title: string;
}

export function ToastLister(props: ToastListerProps) {
  const getStatusIcon = (status: string) => {
    let icon = '';
    switch (status) {
      case 'Completed':
        icon = 'check_circle';
        break;
      case 'Failed':
        icon = 'error';
        break;
      case 'Warning':
        icon = 'warning';
        break;
    }
    return icon;
  };

  const getToastStyleByStatus = (status: string) => {
    let icon = '';
    switch (status) {
      case 'Completed':
        icon = 'toast-success';
        break;
      case 'Failed':
        icon = 'toast-danger';
        break;
      case 'In Progress':
        icon = 'toast-primary';
        break;
      case 'Warning':
        icon = 'toast-warning';
        break;
    }
    return icon;
  };

  const {toastPosition ,toastMesgList, closeToast ,delay} = { ...props };

  return (
    // <div className={styles[`toast-wrapper`]}>
       <ToastContainer
          position={toastPosition || 'top-center'}
          className="p-2"
        >
          {toastMesgList &&
        toastMesgList.map((mesObj, key) => (
          <div key={key}>
            {closeToast ? (
              <Toast
                animation={true}
                autohide={
                  !isNullOrUndefined(mesObj.autohide)
                    ? mesObj.autohide
                    : mesObj.status === 'In Progress'
                    ? false
                    : true
                }
                delay={delay || 15000}
                onClose={() => closeToast(mesObj.id)}
                className={
                  styles[`toast-item`] +
                  ' mt-1 ' +
                  getToastStyleByStatus(mesObj.status)
                }
              >
                <div className={styles['toast-contents']}>
                  <div className={styles['toast-header']}>
                    {mesObj.status === 'In Progress' ? (
                      <div className={styles['toast-image-div']}>
                        <img
                          height="5"
                          width="20"
                          src={loader}
                          alt="loader..."
                        ></img>
                      </div>
                    ) : (
                      <i className="material-icons-outlined">
                        {getStatusIcon(mesObj.status)}
                      </i>
                    )}
                    <div className={styles['toastTitle']}>{mesObj.title}</div>
                    <button
                      type="button"
                      className="close"
                      onClick={() => closeToast(mesObj.id)}
                      data-dismiss="toast"
                    >
                      <i className="modus-icon material-icons">close</i>
                    </button>
                  </div>
                  <div className={styles['toastmessage']}>{mesObj.message}</div>
                </div>
              </Toast>
            ) : (
              ''
            )}
          </div>
        ))}
        </ToastContainer>
        
      
    // </div>
  );
}

export default ToastLister;
