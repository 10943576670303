import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuthProviderHook';
import App from './app';
import { assignRoleToUser } from '../API/RoleAssignment';
import {
  getLocalStorageValue,
  removeLocalStorageValue,
} from '../hooks/useLocalStrorage';
import { useTranslation } from 'react-i18next';
import {
  AlertType,
} from './notification-provider/NotificationProvider';
import { CircleSpinner } from '@workflow/shared/info-graphics';
import AppView from './app-view';
import { AuthorizationProvider } from '../hooks/use-authorization-handler/use-authorization-handler';
import { RouteConstants } from '../API/Utils/RouteConstants';
import ErrorPage from '../pages/inviterror/error-page';

/* eslint-disable-next-line */
export interface ProtectedLayoutProps {}

export function ProtectedLayout() {
  const location = useLocation();
  const { authInfo } = useAuth();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [userProvisiongInProgress, setUserProvisiongInProgress] =
    useState(false);
  const { t } = useTranslation();

  const redirectToHome = () => {
    setIsLoaded(true);
    navigate(RouteConstants['myCapabilities']);
  };
  useEffect(() => {
    if (!authInfo) {
      // user is not authenticated
      navigate({
        pathname: 'signin',
        search: '?' + searchParams,
      });
    } else {
      const userType =
        getLocalStorageValue('userType') || searchParams.get('userType');
      if (userType === 'owner') {
        setUserProvisiongInProgress(true);
        assignRoleToUser().then(
          (result) => {
            removeLocalStorageValue('userType');
            redirectToHome();
          },
          (error) => {
            setError(true);
            removeLocalStorageValue('userType');
            setTimeout(() => {
              setError(false);
              redirectToHome();
            }, 30000);
          }
        );
      } else {
        setIsLoaded(true);
        if (location.pathname === '/') {
          navigate(RouteConstants['myCapabilities']);
        }
      }
    }
  }, [authInfo]);
  if (error) {
    return (
      <ErrorPage
        pageType={RouteConstants['commonPageError']}
        message={t('ErrorPage.AssignRole.text')}
        showSignOut={true}
      />
    );
  } else if (isLoaded) {
    return (
      <AppView>
        <AuthorizationProvider>
          <App />
        </AuthorizationProvider>
      </AppView>
    );
  } else if (userProvisiongInProgress) {
    return (
      <CircleSpinner
        size="48px"
        text={t('Common.AssignRole.ProgressText')}
      ></CircleSpinner>
    );
  } else {
    return <div></div>;
  }
}

export default ProtectedLayout;
