import { FeatureDeployment } from '../models/FeatureDeployment';
import {
    axiosInstance,
} from '../hooks/axiosInstanceHelper';
import { AxiosResponse } from 'axios';
import { EntitlementDBResponse } from '../models/EntitlementDBResponse';

export function fetchEntitlementData(email: string, productSku: string | undefined,status?:string):Promise<AxiosResponse<EntitlementDBResponse>> {

    return axiosInstance().request({
        method: 'GET',
        url:  `/subscriptions/v1/entitlements`,
        params: {
            email,
            productSku,
            status
        }
    });
}
 export const FeatureDeploymentAPI = {
    async fetchFeatureDeployment(
        accountId: string | undefined
    ): Promise<FeatureDeployment[]> {
        const result = await axiosInstance().request({
            method: 'GET',
            url: `/deployment/status`,
            params: {
                accountId,
            },
        });
        return result && result.data ? result.data.documents : [];
    },
};

export const fetchFeatureDeploymentByFeatureId = {
    async fetchFeatureDeployment(
        accountId: string | undefined,
        featureId: string | undefined
    ): Promise<FeatureDeployment[]> {
        const result = await axiosInstance().request({
            method: 'GET',
            url: `/deployment/status`,
            params: {
                accountId, featureId
            },
        });
        return result && result.data ? result.data.documents : [];
    },
};
