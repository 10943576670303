import { ActionType } from '../../../../libs/ui/src/action-button/action-props';
import { AppContants, getCurrentMomentWithIncrement, getCurrentTimeWithDefaultIncrement } from '@workflow/ui';
import moment from 'moment';
import { t } from 'i18next';
import { Detail, Parameter, ParamType, UIParameter } from '../models/Product';

const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export function getFormData(details: Detail[] | undefined, explicitParams: UIParameter[] | undefined) {
  const parameters = details?.map(val => val.parameters).reduce((a, b) => a.concat(b), []);
  return parameters ? parameters.reduce((total: any, current) => {
    if (explicitParams?.some(param => param.name === current.name) && !total[current.name]) {
      total[current.name] = current.value;
    }
    return total;
  }, {}) : undefined;
}

export function getFormTemplate(
  parameters: Parameter[] | undefined,
  action:ActionType
): any {
  const paramList = ['date-time-picker', 'select'];
  if (parameters) {
    
    // eslint-disable-next-line prefer-spread
    const explicitParams: (UIParameter)[] = [];
    let uniqueKeys: string[] = [];
    parameters.forEach((eachParam) => {
      if( eachParam['paramType'] === ParamType.Explicit && !uniqueKeys.includes(eachParam.name)) {
        uniqueKeys.push(eachParam.name);
        explicitParams.push(eachParam);
      }
    });
    uniqueKeys = [];
    const min =  getCurrentMomentWithIncrement(AppContants.MINIMUM_INTERVAL_IN_FUTURE, "minutes");

    const updatedParams = explicitParams?.map(param => {
      let uiField;
      let append;

      if(param.type === 'date-time-picker'){
        append = {
          min: min,
          defaultValue: min,
          validate: {
            invalidDate: (value:any) => (value && moment(value).isSameOrAfter(getCurrentTimeWithDefaultIncrement())) ? undefined : t('Workflows.Date.InvalidValue'),
          }
        }
      }else {
        append = {}
      }
      if (param) {
        uiField = {
          ...param,
          regex: param.name === 'email' ? emailRegex : undefined,
          isControlled: paramList.some(val => param.type === val),
          ...append
        }
      }
      return uiField;
    }).filter(value => !!value);
    return updatedParams;
  }
  return [];
};
