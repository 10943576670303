import styles from './metrics-dashboard.module.scss';
import React, { useState, useEffect } from 'react';
import {
  Widget,
  ChartBox,
  WidgetCharts,
  FeatureData,
  Entryvalues,
  Feature,
  calculateDates,
  DatePickerWrapper,
  calculateDateval,
  OptionType,
  getSubOptions,
} from '@workflow/dashboard';
import { fetchFeatureProducts } from '../../API/ProductAPI';
import { fetchAnalyticsData } from '../../API/AnalyticsDahsboardAPI';
import {
  ModusDatePicker,
  ModusDateInput,
  ModusAlert,
} from '@trimble-oss/modus-react-components';
import { savePreferencesData, getPreferences } from '../../API/PreferencesAPI';
import { useEntitlements } from '../../components/app-view';
import { globalConfig } from '../../API/Utils/enivronment-util';
import moment from 'moment';
import { isNullOrUndefined } from '@workflow/ui';

export function MetricsDashboard() {
  const { accountId } = useEntitlements();
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - 7);

  const formattedDate = (date: Date): string | undefined => {
    if (date instanceof Date) {
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, '0');
      const dd = String(date.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
    } else {
      return date;
    }
  };
  const [widgetCharts, setWidgetCharts] = useState<WidgetCharts>({});
  const [selectedStartDate, setSelectedStartDate] = useState<any>(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(undefined);
  const [selectedOption, setSelectedOption] = useState<any>(undefined);
  const [widgetFeatures, setWidgetFeatures] = useState<any>([]);
  const [widgetChartData, setWidgetChartData] = useState<
    Record<string, FeatureData>
  >({});
  const [isWidgetVisible, setIsWidgetVisible] = useState(false);
  const [count, setCount] = useState(0);
  const [error, setError] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const formattedStartDate = formattedDate(selectedStartDate);
  const formattedEndDate = formattedDate(selectedEndDate);
  const [selectedSubOption, setSelectedSubOption] = useState('custom');
  const [SubOptions, setSubOptions] = useState<OptionType>();
  const [data, setData] = useState<Entryvalues[]>([]);
  const [preference, setPreference] = useState<any>();
  const [finalFeature, setFinalFeature] = useState<any>([]);
  const [saveCounter, setSaveCounter] = useState(0);
  const [initialStartDateSet, setInitialStartDateSet] = useState(false);
  const [initialEndDateSet, setInitialEndDateSet] = useState(false);
  const [initialSelectedOptionSet, setInitialSelectedOptionSet] =
    useState(false);
  //dhanush added
  const [checkboxStatus, setCheckboxStatus] = useState<{
    [feature: string]: { [dimension: string]: boolean };
  }>({});
  const [selectedChart, setselectedChart] = useState('');

  const handleStartDateChange = (event: any) => {
    event.preventDefault();
    const newDate = event.detail.value;
    console.log('Handle Start Date Change:', event);
    setSelectedStartDate(newDate);
    if (initialStartDateSet) {
      setSaveCounter((prevValue) => prevValue + 1);
    } else {
      setInitialStartDateSet(true);
    }
    console.log('counter-handle start date', saveCounter);
  };

  const handleEndDateChange = (event: any) => {
    event.preventDefault();
    console.log('HI I AM HERE WITH NEW END DATE', event);
    setSelectedEndDate(event.detail.value);
    if (initialEndDateSet) {
      setSaveCounter((prevValue) => prevValue + 1);
    } else {
      setInitialEndDateSet(true);
    }
  };
  const handleDateClick = (event: any) => {
    setSelectedSubOption('custom');
  };
  const handleSubOptionChange = (selectedValue: string) => {
    setSelectedSubOption(selectedValue);
    if (initialSelectedOptionSet) {
      setSaveCounter((prevValue) => prevValue + 1);
    } else {
      setInitialSelectedOptionSet(true);
    }
    const { newStartDate, newEndDate } = calculateDates(selectedValue);

    if (newStartDate && newEndDate) {
      const formattedStartDate = formattedDate(newStartDate);
      const formattedEndDate = formattedDate(newEndDate);
      setSelectedStartDate(formattedStartDate);
      setSelectedEndDate(formattedEndDate);
    }
  };

  const getData = async (selectedFeatureId: string, dimension: string) => {
    const result = await fetchAnalyticsData(
      accountId,
      selectedFeatureId,
      dimension,
      selectedOption,
      selectedStartDate,
      selectedEndDate
    );
    return result;
  };

  const callBacks = {
    getData,
  };

  const handleSelectChange = (selectedDuration: string) => {
    setSelectedOption(selectedDuration);
    setSelectedSubOption('custom');

    setSaveCounter((prevValue) => prevValue + 1);
    const { newStartDate, newEndDate } = calculateDateval(selectedDuration);

    if (newStartDate && newEndDate) {
      const formattedStartDate = formattedDate(newStartDate);
      const formattedEndDate = formattedDate(newEndDate);
      setSelectedStartDate(formattedStartDate);
      setSelectedEndDate(formattedEndDate);
    }
  };

  const [features, setFeatures] = useState<Feature[]>([
    {
      featureId: '',
      boxes: [
        {
          showText: false,
          selectedChart: null,
          dimension: '',
          sessionData: null,
          chartComponent: null,
        },
      ],
    },
  ]);

  const handleShowTextCallback = (
    featureId: string,
    boxIndex: number,
    value: boolean
  ) => {
    setFeatures((prevFeatures) =>
      prevFeatures.map((feature) =>
        feature.featureId === featureId
          ? {
              ...feature,
              boxes: feature.boxes.map((box, index) =>
                index === boxIndex
                  ? {
                      ...box,
                      showText: value,
                    }
                  : box
              ),
            }
          : feature
      )
    );
  };

  const cloudProductSKU = globalConfig.config?.productName;
  const setDefaultDate = () => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(currentDate);
    setSelectedOption('week');
  };
  useEffect(() => {
    const getPreferencesData = window.sessionStorage.getItem(
      'AnalyticsPreferenceData'
    );
    let prefer;
    let selectedDuration;
    if (getPreferencesData) {
      prefer = JSON.parse(getPreferencesData);
      console.log('parsed data', prefer);
      setPreference(prefer);
      setFinalFeature(prefer.features);
      const StartDateSession = formattedDate(prefer?.startDate);
      const EndDateSession = formattedDate(prefer?.endDate);
      setSelectedStartDate(StartDateSession);
      setSelectedEndDate(EndDateSession);
      setSelectedOption(prefer?.duration);
      selectedDuration = prefer?.duration;
      if (prefer.features && prefer.features.length > 0) {
        setCount((prev) => prev + 1);
      }
    } else if (isNullOrUndefined(getPreferencesData)) {
      getPreferences(accountId).then(
        (result: any) => {
          console.log(
            'I am inside dashboard for getting data,preferencesData',
            result.data
          );
          if (result.data) {
            prefer = result.data;
            setPreference(result.data);
            setFinalFeature(result.data.features);
            const StartDateval = formattedDate(result.data.startDate);
            const EndDateval = formattedDate(result.data.endDate);

            setSelectedStartDate(StartDateval);
            setSelectedEndDate(EndDateval);
            setSelectedOption(result.data.duration);
            selectedDuration = result.data.duration;
          } else {
            setDefaultDate();
          }
        },
        (error: any) => {
          console.log(error);
          setDefaultDate();
        }
      );
    }

    setSubOptions(getSubOptions(selectedDuration ?? 'week'));
  }, [accountId]);

  useEffect(() => {
    if (
      preference !== null &&
      preference !== undefined &&
      preference.features !== null &&
      preference.features !== undefined &&
      preference.features.length > 0
    ) {
      preference.features.forEach((feature: any) => {
        const { featureId, charts } = feature;

        charts.forEach((chart: any) => {
          const { chartType, dimensionType } = chart;

          setWidgetCharts((prevWidgetCharts) => {
            const featureData = prevWidgetCharts[featureId] || {};
            const dimensionData = featureData[dimensionType] || [];
            const isChartTypeExist = dimensionData.includes(chartType);

            if (!isChartTypeExist) {
              return {
                ...prevWidgetCharts,
                [featureId]: {
                  ...featureData,
                  [dimensionType]: [...dimensionData, chartType],
                },
              };
            }

            // Return the previous state if the chart type already exists
            return prevWidgetCharts;
          });
        });
      });

      const mappedFeatures = preference.features.map(
        (feature: { featureId: string; charts: any[] }) => {
          const existingFeature = features.find(
            (f) => f.featureId === feature.featureId
          );

          if (existingFeature) {
            return {
              ...existingFeature,
              boxes: [
                ...existingFeature.boxes,
                ...feature.charts.map(
                  (chart: { chartType: any; dimensionType: any }) => ({
                    showText: false,
                    selectedChart: chart.chartType,
                    dimension: chart.dimensionType,
                    sessionData: null,
                    chartComponent: null,
                  })
                ),
                {
                  showText: false,
                  selectedChart: null,
                  dimension: '',
                  sessionData: null,
                  chartComponent: null,
                },
              ],
            };
          } else {
            return {
              featureId: feature.featureId,
              boxes: [
                ...feature.charts.map(
                  (chart: { chartType: any; dimensionType: any }) => ({
                    showText: false,
                    selectedChart: chart.chartType,
                    dimension: chart.dimensionType,
                    sessionData: null,
                    chartComponent: null,
                  })
                ),
                {
                  showText: false,
                  selectedChart: null,
                  dimension: '',
                  sessionData: null,
                  chartComponent: null,
                },
              ],
            };
          }
        }
      );

      setFeatures(mappedFeatures);
    }
  }, [preference]);

  useEffect(() => {
    setIsLoading(true);
    fetchFeatureProducts(
      cloudProductSKU,
      accountId
      // FeatureType.CAPABILITY
    ).then(
      (result) => {
        console.log('hi', result);
        const featureIds =
          result && result?.features?.length
            ? result?.features?.map((feature) => {
                const obj = { label: feature.name, value: feature.featureId };
                return obj;
              })
            : [];

        if (featureIds) {
          setWidgetFeatures(featureIds);
        }
        result &&
          result.features?.length &&
          setWidgetChartData((prevData) => {
            const newData: Record<string, FeatureData> = { ...prevData };

            result.features.forEach((feature: FeatureData) => {
              newData[feature['featureId']] = {
                name: feature.name,
                supportedDimensions: feature.supportedDimensions,
              };
            });
            setIsLoading(false);
            return newData;
          });
      },
      (error) => {
        setIsLoading(false);
        setError({
          message: 'Unable to fetch the feature information.Try again later',
        });
        console.log(error);
      }
    );
  }, [accountId, cloudProductSKU]);

  const handleAddFeature = (
    featureId: string,
    selectedChart: string,
    selectedDimension: string,
    sessionData: any,
    index: number,
    chartComponent: any,
    isLastIndexValue: boolean
  ) => {
    setFeatures((prevFeatures) => {
      const existingFeatureIndex = prevFeatures.findIndex(
        (feature) => feature.featureId === featureId
      );
      console.log(
        'existingFeatureIndex-featureId',
        existingFeatureIndex,
        featureId
      );
      console.log(
        'handle add feature ',
        featureId,
        index,
        selectedDimension,
        selectedChart,
        prevFeatures
      );

      let updatedFeatures = [...prevFeatures];
      updatedFeatures = prevFeatures.filter(
        (feature) => feature.featureId !== ''
      );
      // return updatedFeatures;

      if (existingFeatureIndex === -1) {
        updatedFeatures.push({
          featureId,
          boxes: Array.from({ length: index + 1 }, (_, boxIndex) => ({
            showText: boxIndex === index,
            selectedChart: boxIndex === index ? selectedChart : null,
            dimension: boxIndex === index ? selectedDimension : '',
            sessionData: boxIndex === index ? sessionData : null,
            chartComponent: boxIndex === index ? chartComponent : null,
          })),
        });
        if (isLastIndexValue) {
          updatedFeatures[updatedFeatures.length - 1].boxes.push({
            showText: false,
            selectedChart: null,
            dimension: '',
            sessionData: null,
            chartComponent: null,
          });
        }
      } else {
        updatedFeatures.forEach((feature, featureIndex) => {
          console.log('updated features', feature.featureId, featureId);
          const updatedBoxes = feature.boxes.map((box, boxIndex) => ({
            showText: false,
            selectedChart: box.selectedChart,
            dimension: box.dimension,
            sessionData: box.sessionData,
            chartComponent: box.chartComponent,
          }));

          if (feature.featureId === featureId) {
            updatedBoxes[index] = {
              showText: true,
              selectedChart,
              dimension: selectedDimension,
              sessionData,
              chartComponent,
            };
          }

          if (
            featureIndex === existingFeatureIndex &&
            updatedBoxes.length <= index
          ) {
            updatedBoxes.push({
              showText: true,
              selectedChart,
              dimension: selectedDimension,
              sessionData,
              chartComponent,
            });
          }

          if (feature.featureId === featureId && isLastIndexValue) {
            updatedBoxes.push({
              showText: false,
              selectedChart: null,
              dimension: '',
              sessionData: null,
              chartComponent: null,
            });
          }

          updatedFeatures[featureIndex] = {
            ...feature,
            boxes: updatedBoxes,
          };
        });
      }

      return updatedFeatures;
    });
    setSaveCounter((prevValue) => prevValue + 1);
  };

  const handleShowWidget = (value: boolean) => {
    setIsWidgetVisible(value);
  };
  const handleDeleteChart = () => {
    setSaveCounter((prevValue) => prevValue + 1);
  }

  useEffect(() => {
    console.log('counter-saveCounter', saveCounter);
    if (saveCounter > 0) {
      const featurePreference = finalFeature;
      const hasFeatures = true;
      if (hasFeatures) {
        const preferencesData = {
          accountId,
          endDate: moment(selectedEndDate).toISOString(),
          duration: selectedOption,
          startDate: moment(selectedStartDate).toISOString(),
          features: featurePreference,
        };
        savePreferencesData(preferencesData).then(
          (result: any) => {
            console.log('I am inside dashboard,preferencesData', result);
          },
          (error: any) => {
            console.log(error);
          }
        );
      }
    }
  }, [accountId, finalFeature, saveCounter]);

  const options = [
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Day', value: 'day' },
  ];

  return (
    <div>
      <div className={styles['usage-dashboard']}>Usage Dashboard</div>

      {selectedStartDate && selectedEndDate && (
        <DatePickerWrapper
          formattedStartDate={formattedStartDate}
          formattedEndDate={formattedEndDate}
          options={options}
          subOptions={SubOptions}
          selectedDuration={selectedOption}
          selectedDuratrionInterval={selectedSubOption}
          handleSelectChange={handleSelectChange}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          handleDateClick={handleDateClick}
          handleSubOptionChange={handleSubOptionChange}
          showDuration={true}
          showDurationInterval={true}
        ></DatePickerWrapper>
      )}

      {isLoading && (
        <div className={'progress-bar ' + styles['progress-bar-dashboard']}>
          <div className="progress-bar-value"></div>
        </div>
      )}
      {error && <ModusAlert type="error" message={error.message} />}
      {!isLoading && (
        <>
          <div className={styles['container']}>
            {features &&
              features.length > 0 &&
              features?.map((feature) => (
                <div key={feature.featureId}>
                  {count === 0 && (
                    <h4 className={styles['feature-name']}>
                      Click on "Add chart" to display your chart
                    </h4>
                  )}
                  {count > 0 && (
                    <h4 className={styles['feature-name']}>
                      {feature.featureId}
                    </h4>
                  )}
                  <div className={styles['boxContainer']}>
                    {feature.boxes &&
                      feature.boxes.map((box, index) => (
                        <React.Fragment key={index}>
                          <ChartBox
                            index={index}
                            featureId={feature.featureId}
                            selectedChart={box.selectedChart}
                            showText={box.showText}
                            dimension={box.dimension}
                            chartComponent={box.chartComponent}
                            features={features}
                            setFeatures={setFeatures}
                            sessionData={box.sessionData}
                            setWidgetCharts={setWidgetCharts}
                            showTextCallback={(value: any) =>
                              handleShowTextCallback(
                                feature.featureId,
                                index,
                                value
                              )
                            }
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                            widgetChartData={widgetChartData}
                            setIsWidgetVisible={handleShowWidget}
                            setFinalFeature={setFinalFeature}
                            handlerCallback={callBacks}
                            accountId={accountId}
                            handleDeleteChart={handleDeleteChart}
                          />
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              ))}
          </div>
          {isWidgetVisible && (
            <Widget
              featuresData={features}
              setFeaturesData={setFeatures}
              handleAddFeature={handleAddFeature}
              setIsWidgetVisible={setIsWidgetVisible}
              widgetCharts={widgetCharts}
              setWidgetCharts={setWidgetCharts}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              widgetFeatures={widgetFeatures}
              widgetChartData={widgetChartData}
              setCount={setCount}
              setData={setData}
              data={data}
              setFinalFeature={setFinalFeature}
              //dhanush added
              checkboxStatus={checkboxStatus}
              setCheckboxStatus={setCheckboxStatus}
              selectedChart={selectedChart}
              setselectedChart={setselectedChart}
              handlerCallback={callBacks}
            />
          )}
        </>
      )}
    </div>
  );
}
export default MetricsDashboard;
