import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuthProviderHook';
import { useTranslation } from 'react-i18next';
import StatusAlertComp from '../../components/status-alert-comp/status-alert-comp';
import { AlertType } from '../../components/notification-provider/NotificationProvider';
import { RouteConstants } from '../../API/Utils/RouteConstants';
import { CircleSpinner } from '@workflow/shared/info-graphics';

/* eslint-disable-next-line */
export interface LoginProviderProps {}

export function LoginProvider(props: LoginProviderProps) {
  const [searchParams] = useSearchParams();
  const { onLogin } = useAuth();
  const [error, setError] = useState<any | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (onLogin) {
      onLogin(searchParams.get('code')).then(
        (result) => {
          if (result) {
            sessionStorage.setItem('openMenu', 'true');
            setIsLoaded(true);
            navigate(RouteConstants['myCapabilities']);
          } else {
            navigate('/signin');
          }
        },
        (error) => {
          setError(error);
        }
      );
    }
  }, []);

  if (error) {
    const message =
      error.status == 401
        ? 'you are not authorized to view this application'
        : error.status == 403
        ? 'you do not have permission to view this application'
        : 'something went wrong.';
    const alert: AlertType = {
      id: 'error',
      type: 'error',
      status: 'error',
      message,
    };
    return (
      <div className={'container-error'}>
        <img
          data-auto="progress"
          width="120"
          height="120"
          src="images/cloud-access.svg"
          alt="error..."
        ></img>
        <StatusAlertComp
          showAlertText={true}
          alert={alert}
          alwaysShowAlert={true}
        ></StatusAlertComp>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className="px-3">
        <CircleSpinner size="48px" text={t('Common.Loading.Title')}></CircleSpinner>
      </div>
    );
  } else {
    return <div>Login</div>;
  }
}

export default LoginProvider;
