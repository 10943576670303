import { ActionType } from '../../../../../libs/ui/src/action-button/action-props';
import { useEffect, useRef, useState } from 'react';
import {FeatureListItem} from '../../models/FeatureListItem';
import {FormRenderer} from '@workflow/shared/ui-form';
import { t } from 'i18next';
import { ModusModal } from '@trimble-oss/modus-react-components';

/* eslint-disable-next-line */
export interface ModalCreateProps {
  showConfirmDialog: any;
  handleClose: any;
  handleConfirm: any;
  confirmBtnTitle?: any;
  featureData: FeatureListItem;
  formData: any;
  children?: any;
  actionId?:ActionType;
}

export interface ModalMessage {
  title: string;
  message: string;
  confirmBtnTitle?: string;
}

export function ModalCreate(props: ModalCreateProps) {
  const modal = useRef<HTMLModusModalElement>(null);
  const {
    featureData,
    formData,
    showConfirmDialog,
    handleClose,
    handleConfirm,
    actionId
  } = { ...props };
  const [triggerSubmit, setTriggerSubmit] = useState(0);
  const onSubmit = (data: any) => {
    handleConfirm(actionId,featureData.id, featureData.title, data);
  };
  const onActionClick = () => {
    setTriggerSubmit(value => value + 1);
  };


  useEffect(() => {
    if(showConfirmDialog) {
      modal.current?.open();
    } else{
      modal.current?.close();
    }
  }, [showConfirmDialog]);

  const cssFormStyle = {
    "display": "flex",
    "overflow-y": "auto",
    "max-height": "557px",
    "padding-right": "40px",
    "margin": "0rem -1rem -1rem 0rem",
    "min-width": "457px",
    "align-items": "stretch",
    "flex-direction": "column"
  }

  return (
    <ModusModal 
          headerText={t('Workflows.Add.Parameters',{title:featureData?.title})} 
          backdrop={'static'}
          zIndex='1050'
          primaryButtonText={ t('Workflows.Add.Save')}
          secondaryButtonText= { t('Common.Cancel')}
          onPrimaryButtonClick={onActionClick}
          onSecondaryButtonClick={handleClose}  
          onClosed={handleClose} 
          ref={modal}
                  >
          <FormRenderer
            formData={formData}
            cssStyle={cssFormStyle}
            formTemplate={featureData?.formTemplate}
            onSubmit={onSubmit}
            triggerSubmit={triggerSubmit}
            actionId={actionId}
          ></FormRenderer>              
        </ModusModal>    
  );
}
