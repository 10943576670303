import React from 'react';
import { Pie } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js/auto';
import { ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface PieChartProps {
  chartData: ChartData<'pie'>;
  customOptions?: ChartOptions<'pie'>;
}

const PieChart: React.FC<PieChartProps> = ({ chartData, customOptions }) => {
  // Default options
  const defaultOptions: ChartOptions<'pie'> = {
    animation: {
      animateRotate: true,
      animateScale: true,
      duration: 1000,
      easing: 'easeInOutQuart',
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
        labels: {
          boxWidth: 8,
          padding: 4,
        },
      },
      datalabels: {
        anchor: 'center',
        color:'#ffffff',
        font: {
          size: 10,
        },
      },
    },
  };

  const options: ChartOptions<'pie'> = {
    ...defaultOptions,
    ...customOptions,
  };

  return (
    <Pie
      data={chartData}
      options={options}
      plugins={[ChartDataLabels as any]}
    />
  );
};

export default PieChart;
