import { PlanInfoCard } from '@workflow/ui';
import styles from './feature-plan-info.module.scss';
import { PlanCardDetails } from '../../../../../libs/ui/src/plan-info-card/plan-info-card';
import { Plan } from '../../models/Product';

/* eslint-disable-next-line */
export interface FeaturePlanInfoProps {
  title: string;
  description: string;
  planDetails?: Plan[];
  featureData: any;
  onActionClick: any;
  actions?: any;
}

export function FeaturePlanInfo(props: FeaturePlanInfoProps) {
  const { title, description, planDetails, featureData, onActionClick, actions } =
    props;
  return (
    <div className={styles['container-layout']}>
      <div className={styles['container-content']}>
        <div className={styles['container-header']}>
          <p className={styles['header-text']}>{title}</p>
        </div>
        <p className={styles['content-text']}>{description}</p>
        <div className={styles['plan-card-container']}>
          {planDetails &&
            planDetails.map((plan, index) => (
              <PlanInfoCard
                details={plan}
                width="372px"
                height="475px"
                featureData={featureData}
                onActionClick={onActionClick}
                actions={actions}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default FeaturePlanInfo;
