import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend';
import { DateUtil } from "../../../libs/ui/src/utils/DateUtil";

i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "en",
    detection:{
         order: ['navigator', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
    },
    saveMissing:true,
    load:'all',
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
      allowMultiLoading: false,
    },
    react :{
      transSupportBasicHtmlNodes : true
    }    
  },()=> {
      //init moment locale
      DateUtil.localize();
  });
