import { ModusCard } from '@trimble-oss/modus-react-components';
import KeyFeatures from '../../../../../libs/shared/info-graphics/src/lib/key-features/key-features';
import { KeyFeature } from '../../models/Product';
import styles from './list-key-features.module.scss';
import { t } from 'i18next';

/* eslint-disable-next-line */
export interface ListKeyFeaturesProps {
  keyFeatures: KeyFeature[];
}

/* add this class for larger screen grid sizes  row-cols-lg-3 row-cols-xl-4}*/
export function ListKeyFeatures(props: ListKeyFeaturesProps) {
  if(props.keyFeatures && props.keyFeatures.length > 0) {
    return (
      <div className={styles['wholeContainer']}>
        <h1 className={styles['title']}>{t('Page.Details.SubTitle.Key.Features')}</h1>
        <div className="list row justify-content-start pt-2 row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">   
                {props.keyFeatures.map((item, index) => { 
                  return (
                    <div className="col my-3" id= {'Auto'+index} key={index}>
                      <KeyFeatures image={{src:item.image,size:'180px'}} title={item.name} desc={item.desc}/>
                    </div>);
                })}
        </div>
      </div>      
    );
  }else{
    return null;
  }
  
}

export default ListKeyFeatures;
