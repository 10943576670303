import { axiosInstance } from '../hooks/axiosInstanceHelper';

export interface IRoleEditReq {
  accountId?: string;
  userId?: string;
  roleAssignments?: { roleId: string; action: string; }[];
}

export async function assignRoleToUser(): Promise<any> {
  const result = await axiosInstance().request({
    method: 'POST',
    url: `/roles/v1/user/owner/assign`,
  });
  return result && result.data ? result.data : undefined;
}


export async function updateUserRoles(payload: IRoleEditReq): Promise<any> {
  return await axiosInstance().request({
    method: 'POST',
    url: `/roles/v1/user`,
    data: payload,
  });
}


