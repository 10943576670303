import i18next from 'i18next';
import { axiosInstance } from '../hooks/axiosInstanceHelper';
import { FeatureType } from '../models/FeatureType';
import { AnalyticsData } from '../models/AnalayticsData';
import moment from 'moment';

export const setPreferenceData=(preferencesData:any)=>{
  window.sessionStorage.setItem("AnalyticsPreferenceData", JSON.stringify(preferencesData))
}
export async function savePreferencesData(
   preferencesData:any
): Promise<any> {
  // const preferencesData={
  //   accountId,
  //   endDate:moment(endDate).toISOString(),
  //   duration:intervalPeriod,
  //   startDate:moment(startDate).toISOString(),
  //   features:featureArray
  // }
  setPreferenceData(preferencesData as any)
  const result = await axiosInstance().request({
    method: 'POST', 
    url: '/preferences/v1',
    data: preferencesData
  });
  console.log("prefrences has been stored");
  return result && result.data ? result.data : undefined;
}


export async function getPreferences(
    accountId: string | undefined
): Promise<any> {
      const result = await axiosInstance().request({
          method: 'GET',
          url: '/preferences/v1',
          params: {
              accountId,
          },
      });
      return result && result ? result : undefined;
  }
