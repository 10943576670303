import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AlertType } from '../notification-provider/NotificationProvider';
import styles from './status-alert-comp.module.scss';
import { link } from 'fs';
import { ModusAlert } from '@trimble-oss/modus-react-components';

/* eslint-disable-next-line */
export interface StatusAlertCompProps {
  showAlertText?: boolean;
  alertText?: string;
  alert?: AlertType;
  alertCloseHandler?: any;
  message?:string;
  alwaysShowAlert?:boolean;
}

export function StatusAlertComp(props: StatusAlertCompProps) {
  const getIconNameByType = (type: string): string => {
    let iconName = '';
    switch (type) {
      case 'warning':
        iconName = 'warning';
        break;
      case 'success':
        iconName = 'check_circle';
        break;
      case 'danger':
        iconName = 'error';
        break;
      case 'primary':
        iconName = 'info';
        break;
    }
    return iconName;
  };
  const { t } = useTranslation();

  const AlertTemplate = (alert:any,message:any) => {
    
      return alert ? <div>
            {
              (alert?.status==='Failed')? 
               <>
                <span>{message ? message : alert?.message}&nbsp;</span>
               <Trans t={t} i18nKey="Common.AlertSupportText">Please contact the <Link to={'/cloud-support'}>Support</Link> for further help, if issue persists.</Trans>
               </>
            : (alert?.status==='Conflict')? (
              <>
                <span>{alert?.message}&nbsp;</span>
                <Trans t={t} i18nKey="Common.AlertRefreshText">Click here to <a href="javascript:window.location.reload(true)">refresh</a> the status.</Trans>
               </>
            ) : (alert?.status==='not_found')? (
                getStatusMessage(alert?.message, '___', alert?.link) 
            )  :(alert?.status === 'suspend') ?  (
              <>
                <span>{alert?.message}&nbsp;</span>
                <Trans t={t} i18nKey="Common.SaaS.LinkText">Please visit <Link target='_blank' rel='noreferrer' to={alert?.link}>SaaS Subscriptions</Link> for further information.</Trans>
               </>
            )
            : alert?.message
            }
          </div> : '';};

      if(props.showAlertText) {
        if(props.alert && props.alertCloseHandler && !props.alwaysShowAlert ) {
          return (
            <ModusAlert message={AlertTemplate(props.alert, props.message) + ''} type={props.alert?.type} dismissible={true} onDismissClick={props.alertCloseHandler}/>
          );
        }else{
          return <ModusAlert message={AlertTemplate(props.alert, props.message) + ''} type={props.alert?.type} dismissible={false}/>          
        } 
      }else{
        return null;
      }    
}

const getStatusMessage = (message: string, delimiter: string, link: any)=>{
  const mesArr = message? message.split(delimiter):[];
  if(mesArr && mesArr.length>=3){
    return (<>
      <span>{mesArr[0]}</span>
      <span>
        <a href={link} target='_blank' rel='noreferrer'>
          <span className={styles['linkValue']}>{mesArr[1]}</span>
        </a>
      {mesArr[2]}
      </span>
    </>);
  } else {
    return (<span>{message}</span>);
  }
  
}

export default StatusAlertComp;
