import { Parameter } from "./Product"

export interface FeatureDeployment {
    featureId: string,
    entitlementId: string,
    accountId: string,
    skuCode: string,
    id: string,
    crtdDt: string,
    uptdDt: string,
    endDt: string,
    userId: string,
    email: string,
    lifeState:"ACTV" | "INACTV",
    deploymentInfo: {
        endpointURL:string,
        status: SubscriptionStatusTypes
        statusDescription:string ,
        deploymentTime: string,
        tags: {
            releaseVersion: string
        },
        parameters: Parameter[]
    }
}

// export interface Parameter {
//     value : string,
//     paramType:"user_defined" | "implicit"
// }
export enum SubscriptionStatusTypes {
    SUBSCRIBE_PENDING = "SUBSCRIBE-PENDING", SUBSCRIBE_INPROGRESS = "SUBSCRIBE-INPROGRESS", SUBSCRIBE_SUCCESS = "SUBSCRIBE-SUCCESS", SUBSCRIBE_FAILED = "SUBSCRIBE-FAILED", UNSUBSCRIBE_PENDING = "UNSUBSCRIBE-PENDING", UNSUBSCRIBE_INPROGRESS = "UNSUBSCRIBE-INPROGRESS", UNSUBSCRIBE_SUCCESS = "UNSUBSCRIBE-SUCCESS", UNSUBSCRIBE_FAILED ="UNSUBSCRIBE-FAILED",
    SUBSCRIBE_CONFLICT = "SUBSCRIBE-CONFLICT", UNSUBSCRIBE_CONFLICT = "UNSUBSCRIBE-CONFLICT",REINSTATE_SUCCESS = "REINSTATE-SUCCESS",REINSTATE_FAILED = "REINSTATE-FAILED",SUSPEND_FAILED = "SUSPEND-FAILED",REINSTATE_INPROGRESS = "REINSTATE-INPROGRESS",REINSTATE_PENDING = "REINSTATE-PENDING"
}