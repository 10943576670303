export const isDevelopment = process.env['NODE_ENV'] === 'development';

export const setEnvironmentConfig = (): EnvConfig => {
  return {
    environment: isDevelopment ? process.env['NX_ENV_NAME'] : 'APP.ENV.NAME',
    identityURL: isDevelopment ? process.env['NX_IDENTITY_URL'] : 'APP.IDENTITY.URL',
    identityAppName: isDevelopment ? process.env['NX_IDENTITY_APP_NAME'] : 'APP.IDENTITY.APP.NAME',
    clientID: isDevelopment ? process.env['NX_CLIENT_ID'] : 'APP.CLIENT.ID',
    basicAuth: isDevelopment ? process.env['NX_BASIC_AUTH'] : 'APP.BASIC.AUTH',
    clientSecret: isDevelopment ? process.env['NX_CLIENT_SECRET'] : 'APP.CLIENT.SECRET',
    cloudBaseUrl: isDevelopment ? process.env['NX_CLOUD_BASE_URL'] : 'APP.CLOUD.BASE.URL',
    appAPIBaseUrl: isDevelopment ? process.env['NX_API_BASE_URL'] : 'APP.API.BASE.URL',
    appAPIUrlPattern: isDevelopment ? process.env['NX_API_URL_PATTERN'] : 'APP.API.URL.PATTERN',
    appRedirectURL: isDevelopment ? process.env['NX_REDIRECT_URL'] : 'APP.REDIRECT.URL',
    productName: isDevelopment ? process.env['NX_PRODUCT_NAME'] : 'APP.PRODUCT.NAME',
    webPubSubURL: isDevelopment ? process.env['NX_WEBPUBSUB_URI'] : 'APP.WEBPUBSUB.URI',
    marketingEmailAPI: isDevelopment ? process.env['NX_MARKETING_EMAIL_API'] : 'APP.MARKETING.EMAIL.API',
    marketingEmailCDE: isDevelopment ? process.env['NX_MARKETING_EMAIL_CDE'] : 'APP.MARKETING.EMAIL.CDE',
    marketingEmailFeedBack: isDevelopment ? process.env['NX_MARKETING_EMAIL_FeedBack'] : 'APP.MARKETING.EMAIL.FeedBack',
    urlRegexStr: isDevelopment ? process.env['NX_URL_REGEX_STR'] : 'APP.URL.REGEX_STR',
    gainSightToken: isDevelopment ? process.env['NX_GAIN_SIGHT_TOKEN'] : 'APP.GAIN_SIGHT_TOKEN',
    trimbleCloudSubscriptionUrl: isDevelopment ? process.env['NX_TRIMBLE_CLOUD_SUBSCRIPTION_URL'] : 'APP.TRIMBLE_CLOUD_SUBSCRIPTION_URL',
    consoleCloudBaseUrl: isDevelopment ? process.env['NX_CONSOLE_CLOUD_URL'] : 'APP.CONSOLE.CLOUD.URL',
    consoleCloudUrlPattern: isDevelopment ? process.env['NX_CONSOLE_CLOUD_URL_PATTERN'] : 'APP.CONSOLE.CLOUD.URL.PATTERN',
    SaaSSubscriptionListUrl: isDevelopment ? process.env['NX_SAAS_SUBSCRIPTION_LIST_URL'] : 'APP.TRIMBLE_CLOUD_SUBSCRIPTION_URL',
    consoleCloudAppName:isDevelopment ? process.env['NX_CONSOLE_CLOUD_SCOPE'] :'APP.CONSOLE.CLOUD.SCOPE',
    consoleCloudLaunchURL:isDevelopment ? process.env['NX_CONSOLE_CLOUD_LAUNCH_URL'] :'APP.CONSOLE.CLOUD.LAUNCH.URL',
    tdpPortalShow:isDevelopment ? process.env['NX_TDP_PORTAL_SHOW'] :'APP.TDP.PORTAL.SHOW',
    drivePageShow:isDevelopment ? process.env['NX_DRIVE_PAGE_SHOW'] :'APP.DRIVE.PAGE.SHOW',
    myProfileURL:isDevelopment ? process.env['NX_IDENTITY_PROFILE_URL'] :'APP.IDENTITY.PROFILE.URL',
    drawingUrl: isDevelopment ? process.env['NX_DRAWINGS_URL'] : 'APP.DRAWINGS.URL',
    creatorUrl: isDevelopment ? process.env['NX_CREATOR_URL'] : 'APP.CREATOR.URL',
  };
};

export interface EnvConfig {
  environment?: string;
  identityURL?: string;
  identityAppName?: string;
  clientID?: string;
  basicAuth?: string;
  clientSecret?: string;
  cloudBaseUrl?: string;
  appAPIBaseUrl?: string;
  appAPIUrlPattern?:string;
  consoleCloudBaseUrl?:string;
  consoleCloudUrlPattern?:string;
  appRedirectURL?: string;
  productName?: string;
  webPubSubURL?: string;
  marketingEmailAPI?: string,
  marketingEmailCDE?: string,
  marketingEmailFeedBack?: string,
  urlRegexStr?: string,
  gainSightToken?: string;
  trimbleCloudSubscriptionUrl?: string;
  SaaSSubscriptionListUrl?: string;
  consoleCloudAppName?:string;
  consoleCloudLaunchURL?:string;
  tdpPortalShow?:string;
  drivePageShow?:string;
  myProfileURL?:string;
  drawingUrl?: string;
  creatorUrl?:string;
}
class GlobalConfig {
  public config: EnvConfig ={};
  notDefinedYet = true;

  public set(value: EnvConfig): void {
    this.config = value;
  }
}
export const globalConfig = new GlobalConfig();

