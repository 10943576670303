import i18next from 'i18next';
import { axiosInstance } from '../hooks/axiosInstanceHelper';
import { FeatureType } from '../models/FeatureType';
import { AnalyticsData } from '../models/AnalayticsData';
import moment from 'moment';

export async function fetchAnalyticsData(
    accountId?: string,
    featureId?: string,
    selectedDimension?: string,
    intervalPeriod?:string,
    startDate?: Date | string,
    endDate?: Date | string
): Promise<AnalyticsData> {
 
  const result = await axiosInstance().request({
    method: 'POST', 
    url: '/analytics/v1',
    data: {
      accountId,
      dimension:selectedDimension,
      endDate:moment(endDate).toISOString(),
      featureId,
      interval:intervalPeriod,
      startDate:moment(startDate).toISOString()
    },
  });
  console.log("");
  return result && result.data ? result.data : undefined;
}



