import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  ArcElement,
  CategoryScale,
  registerables,
  ChartData,
} from 'chart.js';
import { ChartOptions } from 'chart.js/auto';

interface LineChartProps {
  chartData: ChartData<any>;
  customOptions?: ChartOptions<'line'>;
}

Chart.register(ArcElement);
Chart.register(CategoryScale);
Chart.register(...registerables);

const LineChart: React.FC<LineChartProps> = ({ chartData, customOptions }) => {
  const defaultOptions: ChartOptions<'line'> = {
    animation: {
      animateRotate: true,
      animateScale: true,
      duration: 1000,
      easing: 'easeInOutQuart',
    } as any,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'center',
        labels: {
          boxWidth: 8,
          padding: 4,
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  const options: ChartOptions<'line'> = {
    ...defaultOptions,
    ...customOptions,
  };

  return <Line data={chartData} options={options} plugins={[
    {
      id: "increase-legend-spacing",
      beforeInit(chart) {
        // Get reference to the original fit function
        const originalFit = (chart.legend as any).fit;
        // Override the fit function
        (chart.legend as any).fit = function fit() {
          // Call original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)();
          this.height += 20;
        };
      }
    }
  ]}/>;
};

export default LineChart;
