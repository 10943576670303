import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../hooks/useAuthProviderHook';
import styles from './data-trimble-drive.module.scss';
import { globalConfig } from '../../API/Utils/enivronment-util';
import { AuthenticationAPI } from '../../API/authentication';
import { useNavigate } from 'react-router-dom';
import { isTruthy } from '@workflow/ui';
import { RouteConstants } from '../../API/Utils/RouteConstants';

/* eslint-disable-next-line */
export interface DataTrimbleDriveProps {
  pageType: string;
}

export function DataTrimbleDrive(props: DataTrimbleDriveProps) {
  const [sessionData, setSession] = useState<any>();
  const { authInfo, updateAuthInfo, userInfo, isExpired } = useAuth();
  const [authData, setAuthData] = useState<any>();
  const ref = useRef<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const isTokenExpired = isExpired ? isExpired() : false;
    //console.log(isTokenExpired);
    if (isTokenExpired) {
      AuthenticationAPI.refreshAccessToken().then((result) => {
        setAuthData(result);
      });
    } else if (authInfo) {
      setAuthData(authInfo);
    }
  }, [authInfo, isExpired]);
  useEffect(() => {
    if (userInfo && authData) {
      setSession({
        session: {
          accessToken: authData?.access_token,
          expiresAt: authData?.expiresAt,
          refreshToken: authData?.refresh_token,
          idToken: authData?.id_token,
        },
        currentUser: {
          uuid: userInfo.sub,
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
          email: userInfo.email,
          trn: 'trn:tid:user:' + userInfo.sub,
        },
        tIdConfig: {
          identityProvider: globalConfig.config?.identityURL,
          identityClientApp: globalConfig.config?.identityAppName,
          identityClientId: globalConfig.config?.clientID,
          identityClientKey: globalConfig.config?.clientSecret,
          identityRedirectUri: globalConfig.config?.appRedirectURL + '/auth',
          identitySignOutRedirectUri: globalConfig.config?.appRedirectURL,
        },
      });
    }
  }, [userInfo, authData]);
  useEffect(() => {
    if (sessionData) {
      ref.current.session = sessionData.session;
      ref.current.currentUser = sessionData.currentUser;
      ref.current.tidConfig = sessionData.tIdConfig;
      ref.current.heightOffset = 90;
    }
  }, [sessionData]);

  useEffect(() => {
    const isShowDrivePage = isTruthy(globalConfig.config.drivePageShow);
    if (!isShowDrivePage) {
      navigate(RouteConstants['myCapabilities']);
    }
    ref.current.envProps = {
      drawingUrl: globalConfig.config?.drawingUrl + '/#/drawings/drive',
      creatorUrl: globalConfig.config?.creatorUrl ,
    };
    ref.current.addEventListener('tokenRefreshed', (event: any) => {
      console.log('tokenRefreshed', event.detail);
      const authValue = event.detail;
      authValue.expiresAt = authValue.expires_in
        ? new Date().getTime() + authValue.expires_in * 1000
        : undefined;
      if (updateAuthInfo) {
        updateAuthInfo(authValue);
      }
    });
  }, []);

  const getDriveEnv = () => {
    const regex = /\bprod\b/;
    let env = '';
    if (globalConfig.config?.environment) {
      env = globalConfig.config.environment;
    }
    if (regex.test(env)) {
      return 'prod';
    } else {
      return 'stage';
    }
  };
  const getDriveRegion = () => {
    const env= globalConfig.config.environment;

    if (env === 'prod') {
      return 'US';
    } else if (env === 'prod-eu'){
      return 'EU';
    }
    return undefined;
  };

  return (
    <div className={styles['container']}>
      <trimble-drive
        ref={ref}
        env={getDriveEnv()}
        page={props.pageType}
        region={getDriveRegion()}
        lang="en-US"
      />
    </div>
  );
}

export default DataTrimbleDrive;
